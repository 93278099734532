import { classNames } from "../../helpers/react";

export type SpinnerProps = {
    className?: string;
    size: string | number;
};

export function Spinner(props: SpinnerProps)
{
    return (
        <div
            className={ classNames("spinner-border", "text-primary", props.className) }
            role="status"
            style={ { width: props.size, height: props.size } }
        >
            <span className="visually-hidden">Loading...</span>
        </div>
    );
}
