import { PropertyType } from "@artbanx/nexera/system";
import type { BuildOptions } from "vite";

var configuration: AppConfiguration;

export interface AppConfiguration
{
    chainMode: string;
    walletConnectProjectId: string;
    appPort: number;
    sourceMap: PropertyType<BuildOptions, "sourcemap">;
    assetApiBaseUrl: string;
    cors: CorsConfig;
    publicBaseUrl: string;
    kycServiceUrl: string;
}

export interface CorsConfig
{
    allowedOrigin: string;
    allowedMethods: string;
    allowedHeaders: string;
}

export function getConfiguration(): AppConfiguration
{
    if (configuration == null)
    {
        console.log("Configuration not yet loaded");
        configuration = loadConfiguration();
    }
    return configuration;
}

export function loadConfiguration(envVariables: Record<string, string> | ImportMetaEnv = tryLoadEnv()): AppConfiguration
{
    if (configuration == null)
    {
        loadConfigsFromEnvVariables(envVariables);
    }
    console.log(configuration);
    return configuration;
}

function loadConfigsFromEnvVariables(envVariables: Record<string, any> | ImportMetaEnv)
{
    const walletConnectProjectId = assertEnvValueExists(envVariables, "VITE_WALLETCONNECT_PROJECT_ID");
    const chainMode = assertEnvValueExists(envVariables, "VITE_CHAIN_MODE");
    const assetApiBaseUrl = assertEnvValueExists(envVariables, "VITE_ASSET_API_URL");
    const allowedOrigin = assertEnvValueExists(envVariables, "VITE_CORS_ALLOW_ORIGIN");
    const publicBaseUrl = assertEnvValueExists(envVariables, "VITE_PUBLIC_BASE_URL");
    const kycServiceUrl = assertEnvValueExists(envVariables, "VITE_KYC_SERVICE_URL");

    const appPort = Number.parseInt(envVariables.VITE_UI_PORT ?? 4173);
    configuration = {
        walletConnectProjectId: walletConnectProjectId,
        chainMode: chainMode,
        sourceMap: envVariables.VITE_SOURCE_MAP ?? "hidden",
        appPort: appPort,
        assetApiBaseUrl: assetApiBaseUrl,
        cors: {
            allowedOrigin: allowedOrigin,
            allowedMethods: envVariables.VITE_CORS_ALLOW_METHODS ?? "GET,HEAD,PUT,PATCH,POST",
            allowedHeaders: envVariables.VITE_CORS_ALLOW_HEADERS ?? "Content-Type"
        },
        publicBaseUrl: publicBaseUrl,
        kycServiceUrl: kycServiceUrl
    };
}

function tryLoadEnv()
{
    try
    {
        return import.meta.env;
    }
    catch (e)
    {
        console.error("Error when reading from IMPORT.META.ENV:" + e);
        try
        {
            return import.meta.env;
        }
        catch (e)
        {
            console.error("Error when reading Env Variables:" + e);
            return {};
        }
    }
}

function assertEnvValueExists(props: Record<string, any>, propName: string): string
{
    const prop = props[propName];
    console.log(`Checking required property ${propName}`);
    if (!prop)
    {
        throw Error(`ENV property ${propName} is required`);
    }

    return prop;
}
