export function smartTrim(value: string, maxLength: number)
{
    if (!value)
    {
        return value;
    }
    if (maxLength < 1)
    {
        return value;
    }
    if (value.length <= maxLength)
    {
        return value;
    }
    if (maxLength == 1)
    {
        return value.substring(0, 1) + "...";
    }

    var midpoint = Math.ceil(value.length / 2);
    var toremove = value.length - maxLength;
    var lstrip = Math.ceil(toremove / 2);
    var rstrip = toremove - lstrip;
    return value.substring(0, midpoint - lstrip) + "..."
        + value.substring(midpoint + rstrip);
}
