import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const RedirectManager = (props: any) =>
{
    const [ queryParams ] = useSearchParams();
    const [ kycResponse, setKycResponse ] = useState(false);
    const navigate = useNavigate();

    function isKycResponse()
    {
        const query = new URLSearchParams(window.location.search);
        let transactionStatus = query.get("transactionStatus");
        if (
            query.get("transactionStatus") != null
            && query.get("customerInternalReference") != null
            && query.get("transactionReference") != null
        )
        {
            setKycResponse(true);
        }
        else
        {
            setKycResponse(false);
        }
    }

    function redirect()
    {
        if (kycResponse)
        {
            window.location.replace(window.location.origin + "/wallet/#/ui/kyc?phase=2");
        }
    }

    useEffect(isKycResponse, [ queryParams ]);
    useEffect(redirect, [ kycResponse ]);

    return (
        <>
            <p>Please Wait ...</p>
        </>
    );
};
