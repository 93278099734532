import { ReactNode } from "react";

export type RadialBarProps = {
    progress: number;
    radius: string;
    fillColor: string;
    backgroundColor: string;
    strokeWidth: string;
    children?: ReactNode;
    className?: string;
    containerClassName?: string;
};

export function RadialBar(props: RadialBarProps)
{
    const radius = `calc(${props.radius} - ${props.strokeWidth})`;
    const circumference = `calc((${radius}) * 2 * var(--abx-pi))`;
    const progressOffset = `calc((${1 - (props.progress / 100)}) * ${circumference})`;

    // Double the radius to get the diameter
    const diameter = `calc((${props.radius}) * 2)`;

    // Calculate the side length of the square that can fit inside the circle
    const sideLength = `calc(((${props.radius}) - (${props.strokeWidth}) * 1.5) / (var(--abx-root-of-2) / 2))`;

    // Calculate the position to center the square inside the circle
    const position = `calc(50% - (${sideLength} / 2))`;

    return (
        <svg className={ props.className } style={ { width: diameter, height: diameter, background: "transparent" } }>
            <circle
                cx="50%"
                cy="50%"
                r={ radius }
                style={ {
                    stroke: props.backgroundColor,
                    strokeWidth: props.strokeWidth,
                    strokeDasharray: circumference,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    fill: "transparent"
                } }
            />
            <circle
                cx="50%"
                cy="50%"
                r={ radius }
                style={ {
                    stroke: props.fillColor,
                    strokeWidth: props.strokeWidth,
                    strokeDasharray: circumference,
                    strokeDashoffset: progressOffset,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    fill: "transparent",
                    transformOrigin: "50% 50%",
                    transform: "rotate(-90deg)"
                } }
            />
            <foreignObject width={ diameter } height={ diameter }>
                <div
                    className={ props.containerClassName }
                    style={ {
                        margin: position,
                        width: sideLength,
                        height: sideLength
                    } }
                >
                    { props.children }
                </div>
            </foreignObject>
        </svg>
    );
}
