import { useEffect, useState } from "react";
import { Address } from "../../services/countries/models/address";

import { UserInfo } from "../../services/user/models/userInfo";
import { AddressSearch } from "../shared/AddressSearch";
import CountrySelect from "../shared/CountrySelect";
import "./profileManager.css";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { useNotifications } from "../../hooks/notifications";
import * as kycService from "../../services/kyc/service";
import { BillingInfo } from "../../services/user/models/billingInfo";
import { User } from "../../services/user/models/user";
import { getCurrentIdentity } from "../../services/user/userService";
import * as userProfileService from "../../services/userProfile/userProfileService";
import { KycStatus } from "../kycProvider/dto/KycUserStatusResultDto";
import DialingSelect from "../shared/DialingSelect";
import { NotifyUser } from "../userWallet/UserWalletNotifications";
import "./profileManager.css";

export function prepareInput(
    type: string,
    label: string,
    value: any,
    title: string,
    isRequired: boolean = false,
    disabled: boolean = false,
    touched: boolean = false,
    callback: (one: any, two: any) => void
)
{
    return (
        <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
            <label htmlFor={ `${label}` } className={ `${font(FontDefinition.T4)} form-label` }>{ label }</label>
            <input
                type={ type }
                className={ `form-control ${type === "date" && value ? "date-input--has-value" : ""}` }
                value={ value }
                onChange={ (e) => callback(title, e.target.value) }
                disabled={ disabled }
            />
            { (isRequired && !value && touched) ? <span className="text-danger">{ label } is required!</span> : <></> }
        </div>
    );
}

export const ProfileDetail = () =>
{
    let defaultUser = {
        billingInfo: {
            sameUserAddress: true,
            billingAddress: {}
        },
        userInfo: {
            nationality: {},
            countryOfResidence: "",
            dateOfBirth: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            userAddress: {},
            dialNumber: {}
        },
        identity: getCurrentIdentity()
    } as User;

    const [ _userInfo, setUser ] = useState<UserInfo>(defaultUser.userInfo);
    const [ _billAddress, setBillAddress ] = useState<Address>();
    const [ _isBillChecked, setBillChecked ] = useState<boolean>(false);
    const [ _touched, setTouched ] = useState<boolean>(false);
    const [ _kycVerified, setKycVerified ] = useState(false);
    const notifications = useNotifications();

    async function getKycStatus()
    {
        let kycStatus = await kycService.getKycUserStatus();
        if (kycStatus.status === KycStatus.ALL_CHECKS_CLEAR)
        {
            setKycVerified(true);
        }
        else
        {
            setKycVerified(false);
        }
    }

    useEffect(() =>
    {
        getUserProfile();
        getKycStatus();
    }, []);

    function handleUserInfoChange(key: string, value: any)
    {
        switch (key)
        {
            case "firstName":
                setUser((old) =>
                {
                    return { ...old, firstName: value };
                });
                break;
            case "lastName":
                setUser((old) =>
                {
                    return { ...old, lastName: value };
                });
                break;
            case "dob":
                setUser((old) =>
                {
                    return { ...old, dateOfBirth: value };
                });
                break;
            case "phoneNumber":
                setUser((old) =>
                {
                    return { ...old, phoneNumber: value };
                });
                break;
            case "nationality":
                setUser((old) =>
                {
                    return { ...old, nationality: value };
                });
                break;
            case "userAddress":
                setUser((old) =>
                {
                    return { ...old, userAddress: value };
                });
                break;
            case "dialNumber":
                setUser((old) =>
                {
                    return { ...old, dialNumber: value };
                });
                break;
        }
    }

    async function getUserProfile()
    {
        let userProfile = await userProfileService.getOneUserProfile();
        if (userProfile)
        {
            setUser(userProfile.userInfo);
            if (userProfile.billingInfo.billingAddress)
            {
                setBillChecked(true);
                setBillAddress(userProfile.billingInfo.billingAddress);
            }
            else
            {
                setBillChecked(false);
            }
        }
    }

    async function saveChanges()
    {
        setTouched(true);
        if (!_userInfo?.firstName || !_userInfo.lastName)
        {
            window.scroll({ top: 0 });
            return;
        }
        let user = {
            userInfo: _userInfo,
            billingInfo: {
                sameUserAddress: !_isBillChecked,
                billingAddress: _billAddress
            } as BillingInfo,
            identity: getCurrentIdentity()
        } as User;
        let status = await userProfileService.saveOneUserProfile(user);
        NotifyUser(notifications, status, "Profile saved successfully!", "Something went wrong while saving profile!");
        // does the user really want to know if the profile is saved in wordpress ?
        await userProfileService.saveUserProfileInWordPress(user);
        // NotifyUser(notifications, status, "Profile saved successfully!", "Something went wrong while saving profile!");
    }

    return (
        <>
            <div className="card" style={ { marginTop: "1rem" } }>
                <div className="card-body p-0 m-4">
                    <div className="row">
                        { prepareInput(
                            "text",
                            "First Name",
                            _userInfo?.firstName,
                            "firstName",
                            true,
                            _kycVerified,
                            _touched,
                            handleUserInfoChange
                        ) }
                        { prepareInput(
                            "text",
                            "Last Name",
                            _userInfo?.lastName,
                            "lastName",
                            true,
                            _kycVerified,
                            _touched,
                            handleUserInfoChange
                        ) }
                    </div>
                    <div className="row">
                        <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label htmlFor="nationality" className="marginb-label form-label form-label">
                                Nationality
                            </label>

                            <div className="input-group-flat border rounded">
                                <CountrySelect
                                    disabled={ _kycVerified }
                                    id={ 1 }
                                    selectedValue={ _userInfo?.nationality }
                                    onChange={ (data) => handleUserInfoChange("nationality", data) }
                                    cssClass="marginb-xl-30 marginb-lg-30 marginb-md-30 marginb-sm-20 marginb-xs-20"
                                />
                            </div>
                        </div>
                        { prepareInput(
                            "date",
                            "Date of Birth",
                            _userInfo?.dateOfBirth,
                            "dob",
                            false,
                            _kycVerified,
                            _touched,
                            handleUserInfoChange
                        ) }
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label
                                htmlFor="phone number"
                                className={ `${font(FontDefinition.T4)} marginb-label form-label form-label` }
                            >
                                Phone Number
                            </label>
                            <div className="d-flex flex-row align-items-end border input-group-flat rounded">
                                <DialingSelect
                                    cssClass=""
                                    onChange={ (avalue) => handleUserInfoChange("dialNumber", avalue) }
                                    value={ _userInfo?.dialNumber }
                                    key={ 323 }
                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Phone Number"
                                    value={ _userInfo?.phoneNumber }
                                    onChange={ (e) => handleUserInfoChange("phoneNumber", e.target.value) }
                                    style={ {
                                        border: "0px",
                                        borderLeft: "1px solid rgb(218, 218, 218)",
                                        borderTopLeftRadius: "0px",
                                        borderBottomLeftRadius: "0px"
                                    } }
                                />
                            </div>
                        </div>

                        { prepareInput(
                            "email",
                            "Email Address",
                            getCurrentIdentity().email,
                            "email",
                            false,
                            true,
                            _touched,
                            handleUserInfoChange
                        ) }
                    </div>
                    <div className="row mb-4">
                        <AddressSearch
                            setAddress={ (data) => handleUserInfoChange("userAddress", data) }
                            selectedAddress={ _userInfo?.userAddress }
                            title=""
                            id={ 1 }
                        />
                    </div>
                    <div className="row g-4">
                        <div className="col-12">
                            <span
                                className={ `${font(FontDefinition.T2)}` }
                            >
                                Billing Address
                            </span>
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    id="billChecked"
                                    className="form-check-input"
                                    checked={ _isBillChecked }
                                    onChange={ () => setBillChecked(!_isBillChecked) }
                                />
                                <label
                                    htmlFor="billChecked"
                                    className={ classNames(font(FontDefinition.T4), "form-check-label") }
                                >
                                    Different to my address
                                </label>
                            </div>
                        </div>
                        { _isBillChecked
                            ? (
                                <div className="col-12">
                                    <AddressSearch
                                        setAddress={ setBillAddress }
                                        selectedAddress={ _billAddress }
                                        title=""
                                        id={ 2 }
                                    />
                                </div>
                            )
                            : <></> }
                    </div>
                    <div className="d-flex flex-row-reverse mt-4">
                        <button className={ `"btn btn-primary text-white btn-edit` } onClick={ saveChanges }>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
