import { wait } from "@artbanx/nexera/helpers";
import { useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { classNames } from "../../helpers/react";
import { smartTrim } from "../../helpers/string";

export type ClipboardLableProps = {
    text: string;
    maxLength?: number;
    className?: string;
};

export function ClipboardLabel(props: ClipboardLableProps)
{
    const [ showTooltip, setShowTooltip ] = useState(false);
    const target = useRef<HTMLSpanElement>(null);

    async function handleClick()
    {
        await navigator.clipboard.writeText(props.text);

        setShowTooltip(true);

        await wait(1000);

        setShowTooltip(false);
    }

    return (
        <div>
            <span
                ref={ target }
                role="button"
                className={ classNames(props.className, "text-decoration-underline") }
                onClick={ handleClick }
            >
                { smartTrim(props.text, props.maxLength ?? 15) }
            </span>
            <Overlay target={ target.current } show={ showTooltip } placement="auto">
                { (props) => (
                    <Tooltip { ...props }>
                        Copied!
                    </Tooltip>
                ) }
            </Overlay>
        </div>
    );
}
