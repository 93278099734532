import { isBoolean, isFunction } from "@artbanx/nexera/helpers";
import { Delegate } from "@artbanx/nexera/system";
import { Children, isValidElement, ReactElement, ReactNode } from "react";

export type ConditionChild =
    | ReactElement<Condition.ConditionCheckProps>
    | ReactElement<Condition.NonConditionalProps>;

export type ConditionProps = {
    children: ConditionChild | ConditionChild[];
};

type Condition =
    | {
        conditional: true;
        props: Condition.ConditionCheckProps;
    }
    | {
        conditional: false;
        props: Condition.NonConditionalProps;
    };

export function Condition(props: ConditionProps)
{
    let ifAppeared = false;
    let elseAppeared = false;

    const conditions = Children.map(
        props.children,
        (condition, index) =>
        {
            if (!isValidElement(condition))
            {
                throw "Non valid element appeared in Condition!";
            }

            switch (condition.type)
            {
                case Condition.If:
                {
                    if (ifAppeared)
                    {
                        throw "There is only one If statement can appear in expression!";
                    }

                    ifAppeared = true;

                    return {
                        conditional: true,
                        props: condition.props as Condition.ConditionCheckProps
                    } as Condition;
                }
                case Condition.ElseIf:
                {
                    if (index === 0)
                    {
                        throw "ElseIf can't be on first place in expression!";
                    }

                    if (elseAppeared)
                    {
                        throw "Else must be last element in expression!";
                    }

                    return {
                        conditional: true,
                        props: condition.props as Condition.ConditionCheckProps
                    } as Condition;
                }
                case Condition.Else:
                {
                    if (index === 0)
                    {
                        throw "Else can't be on first place in expression!";
                    }

                    if (elseAppeared)
                    {
                        throw "There is only one Else statement allowed!";
                    }

                    elseAppeared = true;

                    return {
                        conditional: false,
                        props: condition.props as Condition.NonConditionalProps
                    } as Condition;
                }
            }
        }
    );

    if (conditions.length <= 0)
    {
        return <></>;
    }

    for (const condition of conditions)
    {
        if (condition.conditional)
        {
            if (condition.props.expression !== undefined)
            {
                if (
                    (isFunction(condition.props.expression) && condition.props.expression())
                    || (isBoolean(condition.props.expression) && condition.props.expression)
                )
                {
                    return <>{ condition.props.children }</>;
                }
            }
        }
        else
        {
            return <>{ condition.props.children }</>;
        }
    }

    return <></>;
}

export namespace Condition
{
    export type NonConditionalProps = {
        children: ReactNode;
    };

    export type ConditionCheckProps =
        & NonConditionalProps
        & {
            expression: boolean | Delegate<[], boolean>;
        };

    export function If(props: ConditionCheckProps): JSX.Element
    {
        throw "If must appear only inside Condition!";
    }

    export function ElseIf(props: ConditionCheckProps): JSX.Element
    {
        throw "ElseIf must appear only inside Condition!";
    }

    export function Else(props: NonConditionalProps): JSX.Element
    {
        throw "Else must appear only inside Condition!";
    }
}
