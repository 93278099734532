import { Delegate, Undefinable } from "@artbanx/nexera/system";
import { DependencyList, useEffect, useState } from "react";

export enum ResourceLoadingState
{
    Pending = "pending",
    Loading = "loading",
    Loaded = "loaded",
    Failed = "failed"
}

export type Resource<TResource> = {
    state: ResourceLoadingState;
    exception: unknown;
    value: Undefinable<TResource>;
};

export function useResource<TResource>(
    dependencies: DependencyList,
    query: Delegate<[], Promise<TResource> | TResource>,
    dispose?: Delegate<[TResource], Promise<void> | void>
)
{
    const [ resource, setResource ] = useState<Resource<TResource>>(
        {
            state: ResourceLoadingState.Pending,
            value: undefined,
            exception: undefined
        }
    );

    async function fetchResource(resource: Resource<TResource>, dependencies: DependencyList)
    {
        try
        {
            resource.state = ResourceLoadingState.Loading;

            const result = await query();

            resource.value = result;
            resource.state = ResourceLoadingState.Loaded;

            return result;
        }
        catch (exc)
        {
            resource.exception = exc;
            resource.state = ResourceLoadingState.Failed;
        }
        finally
        {
            setResource(
                (old) =>
                {
                    if (old === resource)
                    {
                        return { exception: resource.exception, state: resource.state, value: resource.value };
                    }

                    return old;
                }
            );
        }
    }

    useEffect(
        () =>
        {
            const resource: Resource<TResource> = {
                state: ResourceLoadingState.Pending,
                value: undefined,
                exception: undefined
            };

            setResource(resource);

            const task = fetchResource(resource, dependencies);

            return () =>
            {
                task.then(
                    (resource) =>
                    {
                        if (resource)
                        {
                            dispose?.(resource);
                        }
                    }
                );
            };
        },
        dependencies
    );

    return resource;
}
