import moment, { Duration, Moment } from "moment";

import { setLongInterval } from "@artbanx/nexera/helpers";
import { Delegate, Undefinable } from "@artbanx/nexera/system";
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { classNames } from "../../helpers/react";

export enum DurationUnit
{
    MILLISECOND = 7,
    SECOND = 6,
    MINUTE = 5,
    HOUR = 4,
    DAY = 3,
    WEEK = 2,
    MONTH = 1,
    YEAR = 0
}

function getDurationOptions(displayableUnits: DurationUnit[])
{
    if (displayableUnits.includes(DurationUnit.MILLISECOND))
    {
        return { milliseconds: 1 };
    }
    else if (displayableUnits.includes(DurationUnit.SECOND))
    {
        return { seconds: 1 };
    }
    else if (displayableUnits.includes(DurationUnit.MINUTE))
    {
        return { minutes: 1 };
    }
    else if (displayableUnits.includes(DurationUnit.HOUR))
    {
        return { hours: 1 };
    }
    else if (displayableUnits.includes(DurationUnit.DAY))
    {
        return { days: 1 };
    }
    else if (displayableUnits.includes(DurationUnit.MONTH))
    {
        return { months: 1 };
    }
    else if (displayableUnits.includes(DurationUnit.YEAR))
    {
        return { years: 1 };
    }
}

function getUnitOptions(value: DurationUnit, duration: Duration)
{
    switch (value)
    {
        case DurationUnit.MILLISECOND:
        {
            const value = duration.milliseconds();
            const title = "millis";

            return { value, title };
        }
        case DurationUnit.SECOND:
        {
            const value = duration.seconds();
            const title = value == 1 ? "second" : "seconds";

            return { value, title };
        }
        case DurationUnit.MINUTE:
        {
            const value = duration.minutes();
            const title = value == 1 ? "minute" : "minutes";

            return { value, title };
        }
        case DurationUnit.HOUR:
        {
            const value = duration.hours();
            const title = value == 1 ? "hour" : "hours";

            return { value, title };
        }
        case DurationUnit.DAY:
        {
            const value = duration.days();
            const title = value == 1 ? "day" : "days";

            return { value, title };
        }
        case DurationUnit.WEEK:
        {
            const value = duration.weeks();
            const title = value == 1 ? "week" : "weeks";

            return { value, title };
        }
        case DurationUnit.MONTH:
        {
            const value = duration.months();
            const title = value == 1 ? "month" : "months";

            return { value, title };
        }
        case DurationUnit.YEAR:
        {
            const value = duration.years();
            const title = value == 1 ? "year" : "years";

            return { value, title };
        }
    }
}

type DisplayableUnitsListProps = {
    unitTitleClassName?: string;
    unitValueClassName?: string;
    unitSeparatorClassName?: string;
    displayableUnits: DurationUnit[];
    difference: Undefinable<number>;
};

function* generateDisplayableUnitsList(props: DisplayableUnitsListProps)
{
    const timeRemaining = moment.duration(props.difference);

    for (const [ index, unit ] of props.displayableUnits.entries())
    {
        if (index !== 0)
        {
            yield (
                <div className="col-auto" key={ `separator_${unit}` }>
                    <div className="row text-center m-0 h-100">
                        <div className={ classNames("col-12", "p-0", "d-flex", "align-items-center", props.unitSeparatorClassName) }>
                            <span>:</span>
                        </div>
                        <div className={ classNames("col-12", "p-0") }>
                        </div>
                    </div>
                </div>
            );
        }

        const options = getUnitOptions(unit, timeRemaining);

        yield (
            <div className="col" key={ unit }>
                <div className="row text-center m-0">
                    <div className={ classNames("col-12", "p-0", props.unitValueClassName) }>
                        <span className="text-nowrap">{ options.value }</span>
                    </div>
                    <div className={ classNames("col-12", "p-0", props.unitTitleClassName) }>
                        <span className="text-nowrap">{ options.title }</span>
                    </div>
                </div>
            </div>
        );
    }
}

export class CountdownTimerController
{
    constructor(
        private readonly stopAction: Delegate,
        private readonly startAction: Delegate<[safe?: boolean]>
    )
    {
    }

    public start(safe?: boolean)
    {
        this.startAction(safe);
    }

    public stop()
    {
        this.stopAction();
    }
}

export type CountdownTimerProps = {
    className?: string;
    unitTitleClassName?: string;
    unitValueClassName?: string;
    unitSeparatorClassName?: string;
    displayableUnits: DurationUnit[];
    endMoment: Moment;
    autostart?: boolean;
    onElapsed?: Delegate<[], void>;
};

function CountdownTimerComponent(props: CountdownTimerProps, ref: ForwardedRef<CountdownTimerController>)
{
    const [ autostart, setAutostart ] = useState(props.autostart ?? false);
    const [ timer, setTimer ] = useState<NodeJS.Timeout>();
    const [ difference, setDifference ] = useState<Undefinable<number>>(
        autostart ? props.endMoment.diff(moment()) : undefined
    );

    function update()
    {
        const diff = props.endMoment.diff(moment());

        if (diff <= 0)
        {
            props.onElapsed?.();
            stop();
        }
        else
        {
            setDifference(diff);
        }
    }

    function stop()
    {
        setAutostart(false);
        setTimer(
            (timer) =>
            {
                if (timer)
                {
                    clearInterval(timer);
                    setDifference(undefined);
                }

                return undefined;
            }
        );
    }

    function start(safe?: boolean)
    {
        setTimer(
            (timer) =>
            {
                if (!timer)
                {
                    const timeout = moment.duration(getDurationOptions(props.displayableUnits));

                    if (safe && props.endMoment?.isSameOrBefore(moment()))
                    {
                        return;
                    }

                    return setLongInterval(
                        update,
                        timeout.asMilliseconds()
                    );
                }

                return timer;
            }
        );
    }

    useImperativeHandle(
        ref,
        () => new CountdownTimerController(stop, start),
        [ props.displayableUnits, timer ]
    );

    function initialize()
    {
        if (autostart)
        {
            start(true);
        }
    }

    useEffect(initialize, []);

    return (
        <div className={ classNames("row g-0 px-2", props.className) }>
            { [
                ...generateDisplayableUnitsList({
                    ...{
                        displayableUnits: props.displayableUnits,
                        unitSeparatorClassName: props.unitSeparatorClassName,
                        unitTitleClassName: props.unitTitleClassName,
                        unitValueClassName: props.unitValueClassName,
                        difference
                    }
                })
            ] }
        </div>
    );
}

export const CountdownTimer = forwardRef(CountdownTimerComponent);
