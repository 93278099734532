import { Breakpoint, className } from "./bootstrap";
import { classNames } from "./react";

export enum FontDefinition
{
    H1 = "h1",
    H2 = "h2",
    T0 = "t0",
    T1 = "t1",
    T2 = "t2",
    T3 = "t3",
    T4 = "t4",
    T5 = "t5",
    T6 = "t6",
    T7 = "t7",
    Small = "sm",
    SuperSmall = "s-sm"
}

export function font(definition: FontDefinition, breakpoint?: Breakpoint)
{
    return classNames(
        className({ utility: "fs", breakpoint, value: definition }),
        className({ utility: "lh", breakpoint, value: definition }),
        className({ utility: "fw", breakpoint, value: definition }),
        className({ utility: "ls", breakpoint, value: definition })
    );
}
