import { StatusCodes } from "http-status-codes";
import { getConfiguration } from "../../configuration/configuration";
import { User } from "../user/models/user";
import { convert, getCurrentIdentity } from "../user/userService";

const USER_PROFILE_URL = `${getConfiguration().assetApiBaseUrl}/api/user/profile`;
const publicBaseUrl = getConfiguration().publicBaseUrl;

export async function getOneUserProfile()
{
    try
    {
        if (!getCurrentIdentity())
        {
            return;
        }
        let request = await fetch(
            USER_PROFILE_URL,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );

        return await request.json() as User;
    }
    catch (exc)
    {
        throw exc;
    }
}

export async function saveOneUserProfile(userProfile: User)
{
    if (!userProfile)
    {
        return StatusCodes.BAD_REQUEST;
    }
    try
    {
        let request = await fetch(USER_PROFILE_URL, {
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(userProfile),
            method: "POST"
        });
        let response = await request;

        return response.status;
    }
    catch
    {
        return StatusCodes.BAD_REQUEST;
    }
}

export async function saveUserProfileInWordPress(userProfile: User)
{
    let wordPressUser = convert(userProfile);
    let requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + window.wordpress.token },
        body: JSON.stringify(wordPressUser)
    };
    try
    {
        const result = await fetch(
            `${publicBaseUrl}/wp-json/wc/v3/customers/${userProfile.identity.id}`,
            requestOptions
        );

        return result.status;
    }
    catch
    {
        return StatusCodes.BAD_REQUEST;
    }
}
