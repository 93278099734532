import { Sale, SaleStatus } from "@artbanx/nexera/onchain";
import { Delegate, Undefinable } from "@artbanx/nexera/system";
import { BigNumber } from "ethers";
import moment, { Moment } from "moment";
import { useState } from "react";
import { Placement } from "react-bootstrap/esm/types";
import { DEFAULT_DATE_FORMAT, DEFAULT_DISPLAYABLE_UNITS, DEFAULT_TIME_FORMAT } from "../../constants";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { Condition } from "../condition/Condition";
import { CountdownTimer, DurationUnit } from "../countdownTimer/CountdownTimer";
import { IconPosition, InfoText } from "../infoText/InfoText";

export type SaleStatusViewProps = {
    sale?: Sale;
    showStatus?: boolean;
    className?: string;
    timerClassName?: string;
    statusTitleClassName?: string;
    statusDescriptionTextClassName?: string;
    unitTitleClassName?: string;
    unitValueClassName?: string;
    unitSeparatorClassName?: string;
    infoTextPlacement?: Placement;
    onElapsed?: Delegate<[], void>;
    displayableUnits?: DurationUnit[];
};

type StatusDescription = {
    title: string;
    text: string;
};

function getStatusDescription(sale: Undefinable<Sale>, endMoment: Undefinable<Moment>): StatusDescription
{
    if (!sale)
    {
        return { title: "", text: "" };
    }

    switch (sale.saleStatus)
    {
        case SaleStatus.STARTED:
        {
            return {
                title: `${getStatusTitle(sale.saleStatus)} Period`,
                text: "Refers to the timeframe within which the owner commits to repurchasing "
                    + "its own fractionss from the open market."
            };
        }
        case SaleStatus.APPROVED_FOR_FUNDING:
        case SaleStatus.FUNDED:
        {
            return {
                title: `${getStatusTitle(sale.saleStatus)} Period`,
                text: "This is the closing of a specific time window during which "
                    + "investors have the opportunity to buy fractions at a set price."
            };
        }
        case SaleStatus.REQUESTED:
        case SaleStatus.REJECTED:
        case SaleStatus.NOT_FUNDED:
        case SaleStatus.MARGIN_CALL:
        case SaleStatus.SELLOFF_REQUEST:
        case SaleStatus.CLOSED_BY_BUYBACK:
        case SaleStatus.CLOSED_BY_SELLOFF:
        default:
        {
            return {
                title: `${getStatusTitle(sale.saleStatus)} Period`,
                text: endMoment
                    ? `${getStatusTitle(sale.saleStatus)} period has time limit`
                    : `${getStatusTitle(sale.saleStatus)} period has no time limit`
            };
        }
    }
}

export function SaleStatusView(props: SaleStatusViewProps)
{
    const endMoment = getActivePeriodEndDate(props.sale);
    const baseMoment = moment();

    // TODO: refetch of query on timer elapsed
    const [ isElapsed, setElapsed ] = useState(false);

    const description = getStatusDescription(props.sale, endMoment);

    return (
        <div className={ classNames("d-flex flex-column", props.className) }>
            <Condition>
                <Condition.If expression={ !endMoment }>
                    <Condition>
                        <Condition.If expression={ !!props.showStatus }>
                            <div className={ classNames(props.statusTitleClassName) }>
                                <InfoText
                                    title={ `${getStatusTitle(props.sale?.saleStatus)}` }
                                    iconPosition={ IconPosition.End }
                                    placement={ props.infoTextPlacement }
                                >
                                    <div className="d-flex flex-column">
                                        <span className={ classNames(font(FontDefinition.T6)) }>
                                            { description.title }
                                        </span>
                                        <span className={ classNames(font(FontDefinition.T7)) }>
                                            { description.text }
                                        </span>
                                    </div>
                                </InfoText>
                            </div>
                        </Condition.If>
                    </Condition>
                </Condition.If>
                <Condition.ElseIf expression={ () => endMoment!.isBefore(baseMoment) }>
                    <div className={ classNames(props.statusTitleClassName) }>
                        <InfoText
                            title={ `Period has Expired` }
                            children={ `The "${getStatusTitle(props.sale?.saleStatus)}" period has expired` }
                            iconPosition={ IconPosition.End }
                            placement={ props.infoTextPlacement }
                        />
                    </div>
                    <div className={ classNames("d-flex justify-content-end", props.statusDescriptionTextClassName) }>
                        <span className={ classNames("me-3 text-nowrap") }>
                            { endMoment?.format(DEFAULT_DATE_FORMAT) }
                        </span>
                        <span className={ classNames("text-nowrap") }>
                            { endMoment?.format(DEFAULT_TIME_FORMAT) }
                        </span>
                    </div>
                </Condition.ElseIf>
                <Condition.Else>
                    <Condition>
                        <Condition.If expression={ !!props.showStatus }>
                            <div className={ classNames(props.statusTitleClassName) }>
                                <InfoText
                                    title={ `${getStatusTitle(props.sale?.saleStatus)} Period Ends In` }
                                    iconPosition={ IconPosition.End }
                                    placement={ props.infoTextPlacement }
                                >
                                    <div className="d-flex flex-column">
                                        <span className={ classNames(font(FontDefinition.T6)) }>
                                            { description.title }
                                        </span>
                                        <span className={ classNames(font(FontDefinition.T7)) }>
                                            { description.text }
                                        </span>
                                    </div>
                                </InfoText>
                            </div>
                        </Condition.If>
                    </Condition>
                    <CountdownTimer
                        { ...{
                            ...props,
                            className: props.timerClassName,
                            autostart: true,
                            endMoment: endMoment!,
                            displayableUnits: props.displayableUnits ?? DEFAULT_DISPLAYABLE_UNITS,
                            onElapsed: () =>
                            {
                                setElapsed((old) => !old);
                                props.onElapsed?.();
                            }
                        } }
                    />
                </Condition.Else>
            </Condition>
        </div>
    );
}

function getStatusTitle(status: Undefinable<SaleStatus>)
{
    switch (status)
    {
        case SaleStatus.APPROVED_FOR_FUNDING:
            return "Funding";
        case SaleStatus.CLOSED_BY_BUYBACK:
            return "Closed By Buy-Back";
        case SaleStatus.CLOSED_BY_SELLOFF:
            return "Closed By Sell off";
        case SaleStatus.FUNDED:
            return "Funded";
        case SaleStatus.MARGIN_CALL:
            return "Margin Call";
        case SaleStatus.NOT_FUNDED:
            return "Not Funded";
        case SaleStatus.REJECTED:
            return "Rejected";
        case SaleStatus.REQUESTED:
            return "Requested";
        case SaleStatus.SELLOFF_REQUEST:
            return "Selloff Request";
        case SaleStatus.STARTED:
            return "Buy-Back";
        default:
            return "Non Existent";
    }
}

function calculateEndDate(startDate: BigNumber, duration: BigNumber)
{
    const end = startDate.add(duration);
    const seconds = end.toNumber();

    const endDate = moment.unix(seconds);

    return endDate;
}

export function getActivePeriodEndDate(sale: Undefinable<Sale>): Undefinable<Moment>
{
    if (!sale)
    {
        return undefined;
    }

    switch (sale.saleStatus)
    {
        case SaleStatus.APPROVED_FOR_FUNDING:
        case SaleStatus.FUNDED:
        {
            const endDate = calculateEndDate(
                sale.fundsProvisionStartingTimestamp,
                sale.fundsProvisionDuration
            );

            return endDate;
        }
        case SaleStatus.STARTED:
        case SaleStatus.CLOSED_BY_BUYBACK:
        {
            const buybackStartingTimestamp = sale.fundsProvisionStartingTimestamp.add(sale.buybackDuration);
            const endDate = calculateEndDate(buybackStartingTimestamp, sale.fundsProvisionDuration);

            return endDate;
        }
        default:
            return undefined;
    }
}
