import { Buyer, Sale, Seller } from "@artbanx/nexera/onchain";
import { ERC20__factory, Nullable, ParameterType, Undefinable } from "@artbanx/nexera/system";
import { fetchBalance } from "@wagmi/core";
import { BigNumber } from "ethers";
import { useEffect } from "react";
import { QueryKey, useQuery, UseQueryResult } from "react-query";
import { KycStatus } from "../components/kycProvider/dto/KycUserStatusResultDto";
import { AssetDetailsFilter, AssetFilter } from "../services/assets/assetsService";
import * as AssetsSalesService from "../services/assetsSales/assetsSalesService";
import { AssetSale } from "../services/assetsSales/models/assetSale";
import { AssetSaleDetails } from "../services/assetsSales/models/assetSaleDetails";
import { AssetSaleTransactionEntity } from "../services/assetsSales/models/assetSaleTransactionEntity";
import { getKycUserStatus } from "../services/kyc/service";
import * as UserService from "../services/user/userService";
import * as UserProfileService from "../services/userProfile/userProfileService";
import { VerificationStatus } from "./identityVerifications";
import { useOnchain } from "./onchain";
import { useWallet } from "./wallet";

export enum AvailableAction
{
    NoActionAuthorization,
    NoActionWalletConnection,
    NoActionSaleStatus,
    NoActionExpiredBuyback,
    NoActionExpiredFunding,
    NoActionNoMoreToPurchase,
    NoAtionNoOwnedFractions,
    NoActionClaimFundsNotReady,
    Invest,
    ClaimFunds,
    Buyback
}

export type KeyedQuery<TData = unknown, TError = unknown> = {
    key: QueryKey;
    query: UseQueryResult<TData, TError>;
};

export type QueryOptions<TData> = ParameterType<typeof useQuery<TData>, 2>;

export function useBuyerQuery(
    sale: Undefinable<Sale>,
    options?: QueryOptions<Undefinable<Buyer>>
): KeyedQuery<Undefinable<Buyer>>
{
    const onchain = useOnchain();
    const wallet = useWallet();

    const key = [ "buyer", wallet?.account.address, sale?.id ];
    const query = useQuery(
        key,
        async () =>
        {
            if (!onchain || !wallet?.account.address || !sale)
            {
                return;
            }

            return await onchain.fetchBuyer(sale, wallet.account.address);
        },
        options
    );

    return { key, query };
}

export function useSellerQuery(
    sale: Undefinable<Sale>,
    options?: QueryOptions<Undefinable<Seller>>
): KeyedQuery<Undefinable<Seller>>
{
    const onchain = useOnchain();
    const wallet = useWallet();

    const key = [ "seller", wallet?.account.address, sale?.id ];
    const query = useQuery(
        key,
        async () =>
        {
            if (!onchain || !wallet?.account.address || !sale)
            {
                return;
            }

            return await onchain.fetchSeller(sale);
        },
        options
    );

    return { key, query };
}

export function useAssetsSalesQuery(
    filter?: Nullable<AssetFilter>,
    options?: QueryOptions<Undefinable<AssetSale[]>>
): KeyedQuery<Undefinable<AssetSale[]>>
{
    const onchain = useOnchain();

    const key = [ "assetsSales", filter?.buyerId, filter?.sellerId ];

    const query = useQuery(
        key,
        async () =>
        {
            if (filter === null)
            {
                return [];
            }

            if (!onchain)
            {
                return;
            }

            return await AssetsSalesService.get(onchain, filter);
        },
        options
    );

    useEffect(() =>
    {
        query.refetch();
    }, [ !!onchain ]);

    return { key, query };
}

export function useAssetSaleDetailsQuery(
    filter: Nullable<AssetDetailsFilter>,
    options?: QueryOptions<Undefinable<AssetSaleDetails>>
): KeyedQuery<Undefinable<AssetSaleDetails>>
{
    const onchain = useOnchain();

    const key = [ "assetSaleDetails", filter?.id, filter?.saleId ];
    const query = useQuery(
        key,
        async () =>
        {
            if (!onchain || !filter)
            {
                return;
            }

            return await AssetsSalesService.getDetails(onchain, filter);
        },
        options
    );

    useEffect(
        () =>
        {
            query.refetch();
        },
        [ !!onchain ]
    );

    return { key, query };
}

export function useTransactionsQuery(
    assetSale: Undefinable<AssetSale | AssetSaleDetails>,
    options?: QueryOptions<Undefinable<AssetSaleTransactionEntity[]>>
): KeyedQuery<Undefinable<AssetSaleTransactionEntity[]>>
{
    const onchain = useOnchain();

    const key = [ "assetSaleTransactions", assetSale?.sale.id ];
    const query = useQuery(
        key,
        async () =>
        {
            if (!onchain || !assetSale)
            {
                return;
            }

            const result = await AssetsSalesService.getTransactionsHistory(onchain, assetSale);

            return result;
        },
        options
    );

    useEffect(
        () =>
        {
            query.refetch();
        },
        [ !!onchain ]
    );

    return { key, query };
}

export function useKycStatus(): KeyedQuery<KycStatus>
{
    const userIdentity = UserService.getCurrentIdentity();

    const key = [ "kycStatus", userIdentity.id ];
    const query: UseQueryResult<KycStatus> = useQuery(
        key,
        async () =>
        {
            try
            {
                const userProfile = UserService.getCurrentIdentity();

                if (!UserService.isAnonymous(userProfile))
                {
                    const result = await getKycUserStatus();

                    return result.status;
                }

                return KycStatus.NO_CHECKS_FOUND;
            }
            catch
            {
                return KycStatus.NO_CHECKS_FOUND;
            }
        }
    );

    return { key, query };
}

export function useEmailStatus(): KeyedQuery<VerificationStatus>
{
    const userIdentity = UserService.getCurrentIdentity();

    const key = [ "emailStatus", userIdentity.id ];
    const query = useQuery(
        key,
        async () =>
        {
            try
            {
                let userProfile = UserService.getCurrentIdentity();
                if (!UserService.isAnonymous(userProfile))
                {
                    const result = await UserProfileService.getOneUserProfile();

                    return !!result?.identity.emailVerified
                        ? VerificationStatus.Verified
                        : VerificationStatus.NotVerified;
                }
                return VerificationStatus.NotVerified;
            }
            catch
            {
                return VerificationStatus.NotVerified;
            }
        }
    );

    return { key, query };
}

export type Balance = {
    name?: string;
    symbol: string;
    decimals: number;
    value: BigNumber;
};

export function useBalances(): KeyedQuery<Undefinable<Balance[]>>
{
    const onchain = useOnchain();
    const wallet = useWallet();

    const key = [ "balances", wallet?.account.address ];
    const query = useQuery(
        key,
        async () =>
        {
            if (!onchain || !wallet?.account.address)
            {
                return;
            }

            const result: Balance[] = [];

            const nativeBalance = await fetchBalance({ address: wallet.account.address, chainId: wallet.network.chain?.id });

            result.push({ symbol: nativeBalance.symbol, value: BigNumber.from(nativeBalance.value), decimals: nativeBalance.decimals });

            const fundingCurrencies = await onchain.fetchAcceptedFundingCurrencies();

            for (const currency of fundingCurrencies)
            {
                const erc20 = ERC20__factory.connect(currency.address, onchain.identity);

                const value = await erc20.balanceOf(wallet.account.address);

                result.push(
                    {
                        name: currency.name,
                        symbol: currency.symbol,
                        decimals: currency.decimals,
                        value
                    }
                );
            }

            return result;
        }
    );

    useEffect(() =>
    {
        query.refetch();
    }, [ onchain ]);

    return { key, query };
}
