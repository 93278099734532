import cards from "../components/styles/Cards.module.css";

import * as AssetsSalesService from "../services/assetsSales/assetsSalesService";

import { formatSaleCurrencyScale, formatWithoutScale } from "@artbanx/nexera/helpers";
import { Buyer, ClaimFundsAfterBuybackStages, Onchain } from "@artbanx/nexera/onchain";
import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Loader } from "../components/loader/Loader";
import { ProgressState } from "../components/progressModal/ProgressModal";
import { SaleStatusView } from "../components/saleStatusView/SaleStatusView";
import { classNames } from "../helpers/react";
import { font, FontDefinition } from "../helpers/typography";
import { useOnchain } from "../hooks/onchain";
import { useProgressModal } from "../hooks/progressModal";
import { useBuyerQuery } from "../hooks/queries";
import { useWallet } from "../hooks/wallet";
import { AssetSaleDetails } from "../services/assetsSales/models/assetSaleDetails";
import { InvestmentDetails } from "./InvestmentDetails";

type ClaimFundsBodyProps = {
    assetSale: AssetSaleDetails;
    buyer: Buyer;
    onchain: Onchain;
};

function ClaimFundsBody(props: ClaimFundsBodyProps)
{
    const navigate = useNavigate();
    const progressModal = useProgressModal();
    const [ termsChecked, setTermsChecked ] = useState<boolean>(false);

    const transfer = props.onchain.fetchClaimFundsTransferInfo(props.assetSale.sale, props.buyer);
    const transferValidationResult = props.onchain.fetchClaimFundsTransferValidationResult(props.buyer, transfer);

    async function claimFunds()
    {
        if (!progressModal?.current)
        {
            return;
        }

        const modal = progressModal.current;

        modal
            .setVisible(true)
            .setUserCanHide(false)
            .setProgressState(ProgressState.LOADING)
            .setMessage(undefined)
            .setChildren(undefined)
            .execute();

        await props.onchain.claimFundsAfterBuyback(props.assetSale.sale.id)
            .on(
                ClaimFundsAfterBuybackStages.ApproveERC1155ForABX,
                () =>
                {
                    modal
                        .setMessage("Waiting for ERC1155 approval from signer...")
                        .execute();
                }
            )
            .on(
                ClaimFundsAfterBuybackStages.ReceiveFundsAfterBuyback,
                () =>
                {
                    modal
                        .setMessage("Waiting for funds receiving approval from signer...")
                        .execute();
                }
            )
            .onSuccess(
                () =>
                {
                    modal
                        .setProgressState(ProgressState.SUCCESS)
                        .setMessage("Payment has been successfully completed")
                        .setUserCanHide(true)
                        .setOnHide(() => navigate(generatePath(InvestmentDetails.route, { assetId: props.assetSale.asset.assetId })))
                        .execute();
                }
            )
            .onFail(
                (exc) =>
                {
                    modal
                        .setProgressState(ProgressState.FAIL)
                        .setMessage(exc.reason ?? exc.message ?? "Something went wrong")
                        .setUserCanHide(true)
                        .execute();
                }
            )
            .execute();
    }

    return (
        <div className="container">
            <div className="col-12 m-5">
                <div className="card">
                    <div className="card-body">
                        <div className="row g-3">
                            <div className="col-12 col-md-6">
                                <span className={ classNames("card-title", font(FontDefinition.T0)) }>
                                    Claim Funds
                                </span>
                                <span className={ classNames(font(FontDefinition.T4)) }>
                                    You can now proceed to claim your funds and enjoy the returns on your investment.
                                </span>
                            </div>
                            <div className="col-xl-2 d-none d-xl-block">
                            </div>
                            <div className="col-12 col-md-6 ps-md-2 px-0 pe-md-0 col-xl-4 d-flex align-items-center justify-content-center justify-content-md-end">
                                <div className="w-100 d-flex justfy-content-between bg-body rounded">
                                    <SaleStatusView
                                        showStatus
                                        infoTextPlacement="auto-end"
                                        sale={ props.assetSale.sale }
                                        className="p-3 w-100"
                                        statusTitleClassName={ classNames(
                                            "d-flex",
                                            "justify-content-end",
                                            "mb-2",
                                            font(FontDefinition.T7)
                                        ) }
                                        statusDescriptionTextClassName={ classNames(
                                            font(FontDefinition.T1),
                                            "text-secondary"
                                        ) }
                                        unitValueClassName={ classNames(font(FontDefinition.T1)) }
                                        unitSeparatorClassName={ classNames(font(FontDefinition.T2), "text-secondary", "pb-3") }
                                        unitTitleClassName={ classNames(font(FontDefinition.Small)) }
                                    />
                                </div>
                            </div>
                            <div className="col-12 px-0">
                                <div className="row gx-3 gy-3 p-0 m-0">
                                    <div className="col-12 col-md bg-body rounded me-md-2">
                                        <div className="row gy-5 h-100 p-0 m-0 px-3 pb-5">
                                            <div className="col-12 d-flex flex-column">
                                                <span className={ classNames(font(FontDefinition.T7)) }>Reference Number</span>
                                                <span className={ classNames(font(FontDefinition.T2)) }>{ props.assetSale.asset.assetShortId }</span>
                                            </div>
                                            <div className="col-12 d-flex flex-column">
                                                <span className={ classNames(font(FontDefinition.T7)) }>Tokens Owned</span>
                                                <span className={ classNames(font(FontDefinition.T2)) }>
                                                    <span>
                                                        { `${formatWithoutScale(transfer.nftsToBurn)}` }
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="col-12 d-flex flex-column">
                                                <span className={ classNames(font(FontDefinition.T7)) }>Cost per Fraction</span>
                                                <span className={ classNames(font(FontDefinition.T2)) }>
                                                    { formatSaleCurrencyScale(props.assetSale.sale, props.assetSale.sale.monetaryInfo.pricePerFraction) }
                                                    &nbsp;
                                                    { props.assetSale.sale.fundingCurrency.symbol }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md bg-primary-lt rounded text-dark ms-md-2">
                                        <div className="row m-3">
                                            <div className="col-6 text-left mt-5">Subtotal</div>
                                            <div className="col-6 text-right mt-5" style={ { display: "flex", justifyContent: "flex-end" } }>
                                                <span>
                                                    { `${formatWithoutScale(transfer.nftsToBurn)} ${props.assetSale.sale.fundingCurrency.symbol}` }
                                                </span>
                                            </div>
                                        </div>
                                        <hr className="my-0" />
                                        <div className="row m-3">
                                            <div className="col-6 text-left mt-5">Total</div>
                                            <div className="col-6 text-right mt-5" style={ { display: "flex", justifyContent: "flex-end" } }>
                                                <span>
                                                    { `${
                                                        formatSaleCurrencyScale(props.assetSale.sale, transfer.fundsToReturn)
                                                    } ${props.assetSale.sale.fundingCurrency.symbol}` }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-6">
                                        <div className="form-check">
                                            <input
                                                id="terms-and-conditions"
                                                type="checkbox"
                                                className="form-check-input"
                                                onChange={ (event) => setTermsChecked(event.target.checked) }
                                            />
                                            <div className="d-inline d-flex align-items-center">
                                                <label htmlFor="terms-and-conditions" className={ classNames("form-label", font(FontDefinition.Small)) }>
                                                    I have read and agree to the{" "}
                                                    <a
                                                        target="_blank"
                                                        href={ window.location.origin + "/downloads/pdf/terms_of_services_for_a_buy_back_option.pdf" }
                                                        rel="noopener noreferrer"
                                                    >
                                                        Terms of Services for a Buy-Back Option
                                                    </a>{"  "}
                                                    and{" "}
                                                    <a target="_blank" href={ window.location.origin + "/privacy-policy/" } rel="noopener noreferrer">
                                                        Privacy Policy
                                                    </a>.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6" style={ { display: "flex", justifyContent: "flex-end", paddingRight: "1em" } }>
                                        <input
                                            className="btn btn-primary rounded-pill"
                                            type="button"
                                            style={ { width: "250px" } }
                                            onClick={ claimFunds }
                                            disabled={ !transferValidationResult.valid || !termsChecked }
                                            value="Transfer to Wallet"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ClaimFunds()
{
    const { assetId } = useParams();

    const onchain = useOnchain();
    const wallet = useWallet();

    const [ assetSale, setAssetSale ] = useState<AssetSaleDetails>();
    const [ buyer, setBuyer ] = useState<Buyer>();

    useEffect(fetchAssetSale.asVoid(), [ onchain ]);
    useEffect(fetchBuyer.asVoid(), [ assetSale, onchain ]);

    function reset()
    {
        if (buyer)
        {
            setBuyer(undefined);
        }
    }

    async function fetchAssetSale()
    {
        if (!onchain || !assetId)
        {
            return;
        }

        const assetSale = await AssetsSalesService.getDetails(onchain, { id: assetId });

        setAssetSale(assetSale);
    }

    async function fetchBuyer()
    {
        if (!onchain || !assetSale || !wallet?.account.address)
        {
            reset();
            return;
        }

        const buyer = await onchain.fetchBuyer(
            assetSale.sale,
            wallet.account.address
        );

        setBuyer(buyer);
    }
    const userBuyer = useBuyerQuery(assetSale?.sale, { refetchInterval: 10000 });

    return (
        <Loader message="Please connect your wallet..." dependencies={ { buyer: userBuyer.query.data, assetSale, onchain, signer: onchain?.signer } }>
            { (dependencies) => <ClaimFundsBody { ...dependencies } /> }
        </Loader>
    );
}

export namespace ClaimFunds
{
    export const route = "/ui/claim-funds/:assetId";
}
