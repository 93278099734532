import { GoogleMap, Libraries, useJsApiLoader } from "@react-google-maps/api";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { font, FontDefinition } from "../../helpers/typography";
import { Address } from "../../services/countries/models/address";
import { MappedCountry } from "../../services/countries/models/mappedCountry";
import CountrySelect from "./CountrySelect";

interface AddressSearchProps
{
    id: number;
    setAddress: (address: Address | undefined) => void;
    selectedAddress?: Address | undefined;
    title?: string;
}

const libs: Libraries = [ "places" ];

export const AddressSearch = (props: AddressSearchProps) =>
{
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyDFV5V0IvjpDkFfFGGSYfVLYv-6ViSfDZ4",
        libraries: libs
    });

    const [ _searchInput, setSearchInput ] = useState<string | undefined>(
        props?.selectedAddress?.detailedAddress
            || ""
    );

    // const [ route, setRoute ] = useState<string>(props.selectedAddress?.route);

    function initialize()
    {
        var input = document.getElementById("autocomplete_search" + props.id) as HTMLInputElement;

        const options = {
            // types: [ "street_address" ],
            fields: [ "address_components", "formatted_address" ]
        };

        if (input)
        {
            var autocomplete = new window.google.maps.places.Autocomplete(input, options);

            autocomplete.addListener("place_changed", function()
            {
                var place = autocomplete.getPlace();
                let address = place;
                updateAddress(address);
            });
        }
    }

    function updateAddress(place: any)
    {
        let address = place.address_components;
        let formattedAddress = place.formatted_address;
        let streetNumber = "";
        let route = "";
        let postalCode = "";
        let locality = "";
        let administrativeArea = "";
        let country = "";
        let countrySm = "";

        for (const component of address)
        {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];

            switch (componentType)
            {
                case "street_number":
                {
                    route += ` ${component.long_name} `;
                    break;
                }

                case "route":
                {
                    route += ` ${component.long_name} `;
                    break;
                }

                case "postal_code":
                {
                    postalCode = `${component.long_name}`;
                    break;
                }

                case "postal_code_suffix":
                {
                    postalCode += `-${component.long_name}`;
                    break;
                }

                case "locality":
                    locality = component.long_name;
                    break;

                case "administrative_area_level_1":
                {
                    administrativeArea = component.long_name;
                    break;
                }

                case "country":
                    country = component.long_name;
                    countrySm = component.short_name;
                    break;
            }
        }

        let newAddress = {
            country: country,
            locality: locality,
            administrativeArea: administrativeArea,
            postalCode: postalCode,
            route: route,
            streetNumber: streetNumber,
            detailedAddress: formattedAddress,
            countrySm: countrySm
        } as Address;
        props.setAddress(newAddress);
        setSearchInput(newAddress.detailedAddress);
        // props.getAddress(newAddress);
        return newAddress;
    }

    useEffect(() =>
    {
        initialize();
    }, [ _searchInput ]);

    return (
        <div>
            { props.title
                ? (
                    <h5 className="text-secondary text-left mt-3">
                        { props.title }
                    </h5>
                )
                : <></> }

            <div className="row">
                { isLoaded
                    ? (
                        <div className="col-xl-6 col-lg-6 col-xs-12">
                            <label className={ `${font(FontDefinition.T4)} form-label` } htmlFor="">Address</label>
                            <input
                                id={ "autocomplete_search" + props.id }
                                name="autocomplete_search"
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                value={ _searchInput }
                                onChange={ (e) => setSearchInput(e.target.value) }
                            />
                        </div>
                    )
                    : <></> }

                <div className="col-xl-6 col-lg-6 col-xs-12">
                    <label className={ `${font(FontDefinition.T4)} form-label` } htmlFor="">Country</label>
                    <div className="input-group-flat border rounded">
                        <CountrySelect
                            disabled={ false }
                            selectedValue={ {
                                label: props.selectedAddress?.country,
                                value: props.selectedAddress?.countrySm
                            } }
                            onChange={ (option: MappedCountry | undefined) =>
                            {
                                props.setAddress({
                                    ...props.selectedAddress,
                                    country: option?.label,
                                    countrySm: option?.value
                                });
                            } }
                            key={ props.id }
                            id={ 1 }
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-xs-12">
                        <label className={ `${font(FontDefinition.T4)} form-label` } htmlFor="">
                            Street Address
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={ props.selectedAddress?.route }
                            onChange={ (e) =>
                            {
                                props.setAddress({
                                    ...props.selectedAddress,
                                    route: e.target.value || ""
                                });
                            } }
                        />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-xs-12">
                        <label className={ `${font(FontDefinition.T4)} form-label` } htmlFor="">Town/City</label>
                        <input
                            type="text"
                            className="form-control"
                            value={ props.selectedAddress?.locality }
                            onChange={ (e) =>
                            {
                                if (e.target.value)
                                {
                                    props.setAddress({ ...props.selectedAddress, locality: e.target.value });
                                }
                            } }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <label className={ `${font(FontDefinition.T4)} form-label` } htmlFor="">
                            State/Province/Region
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={ props.selectedAddress?.administrativeArea }
                            onChange={ (e) =>
                            {
                                if (e.target.value)
                                {
                                    props.setAddress({
                                        ...props.selectedAddress,
                                        administrativeArea: e.target.value
                                    });
                                }
                            } }
                        />
                    </div>
                    <div className="col-6">
                        <label className={ `${font(FontDefinition.T4)} form-label` } htmlFor="">Zip/Postal Code</label>
                        <input
                            type="text"
                            className="form-control"
                            value={ props.selectedAddress?.postalCode }
                            onChange={ (e) =>
                            {
                                if (e.target.value)
                                {
                                    props.setAddress({
                                        ...props.selectedAddress,
                                        postalCode: e.target.value
                                    });
                                }
                            } }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
