import { Onchain } from "@artbanx/nexera/onchain";
import { Undefinable } from "@artbanx/nexera/system";
import { useContext } from "react";
import { OnchainContext } from "../components/onchainProvider/OnchainProvider";

export function useOnchain(): Undefinable<Onchain>
{
    const onchain = useContext(OnchainContext);

    return onchain;
}
