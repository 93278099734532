import KycStepper from "../components/kyc/KycStepper";
export function KycManager()
{
    return (
        <>
            <KycStepper />
        </>
    );
}

export namespace KycManager
{
    export const route = "/ui/kyc";
}
