import { Address } from "@artbanx/nexera/system";
import { StatusCodes } from "http-status-codes";
import { getConfiguration } from "../../configuration/configuration";
import { ErrorResponse } from "../shared/errorResponse";
import { UserAssetCorrelation } from "./dto/userAssetCorrelation";
import { UserPurchaseValidationDto } from "./dto/userPurchaseValidationDto";

const USER_PURCHASE_URL = `${getConfiguration().assetApiBaseUrl}/api/user/purchase/validate`;

async function buildResponseException(response: Response)
{
    const message = `Exception happened while validating purchase.`;

    try
    {
        const cause = await response.json() as ErrorResponse;

        return new Error(`${message} ${cause.errorMessage}`, { cause });
    }
    catch
    {
        return new Error(
            message,
            { cause: { status: response.status } }
        );
    }
}

export async function validatePurchase(walletAddress: Address, assetId: string)
{
    const payload: UserPurchaseValidationDto = {
        walletAddress,
        assetId
    };

    const response = await fetch(
        USER_PURCHASE_URL,
        {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(payload)
        }
    );

    if (response.status != StatusCodes.OK)
    {
        throw await buildResponseException(response);
    }

    return await response.json() as UserAssetCorrelation;
}
