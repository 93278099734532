import { BigNumberComparer, formatSaleCurrencyScale, formatWithoutScale } from "@artbanx/nexera/helpers";
import { Onchain } from "@artbanx/nexera/onchain";
import { Delegate, Nullable, Undefinable } from "@artbanx/nexera/system";
import { ApexOptions } from "apexcharts";
import moment from "moment";
import { ReactNode, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useParams } from "react-router-dom";
import { AssetActionButton, NoActionAvailableReason } from "../components/assetActionButton/AssetActionButton";
import { Avatar } from "../components/avatar/Avatar";
import { ClipboardLabel } from "../components/clipboardLabel/ClipboardLabel";
import { BindingsBuilder, Collection, CollectionController, SortType } from "../components/collection/Collection";
import { Condition } from "../components/condition/Condition";
import {
    IdentityVerificationsModal,
    IdentityVerificationsModalController,
    useIdentityVerificationsModal
} from "../components/identityVerificationsModal/IdentityVerificationsModal";
import { IconPosition, InfoText } from "../components/infoText/InfoText";
import { InvestmentImage } from "../components/investmentImage/InvestmentImage";
import { Loader } from "../components/loader/Loader";
import { SaleStatusBadge } from "../components/saleStatusBadge/SaleStatusBadge";
import { SaleStatusView } from "../components/saleStatusView/SaleStatusView";
import { Wallet } from "../components/walletProvider/WalletProvider";
import { DEFAULT_DATETIME_FORMAT } from "../constants";
import { Breakpoint } from "../helpers/bootstrap";
import { classNames } from "../helpers/react";
import { font, FontDefinition } from "../helpers/typography";
import { useAssetDetailsTracking } from "../hooks/assetDetailsTracking";
import { useOnchain } from "../hooks/onchain";
import { useBuyerQuery } from "../hooks/queries";
import { useTransactionsTracking } from "../hooks/transactionsTracking";
import { useWallet } from "../hooks/wallet";
import { useWalletIdentityStatus, WalletIdentityStatus } from "../hooks/walletIdentityStatus";
import { AssetDetailsFilter } from "../services/assets/assetsService";
import { AssetSaleDetails } from "../services/assetsSales/models/assetSaleDetails";
import { AssetSaleTransactionEntity } from "../services/assetsSales/models/assetSaleTransactionEntity";
import { AssetSaleTransactionType } from "../services/assetsSales/models/assetSaleTransactionType";
import { chains } from "../wagmi";

type BlockExplorerPage = {
    name: string;
    url: string;
};

type TransactionCardProps = {
    transaction: AssetSaleTransactionEntity;
    page: Undefinable<BlockExplorerPage>;
    children?: ReactNode;
};

const TRANSACTION_LABEL_LENGTH = 10;

function createTransactionBindings(builder: BindingsBuilder<AssetSaleTransactionEntity>)
{
    builder
        .addBinding(
            "Timestamp",
            (transaction) => transaction.value.blockTimestamp,
            [ SortType.Descending ],
            BigNumberComparer
        );
}

function TransactionCard(props: TransactionCardProps)
{
    return (
        <div className="card h-100">
            <div className="card-body p-2 pb-4 pt-0">
                <div className="row g-2">
                    <div className="col-12">
                        <hr className="m-0 mb-3" />
                    </div>
                    <div className="col-6 col-xxl d-flex flex-column align-items-left justify-content-center">
                        <span className={ classNames(font(FontDefinition.Small)) }>
                            ID
                        </span>
                        <ClipboardLabel
                            className={ classNames(font(FontDefinition.T6)) }
                            text={ formatWithoutScale(props.transaction.value.id) }
                            maxLength={ TRANSACTION_LABEL_LENGTH }
                        />
                    </div>
                    <div className="col-6 col-xxl d-flex flex-column align-items-left justify-content-center">
                        <span className={ classNames(font(FontDefinition.Small)) }>
                            Type
                        </span>
                        <span className={ classNames(font(FontDefinition.T6)) }>
                            { props.transaction.type }
                        </span>
                    </div>
                    { props.children }
                    <div className="col-6 col-xxl d-flex flex-column align-items-left justify-content-center">
                        <span className={ classNames(font(FontDefinition.Small)) }>
                            Block Number
                        </span>
                        <span className={ classNames(font(FontDefinition.T6)) }>
                            { formatWithoutScale(props.transaction.value.blockNumber) }
                        </span>
                    </div>
                    <div className="col-6 col-xxl d-flex flex-column align-items-left justify-content-center">
                        <span className={ classNames(font(FontDefinition.Small)) }>
                            Timestamp
                        </span>
                        <span className={ classNames(font(FontDefinition.T6)) }>
                            { moment.unix(props.transaction.value.blockTimestamp.toNumber()).format(
                                DEFAULT_DATETIME_FORMAT
                            ) }
                        </span>
                    </div>
                    <div className="col-6 col-xxl d-flex flex-column align-items-left justify-content-center">
                        <div className="row h-100">
                            <span className={ classNames(font(FontDefinition.Small)) }>
                                Transaction Hash
                            </span>
                            <div className="d-flex flex-column align-items-left justify-content-center">
                                <ClipboardLabel
                                    text={ props.transaction.value.transactionHash }
                                    className={ classNames(
                                        font(FontDefinition.T6)
                                    ) }
                                    maxLength={ TRANSACTION_LABEL_LENGTH }
                                />
                                <Condition>
                                    <Condition.If expression={ !!props.page }>
                                        <a
                                            className={ classNames(
                                                font(FontDefinition.Small)
                                            ) }
                                            href={ props.page?.url }
                                        >
                                            { props.page?.name }
                                        </a>
                                    </Condition.If>
                                </Condition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

type TransactionViewProps = {
    assetSale: AssetSaleDetails;
    transaction: AssetSaleTransactionEntity;
    getBlockExplorerPage: Delegate<[AssetSaleTransactionEntity], Undefinable<BlockExplorerPage>>;
};

function TransactionView(props: TransactionViewProps)
{
    const page = props.getBlockExplorerPage(props.transaction);

    switch (props.transaction.type)
    {
        case AssetSaleTransactionType.Purchase:
        {
            return (
                <TransactionCard page={ page } transaction={ props.transaction }>
                    <div className="col-6 col-xxl d-flex flex-column align-items-left justify-content-center">
                        <span className={ classNames(font(FontDefinition.Small)) }>
                            Investor
                        </span>
                        <ClipboardLabel
                            text={ props.transaction.value.buyer }
                            className={ classNames(font(FontDefinition.T6)) }
                            maxLength={ TRANSACTION_LABEL_LENGTH }
                        />
                    </div>
                    <div className="col-6 col-xxl d-flex flex-column align-items-left justify-content-center">
                        <span className={ classNames(font(FontDefinition.Small)) }>
                            Fractions Purchased
                        </span>
                        <span className={ classNames(font(FontDefinition.T6)) }>
                            { formatWithoutScale(props.transaction.value.fractionsPurchased) }
                        </span>
                    </div>
                </TransactionCard>
            );
        }
        case AssetSaleTransactionType.FundsReceival:
        {
            return (
                <TransactionCard page={ page } transaction={ props.transaction }>
                    <div className="col-6 col-xxl d-flex flex-column align-items-left justify-content-center">
                        <span className={ classNames(font(FontDefinition.Small)) }>
                            Investor
                        </span>
                        <ClipboardLabel
                            text={ props.transaction.value.buyer }
                            className={ classNames(font(FontDefinition.T6)) }
                            maxLength={ TRANSACTION_LABEL_LENGTH }
                        />
                    </div>
                    <div className="col-6 col-xxl d-flex flex-column align-items-left justify-content-center">
                        <span className={ classNames(font(FontDefinition.Small)) }>
                            Amount received
                        </span>
                        <span className={ classNames(font(FontDefinition.T6)) }>
                            { formatSaleCurrencyScale(props.assetSale.sale, props.transaction.value.amountReceived) }{" "}
                            { props.assetSale.sale.fundingCurrency.symbol }
                        </span>
                    </div>
                </TransactionCard>
            );
        }
        case AssetSaleTransactionType.Buyback:
        {
            return (
                <TransactionCard page={ page } transaction={ props.transaction }>
                    <div className="col-6 col-xxl d-flex flex-column align-items-left justify-content-center">
                        <span className={ classNames(font(FontDefinition.Small)) }>
                            Investor
                        </span>
                        <span className={ classNames(font(FontDefinition.T6)) }>-</span>
                    </div>
                    <div className="col-6 col-xxl d-flex flex-column align-items-left justify-content-center">
                        <span className={ classNames(font(FontDefinition.Small)) }>
                            Amount received
                        </span>
                        <span className={ classNames(font(FontDefinition.T6)) }>-</span>
                    </div>
                </TransactionCard>
            );
        }
    }
}

function buildChartOptions(labels: string[])
{
    // TODO: Re-check hardcoded values,
    // apex-charts provides some things not through style,
    // but SVG attributes, so can't resolve CSS expressions
    const chartOptions: ApexOptions = {
        chart: {
            type: "line",
            fontFamily: "inherit",
            parentHeightOffset: 0,
            toolbar: {
                show: false
            },
            animations: {
                enabled: false
            }
        },
        fill: {
            opacity: 1
        },
        stroke: {
            width: 2,
            lineCap: "round",
            curve: "smooth"
        },
        tooltip: {
            theme: "dark"
        },
        grid: {
            padding: {
                top: -20,
                right: 0,
                left: -4,
                bottom: -4
            },
            strokeDashArray: 4
        },
        xaxis: {
            tooltip: {
                enabled: false
            },
            type: "datetime"
        },
        yaxis: {
            labels: {
                padding: 4
            }
        },
        labels: labels,
        legend: {
            show: false
        },
        noData: {
            align: "center",
            verticalAlign: "middle",
            text: "No data available",
            style: {
                color: "var(--bs-secondary-lt)",
                fontSize: "1.5rem"
            }
        }
    };

    return chartOptions;
}

type AssetActionButtonFallbackProps = {
    reason: NoActionAvailableReason;
    verificationsModal: IdentityVerificationsModalController;
};

function AssetActionButtonFallback(props: AssetActionButtonFallbackProps)
{
    if (props.reason === NoActionAvailableReason.IdentityVerifications)
    {
        return (
            <button
                className={ classNames(
                    classNames(font(FontDefinition.T5)),
                    "w-100 my-0 btn btn-primary btn-pill"
                ) }
                onClick={ () => props.verificationsModal.show() }
            >
                Invest
            </button>
        );
    }

    function getDescription()
    {
        switch (props.reason)
        {
            case NoActionAvailableReason.Authorization:
                return "You are not logged in.";
            case NoActionAvailableReason.IdentityVerifications:
                return "Wallet not connected.";
            case NoActionAvailableReason.AssetStatus:
                return "No action available.";
            case NoActionAvailableReason.ExpiredFunding:
                return "Funding period has expired.";
            case NoActionAvailableReason.ExpiredBuyback:
                return "Buy-Back period has expired.";
            case NoActionAvailableReason.NoMoreToPurchase:
                return "No more fractions available.";
            case NoActionAvailableReason.NoOwnedFractions:
                return "No fractions owned.";
            case NoActionAvailableReason.ClaimFundsNotReady:
                return "You cannot claim funds before the Buy-Back period ends.";
            default:
                return "Currently pending.";
        }
    }

    const description = getDescription();

    return (
        <>
            <button
                className={ classNames(
                    classNames(font(FontDefinition.T5)),
                    "w-100 my-0 btn btn-outline-primary btn-pill"
                ) }
                disabled
            >
                No action available
            </button>
            <InfoText
                placement="auto"
                iconPosition={ IconPosition.End }
                className="d-flex align-items-center"
            >
                { description }
            </InfoText>
        </>
    );
}

type InvestmentDetailsBodyProps = {
    onchain: Onchain;
    wallet: Wallet;
    assetSale: AssetSaleDetails;
    transactions: AssetSaleTransactionEntity[];
};

function InvestmentDetailsBody(props: InvestmentDetailsBodyProps)
{
    const defaultBlockExplorer = props.wallet.network.chain?.blockExplorers?.default
        ?? chains[0]?.blockExplorers?.default;

    const verificationsModal = useIdentityVerificationsModal();
    const identityStatus = useWalletIdentityStatus();
    const status = identityStatus(props.assetSale);
    const buyer = useBuyerQuery(props.assetSale.sale);

    const chartOptions = buildChartOptions(
        props.assetSale.asset.artistStats?.[0]?.annualMarketCaps
            ?.map((cap) => cap.year.toString()) ?? []
    );

    const chartSeries: ApexOptions["series"] = [
        {
            name: "Average Auction Cap",
            data: props.assetSale.asset.artistStats?.[0]?.annualMarketCaps
                ?.map((cap) => cap.value) ?? []
        }
    ];

    function getBlockExplorerPage(transaction: AssetSaleTransactionEntity): Undefinable<BlockExplorerPage>
    {
        if (!defaultBlockExplorer)
        {
            return undefined;
        }

        // TODO: check compatibility with other block-explorers
        return {
            name: defaultBlockExplorer.name,
            url: `${defaultBlockExplorer.url}/tx/${transaction.value.transactionHash}`
        };
    }

    const transactionsController = useRef<CollectionController<AssetSaleTransactionEntity>>(null);

    return (
        <div className="row g-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row g-4">
                            <div className="col-12 col-md-6 d-flex flex-column align-items-start justify-content-center">
                                <SaleStatusBadge
                                    assetSale={ props.assetSale }
                                    className={ classNames("mb-3", font(FontDefinition.Small)) }
                                />
                                <h2 className={ font(FontDefinition.H2) }>
                                    { props.assetSale.asset.assetShortId }
                                </h2>
                            </div>
                            <div className="col-xl-2 d-none d-xl-block">
                            </div>
                            <div className="col-12 col-md-6 ps-md-2 px-0 pe-md-0 mt-2 mt-md-4 col-xl-4 d-flex align-items-center justify-content-center justify-content-md-end">
                                <div className="w-100 d-flex justfy-content-between bg-body rounded">
                                    <SaleStatusView
                                        showStatus
                                        infoTextPlacement="auto-end"
                                        sale={ props.assetSale.sale }
                                        className="p-3 w-100"
                                        statusTitleClassName={ classNames(
                                            "d-flex",
                                            "justify-content-end",
                                            "mb-2",
                                            font(FontDefinition.T7)
                                        ) }
                                        statusDescriptionTextClassName={ classNames(
                                            font(FontDefinition.T1),
                                            "text-secondary"
                                        ) }
                                        unitValueClassName={ classNames(font(FontDefinition.T1)) }
                                        unitSeparatorClassName={ classNames(font(FontDefinition.T2), "text-secondary", "pb-3") }
                                        unitTitleClassName={ classNames(font(FontDefinition.Small)) }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="card">
                    <div className="card-body p-5">
                        <div className="row g-4">
                            <div className="col-12 col-lg-6 col-xxl-3 d-flex justify-content-center">
                                <div className="d-flex d-lg-none justify-content-center w-100 h-100">
                                    <InvestmentImage
                                        { ...{
                                            assetSale: props.assetSale,
                                            showFundingStatusCaption: true,
                                            fundingCaptionClassName: classNames(
                                                font(FontDefinition.T2),
                                                "text-secondary",
                                                "mb-2"
                                            ),
                                            showFundingStatusInfoText: true,
                                            fundingValueClassName: classNames(
                                                font(FontDefinition.H2),
                                                "text-primary"
                                            ),
                                            fundingStatusStrokeWidth: "0.6rem",
                                            imageSize: {
                                                width: "100%",
                                                height: "15rem"
                                            },
                                            fundingStatusSize: "10.4375rem"
                                        } }
                                    />
                                </div>
                                <div className="d-none d-lg-flex d-xxl-none justify-content-center w-100 h-100">
                                    <InvestmentImage
                                        { ...{
                                            assetSale: props.assetSale,
                                            showFundingStatusCaption: true,
                                            fundingCaptionClassName: classNames(
                                                font(FontDefinition.T2),
                                                "text-secondary",
                                                "mb-2"
                                            ),
                                            showFundingStatusInfoText: true,
                                            fundingValueClassName: classNames(
                                                font(FontDefinition.H2),
                                                "text-primary"
                                            ),
                                            fundingStatusStrokeWidth: "0.6rem",
                                            imageSize: {
                                                width: "100%",
                                                height: "100%"
                                            },
                                            fundingStatusSize: "10.4375rem"
                                        } }
                                    />
                                </div>
                                <div className="d-none d-xxl-flex flex-column justify-content-start w-100 h-100">
                                    <InvestmentImage
                                        { ...{
                                            assetSale: props.assetSale,
                                            showFundingStatusCaption: true,
                                            fundingCaptionClassName: classNames(
                                                font(FontDefinition.T2),
                                                "text-secondary",
                                                "mb-2"
                                            ),
                                            showFundingStatusInfoText: true,
                                            fundingValueClassName: classNames(
                                                font(FontDefinition.H2),
                                                "text-primary"
                                            ),
                                            fundingStatusStrokeWidth: "0.6rem",
                                            imageSize: {
                                                width: "100%",
                                                height: "100%"
                                            },
                                            fundingStatusSize: "10.4375rem"
                                        } }
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 col-xxl-9">
                                <div className="row g-4">
                                    <div className="col-6 col-xxl-4 d-flex flex-column align-items-left">
                                        <Condition>
                                            <Condition.If expression={ status === WalletIdentityStatus.Seller }>
                                                <span className={ classNames(font(FontDefinition.T5)) }>
                                                    Fractions Sold
                                                </span>
                                                <span
                                                    className={ classNames(
                                                        font(FontDefinition.T2),
                                                        font(FontDefinition.T1, Breakpoint.LG)
                                                    ) }
                                                >
                                                    { formatWithoutScale(
                                                        props.assetSale?.sale.monetaryInfo.fractionsPurchased
                                                    ) }
                                                    { " / " }
                                                    { formatWithoutScale(
                                                        props.assetSale?.sale.monetaryInfo.maxAmountOfFractions
                                                    ) }
                                                </span>
                                            </Condition.If>
                                            <Condition.ElseIf expression={ () => buyer.query.data?.fractionsBalance?.lte(0) ?? true }>
                                                <span className={ classNames(font(FontDefinition.T5)) }>
                                                    Fractions Available
                                                </span>
                                                <span
                                                    className={ classNames(
                                                        font(FontDefinition.T2),
                                                        font(FontDefinition.T1, Breakpoint.LG)
                                                    ) }
                                                >
                                                    { formatWithoutScale(
                                                        props.assetSale?.sale.monetaryInfo.maxAmountOfFractions
                                                            .sub(props.assetSale.sale.monetaryInfo.fractionsPurchased)
                                                    ) }
                                                    { " / " }
                                                    { formatWithoutScale(
                                                        props.assetSale?.sale.monetaryInfo.maxAmountOfFractions
                                                    ) }
                                                </span>
                                            </Condition.ElseIf>
                                            <Condition.Else>
                                                <span className={ classNames(font(FontDefinition.T5)) }>
                                                    Fractions Owned
                                                </span>
                                                <span
                                                    className={ classNames(
                                                        font(FontDefinition.T2),
                                                        font(FontDefinition.T1, Breakpoint.LG)
                                                    ) }
                                                >
                                                    { formatWithoutScale(buyer.query.data?.fractionsBalance) }
                                                    { " / " }
                                                    { formatWithoutScale(
                                                        props.assetSale?.sale.monetaryInfo.maxAmountOfFractions
                                                    ) }
                                                </span>
                                            </Condition.Else>
                                        </Condition>
                                    </div>
                                    <div className="col-6 col-xxl-4 d-flex flex-column align-items-left">
                                        <span className={ classNames(font(FontDefinition.T5)) }>
                                            Cost per Fraction
                                        </span>
                                        <span
                                            className={ classNames(
                                                font(FontDefinition.T2),
                                                font(FontDefinition.T1, Breakpoint.LG)
                                            ) }
                                        >
                                            { props.assetSale?.sale.fundingCurrency.symbol }
                                            &nbsp;
                                            { formatSaleCurrencyScale(props.assetSale.sale, props.assetSale?.sale.monetaryInfo.pricePerFraction) }
                                        </span>
                                    </div>
                                    <div className="col-6 col-xxl-4 d-flex flex-column align-items-left">
                                        <span className={ classNames(font(FontDefinition.T5)) }>
                                            Buy-Back Duration
                                        </span>
                                        <span
                                            className={ classNames(
                                                font(FontDefinition.T2),
                                                font(FontDefinition.T1, Breakpoint.LG)
                                            ) }
                                        >
                                            { (() =>
                                            {
                                                let duration: number;

                                                try
                                                {
                                                    duration = props.assetSale?.sale?.buybackDuration.toNumber();
                                                }
                                                catch
                                                {
                                                    duration = Number.MAX_SAFE_INTEGER;
                                                }

                                                return moment.duration(
                                                    duration,
                                                    "seconds"
                                                ).asDays().toFixed(0);
                                            })() } { "Days" }
                                        </span>
                                    </div>
                                    <div className="col-6 col-xxl-4 d-flex flex-column align-items-left">
                                        <span className={ classNames(font(FontDefinition.T5)) }>
                                            Expected ROI
                                        </span>
                                        <span
                                            className={ classNames(
                                                font(FontDefinition.T2),
                                                font(FontDefinition.T1, Breakpoint.LG)
                                            ) }
                                        >
                                            { `${props.assetSale.asset.expectedReturn.from}%` }
                                            { "-" }
                                            { `${props.assetSale.asset.expectedReturn.till}%` }
                                        </span>
                                    </div>
                                    <div className="col-6 col-xxl-4 d-flex flex-column align-items-left">
                                        <span className={ classNames(font(FontDefinition.T5)) }>
                                            Asset Value
                                        </span>
                                        <span
                                            className={ classNames(classNames(
                                                font(FontDefinition.T2),
                                                font(FontDefinition.T1, Breakpoint.LG)
                                            )) }
                                        >
                                            { props.assetSale?.sale.fundingCurrency.symbol }
                                            &nbsp;
                                            { formatSaleCurrencyScale(props.assetSale.sale, props.assetSale.sale.oracleInfo.price) }
                                        </span>
                                    </div>
                                    <div className="col-6 col-xxl-4 d-flex flex-column align-items-left">
                                        <span className={ classNames(font(FontDefinition.T5)) }>
                                            Investment Size
                                        </span>
                                        <span
                                            className={ classNames(classNames(
                                                font(FontDefinition.T2),
                                                font(FontDefinition.T1, Breakpoint.LG)
                                            )) }
                                        >
                                            { props.assetSale?.sale.fundingCurrency.symbol }
                                            &nbsp;
                                            { formatSaleCurrencyScale(
                                                props.assetSale.sale,
                                                props.assetSale?.sale.monetaryInfo.maxAmountOfFractions.mul(
                                                    props.assetSale.sale.monetaryInfo.pricePerFraction
                                                )
                                            ) }
                                        </span>
                                    </div>
                                    <div className="d-none d-xxl-block col-4">
                                    </div>
                                    <div className="col-12 col-lg-6 col-xxl-4 mt-lg-6">
                                        <a
                                            className={ classNames(
                                                font(FontDefinition.T5),
                                                "w-100 my-0 btn btn-outline-primary btn-pill"
                                            ) }
                                            href="https://www.arkefi.com/downloads/pdf/art-00100.pdf"
                                            download="art.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="bi bi-file-earmark-pdf mx-2"></i>
                                            Download PDF
                                        </a>
                                    </div>
                                    <div className="col-12 col-lg-6 col-xxl-4 mt-lg-6 text-center d-flex justify-content-center align-items-center">
                                        <AssetActionButton
                                            assetSale={ props.assetSale }
                                            className={ classNames(
                                                "w-100 my-0 btn btn-outline-primary btn-pill",
                                                font(FontDefinition.T5)
                                            ) }
                                        >
                                            { (reason) => (
                                                <AssetActionButtonFallback
                                                    reason={ reason }
                                                    verificationsModal={ verificationsModal }
                                                />
                                            ) }
                                        </AssetActionButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body p-5">
                                <div className="row g-4 align-items-center pt-4">
                                    <div className="col-12">
                                        <span className={ classNames(font(FontDefinition.T1)) }>Artist Statistics</span>
                                    </div>

                                    <div className="col-12 col-lg-auto">
                                        <div className="row g-4">
                                            <div className="col-auto d-flex justify-content-center align-items-center">
                                                <Avatar
                                                    src={ props.assetSale?.asset.artists?.[0]?.imageUrl }
                                                    size="4.6875rem"
                                                />
                                            </div>
                                            <div className="col-auto d-flex flex-column justify-content-center align-items-start">
                                                <span className={ classNames(font(FontDefinition.T1)) }>
                                                    { props.assetSale?.asset.artists?.[0]?.name }
                                                </span>
                                                <span className={ classNames(font(FontDefinition.T5), "text-nowrap") }>
                                                    { props.assetSale?.asset.artists[0]?.city ?? " " }
                                                    { ", " }
                                                    { props.assetSale?.asset.artists[0]?.country ?? " " }
                                                    <wbr />
                                                    { " (" }
                                                    { props.assetSale?.asset.artists[0]?.yearOfBirth }
                                                    { " — " }
                                                    { props.assetSale?.asset.artists[0]?.yearOfDeath ?? " " }
                                                    { ")" }
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg">
                                        <div className="card bg-primary-lt rounded">
                                            <div className="card-body text-dark">
                                                <div className="row g-2">
                                                    <div className="col-12 col-lg-4 d-flex flex-column justify-content-center">
                                                        <strong
                                                            className={ classNames(
                                                                font(FontDefinition.T2),
                                                                "text-green"
                                                            ) }
                                                        >
                                                            { props.assetSale.asset.artistStats?.[0]
                                                                ?.priceAppreciationFactor ?? 0 } { "%" }
                                                            <i className="mx-2 bi bi-arrow-up-right"></i>
                                                        </strong>
                                                        <p
                                                            className={ classNames(
                                                                "text-nowrap",
                                                                font(FontDefinition.T5, Breakpoint.XL),
                                                                font(FontDefinition.T7)
                                                            ) }
                                                        >
                                                            Annual Price Appreciation&nbsp;
                                                            <wbr />
                                                            for Similar Artworks
                                                        </p>
                                                    </div>
                                                    { /**TODO: USD hardcoded */ }
                                                    { /** TODO: Re check calculations */ }
                                                    <div className="col-12 col-lg">
                                                        <div className="row g-4">
                                                            <div className="col-6 d-flex flex-column align-items-left justify-content-center">
                                                                <span
                                                                    className={ classNames(
                                                                        font(FontDefinition.T5, Breakpoint.LG),
                                                                        font(FontDefinition.T7)
                                                                    ) }
                                                                >
                                                                    Auction Record
                                                                </span>
                                                                <span className={ classNames(font(FontDefinition.T2)) }>
                                                                    { "USD" }
                                                                    &nbsp;
                                                                    { formatWithoutScale(
                                                                        props.assetSale.asset.artistStats?.[0]
                                                                            ?.highestPrice
                                                                            ?? 0
                                                                    ) }
                                                                </span>
                                                            </div>
                                                            <div className="col-6 d-flex flex-column align-items-left justify-content-center">
                                                                <span
                                                                    className={ classNames(
                                                                        font(FontDefinition.T5, Breakpoint.XL),
                                                                        font(FontDefinition.T7)
                                                                    ) }
                                                                >
                                                                    Average Price
                                                                </span>
                                                                <span className={ classNames(font(FontDefinition.T2)) }>
                                                                    { "USD" }
                                                                    &nbsp;
                                                                    { formatWithoutScale(
                                                                        props.assetSale.asset.artistStats?.[0]
                                                                            ?.averagePrice
                                                                            ?? 0
                                                                    ) }
                                                                </span>
                                                            </div>
                                                            <div className="col-6 d-flex flex-column align-items-left justify-content-center">
                                                                <span
                                                                    className={ classNames(
                                                                        font(FontDefinition.T5, Breakpoint.XL),
                                                                        font(FontDefinition.T7)
                                                                    ) }
                                                                >
                                                                    Total Auction Market Cap
                                                                </span>
                                                                <span className={ classNames(font(FontDefinition.T2)) }>
                                                                    { "USD" }
                                                                    &nbsp;
                                                                    { formatWithoutScale(
                                                                        props.assetSale.asset.artistStats?.[0]
                                                                            ?.totalMarketCap
                                                                            ?? 0
                                                                    ) }
                                                                </span>
                                                            </div>
                                                            <div className="col-6 d-flex flex-column align-items-left justify-content-center">
                                                                <span
                                                                    className={ classNames(
                                                                        font(FontDefinition.T5, Breakpoint.XL),
                                                                        font(FontDefinition.T7)
                                                                    ) }
                                                                >
                                                                    Total Sell-Through Rate
                                                                </span>
                                                                <span className={ classNames(font(FontDefinition.T2)) }>
                                                                    { props.assetSale.asset.artistStats?.[0]
                                                                        ?.sellThroughRate
                                                                        ?? 0 } %
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 g-2 align-items-center p-3 pb-5" style={ { height: "18rem" } }>
                                    <ReactApexChart
                                        options={ chartOptions }
                                        height={ "100%" }
                                        type={ "line" }
                                        series={ chartSeries }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="card">
                    <div className="card-body p-5">
                        <div className="row g-4">
                            <div className="col-12">
                                <div className="row">
                                    <div className={ props.transactions.length > 0 ? "col-6" : "col-12" }>
                                        <span
                                            className={ classNames(
                                                font(FontDefinition.T1, Breakpoint.SM),
                                                font(FontDefinition.T2)
                                            ) }
                                        >
                                            Transactional History
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <Condition>
                                <Condition.If expression={ props.transactions.length > 0 }>
                                    <div className="col-12">
                                        <div className="row row-cols-1 row-cols-md-2 row-cols-xxl-1">
                                            <Collection
                                                delegated={ true }
                                                data={ props.transactions }
                                                bindings={ createTransactionBindings }
                                                idSelector={ (transaction) => transaction.value.id.toString() }
                                                multiValueSort={ false }
                                                ref={ transactionsController }
                                            >
                                                { (transaction) =>
                                                {
                                                    return (
                                                        <div className="col">
                                                            <TransactionView
                                                                assetSale={ props.assetSale }
                                                                getBlockExplorerPage={ getBlockExplorerPage }
                                                                transaction={ transaction }
                                                            />
                                                        </div>
                                                    );
                                                } }
                                            </Collection>
                                        </div>
                                    </div>
                                </Condition.If>
                                <Condition.Else>
                                    <div className="col-12 text-center">
                                        <span className={ classNames(font(FontDefinition.T1), "text-secondary") }>
                                            No data available
                                        </span>
                                    </div>
                                </Condition.Else>
                            </Condition>
                        </div>
                    </div>
                </div>
            </div>

            <IdentityVerificationsModal controller={ verificationsModal } />
        </div>
    );
}

export function InvestmentDetails()
{
    const onchain = useOnchain();
    const wallet = useWallet();
    const { assetId } = useParams();
    const [ filter ] = useState<Nullable<AssetDetailsFilter>>({ id: assetId });

    const assetSale = useAssetDetailsTracking(filter);
    const transactions = useTransactionsTracking(assetSale);

    return (
        <Loader dependencies={ { onchain, assetSale, transactions, wallet } }>
            { ({ onchain, assetSale, transactions, wallet }) => (
                <InvestmentDetailsBody
                    { ...{ onchain, assetSale, transactions, wallet } }
                />
            ) }
        </Loader>
    );
}

export namespace InvestmentDetails
{
    export const route = "/ui/investment-details/:assetId";
}
