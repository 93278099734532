import { Delegate, Undefinable } from "@artbanx/nexera/system";
import { Dispatch, ForwardedRef, forwardRef, ReactNode, SetStateAction, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Modal as BootstrapModal } from "react-bootstrap";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { Condition } from "../condition/Condition";
import { Spinner } from "../spinner/Spinner";

export enum ProgressState
{
    LOADING,
    SUCCESS,
    FAIL
}

export type ProgressModalProps = {};

export interface ProgressModalController
{
    setOnHide(value: Undefinable<Delegate<[], void>>): this;
    setVisible(value: Undefinable<boolean>): this;
    setProgressState(value: Undefinable<ProgressState>): this;
    setUserCanHide(value: Undefinable<boolean>): this;
    setMessage(value: Undefinable<string>): this;
    setChildren(value: Undefinable<ReactNode>): this;
    execute(): void;
}

export class ProgressModalControllerImpl implements ProgressModalController
{
    private mutations: Delegate<[ProgressModalState]>[] = [];

    public constructor(
        private readonly setState: Dispatch<SetStateAction<ProgressModalState>>
    )
    {
    }

    execute(): void
    {
        const mutations = this.mutations;

        this.setState(
            (state) =>
            {
                let newState = { ...state };

                for (const mutation of mutations)
                {
                    mutation(newState);
                }

                return newState;
            }
        );

        this.mutations = [];
    }

    public setOnHide(value: Undefinable<Delegate<[], void>>): this
    {
        this.mutations.push((state) => state.onHide = value);

        return this;
    }

    public setVisible(value: boolean): this
    {
        this.mutations.push((state) => state.visible = value);

        return this;
    }

    public setProgressState(value: ProgressState): this
    {
        this.mutations.push((state) => state.progressState = value);

        return this;
    }

    public setUserCanHide(value: boolean): this
    {
        this.mutations.push((state) => state.userCanHide = value);

        return this;
    }

    public setMessage(value: Undefinable<string>): this
    {
        this.mutations.push((state) => state.message = value);

        return this;
    }

    public setChildren(value: Undefinable<ReactNode>): this
    {
        this.mutations.push((state) => state.children = value);

        return this;
    }
}

type ProgressModalState = {
    visible: boolean;
    progressState: ProgressState;
    userCanHide: boolean;
    message?: string;
    children?: ReactNode;
    onHide?: Delegate;
};

function ProgressModalComponent(props: ProgressModalProps, ref: ForwardedRef<ProgressModalControllerImpl>)
{
    const isFirstRender = useRef(true);

    const [ state, setState ] = useState<ProgressModalState>(
        {
            progressState: ProgressState.LOADING,
            userCanHide: false,
            visible: false
        }
    );

    function onHideHandler()
    {
        if (state.userCanHide)
        {
            setState((state) => ({ ...state, visible: false }));
            state.onHide?.();
        }
    }

    useImperativeHandle(
        ref,
        () => new ProgressModalControllerImpl(setState),
        []
    );

    useEffect(
        () =>
        {
            if (isFirstRender.current)
            {
                isFirstRender.current = false;
                return;
            }

            if (!state.visible)
            {
                state.onHide?.();
            }
        },
        [ state.visible ]
    );

    return (
        <BootstrapModal centered animation show={ state.visible } onHide={ onHideHandler }>
            <BootstrapModal.Body>
                <div className="row g-4">
                    <Condition>
                        <Condition.If expression={ state.progressState === ProgressState.SUCCESS }>
                            <div className="col-auto d-flex align-items-center justify-content-center">
                                <div className={ classNames(font(FontDefinition.T2)) }>
                                    <i className="text-success bi bi-check-circle-fill"></i>
                                </div>
                            </div>
                        </Condition.If>
                        <Condition.ElseIf expression={ state.progressState === ProgressState.FAIL }>
                            <div className="col-auto d-flex align-items-center justify-content-center">
                                <div className={ classNames(font(FontDefinition.T1)) }>
                                    <i className="text-danger bi bi-x-circle-fill"></i>
                                </div>
                            </div>
                        </Condition.ElseIf>
                        <Condition.ElseIf expression={ state.progressState === ProgressState.LOADING }>
                            <div className="col-auto d-flex align-items-center justify-content-center">
                                <Spinner size="2rem" />
                            </div>
                        </Condition.ElseIf>
                    </Condition>
                    <Condition>
                        <Condition.If expression={ !!state.message }>
                            <div className="col d-flex align-items-center justify-content-center">
                                <span className={ classNames(font(FontDefinition.T4)) }>{ state.message }</span>
                            </div>
                        </Condition.If>
                    </Condition>
                    <Condition>
                        <Condition.If expression={ !!state.children }>
                            <div className="col-12">
                                { state.children }
                            </div>
                        </Condition.If>
                    </Condition>
                </div>
            </BootstrapModal.Body>
        </BootstrapModal>
    );
}

export const ProgressModal = forwardRef(ProgressModalComponent);
