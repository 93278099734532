import { BigNumber } from "ethers";

import { SubgraphListener } from "@artbanx/nexera/subgraph";
import { Undefinable } from "@artbanx/nexera/system";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useOnchain } from "../../hooks/onchain";
import { useWallet } from "../../hooks/wallet";

export const SubgraphContext = createContext<Undefinable<SubgraphListener>>(undefined);

export type SubgraphProviderProps = {
    children: ReactNode;
};

export function SubgraphProvider(props: SubgraphProviderProps)
{
    const onchain = useOnchain();
    const wallet = useWallet();
    const [ listener, setListener ] = useState<SubgraphListener>();

    function initialize()
    {
        if (!onchain || !wallet)
        {
            setListener(undefined);
            return;
        }

        const listener = new SubgraphListener(
            async () => BigNumber.from(await wallet.provider.getBlockNumber()),
            3000,
            onchain.subgraph
        );

        setListener(listener);

        return () => listener.dispose();
    }

    useEffect(initialize, [ !!onchain ]);

    return (
        <SubgraphContext.Provider value={ listener }>
            { props.children }
        </SubgraphContext.Provider>
    );
}
