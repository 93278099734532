import { getConfiguration } from "../../configuration/configuration";
import { CountryDto } from "./dto/countryDto";

const baseUrl = getConfiguration().assetApiBaseUrl;
export async function getAllCountries()
{
    let request = await fetch(`${baseUrl}/api/countries/all`, {
        headers: {
            "content-type": "application/json"
        },
        method: "GET"
    });
    let result = await request.json() as CountryDto[];
    return result;
}

export async function getBlackListedCountries()
{
    let request = await fetch(`${baseUrl}/api/countries/blacklists`, {
        headers: {
            "content-type": "application/json"
        },
        method: "GET"
    });
    let result = await request.json() as CountryDto[];
    return result;
}
