import { Address } from "@artbanx/nexera/system";
import { StatusCodes } from "http-status-codes";
import { getConfiguration } from "../../configuration/configuration";
import { Asset } from "./models/asset";
import { AssetDetails } from "./models/assetDetails";
import { NewAssetDto } from "./models/newAssetDto";

const BASE_URL = getConfiguration().assetApiBaseUrl;

export type AssetFilter = {
    buyerId?: Address;
    sellerId?: Address;
};

export type AssetDetailsFilter = {
    id?: string;
    saleId?: number;
};

export async function get(filter?: AssetFilter): Promise<Asset[]>
{
    const url = `${BASE_URL}/api/asset?${new URLSearchParams({ ...filter, pageSize: "500" })}`;

    const result = await fetch(url);

    if (result.status !== StatusCodes.OK)
    {
        throw new Error("Could not fetch assets", { cause: result.status });
    }

    const assets = await result.json();

    return assets;
}

export async function getDetails(filter: AssetDetailsFilter): Promise<AssetDetails>
{
    function getFetchOptions(): { url: string; options?: RequestInit; }
    {
        if (filter.id !== undefined)
        {
            return {
                url: `${BASE_URL}/api/asset/${filter.id}`
            };
        }
        else if (filter.saleId !== undefined)
        {
            return {
                url: `${BASE_URL}/api/asset/token/${filter.saleId}`
            };
        }
        else
        {
            throw "Either id or saleId must be defined!";
        }
    }

    const options = getFetchOptions();

    const result = await fetch(options.url, options.options);

    if (result.status !== StatusCodes.OK)
    {
        if (result.status === StatusCodes.NOT_FOUND)
        {
            throw new Error("Could not find asset by id", { cause: result.status });
        }

        throw new Error("Could not fetch asset", { cause: result.status });
    }

    const asset = await result.json();

    return asset;
}

export async function put(assetDto: NewAssetDto): Promise<AssetDetails>
{
    const result = await fetch(
        `${BASE_URL}/api/asset`,
        {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(assetDto)
        }
    );

    if (result.status !== StatusCodes.OK)
    {
        throw new Error("Could not create asset", { cause: result.status });
    }

    const asset = await result.json();

    return asset;
}
