import { Picture, PictureSizeMode } from "../picture/Picture";

export type AvatarProps = {
    src?: string;
    size?: string;
    className?: string;
};

export function Avatar(props: AvatarProps)
{
    return (
        <Picture
            { ...{
                src: props.src,
                className: props.className,
                size: { width: props.size, height: props.size },
                sizeMode: PictureSizeMode.Cover,
                style: { borderRadius: "50%" }
            } }
        >
            { /*TODO: No avatar fallback */ }
        </Picture>
    );
}
