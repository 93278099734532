export function injectJWTInterceptor()
{
    let { fetch: originalFetch } = window;
    let baseUrl = import.meta.env.VITE_ASSET_API_URL;

    window.fetch = async (resource, config) =>
    {
        let requestUrl = getUrlFromRequestInfoOrURL(resource);

        if (requestUrl?.startsWith(baseUrl))
        {
            const authToken = window.wordpress.token;
            if (config?.headers)
            {
                config.headers = { ...config?.headers, JWT: authToken || "" };
            }
            // request interceptor ends

            let authenticatedResponse = await originalFetch(resource as any, config);

            // response interceptor here
            return authenticatedResponse;
        }
        else
        {
            let unauthenticatedResponse = await originalFetch(resource as any, config);
            return unauthenticatedResponse;
        }
    };
}

function getUrlFromRequestInfoOrURL(input: unknown): string | null
{
    if (typeof input === "string")
    {
        // If it's a string, treat it as a URL string
        return input;
    }
    else if (input instanceof URL)
    {
        // If it's an instance of URL, you can directly access its properties
        return input.href;
    }
    else
    {
        return null; // Handle unsupported cases or null values
    }
}
