import { Nullable } from "@artbanx/nexera/system";
import { createContext, RefObject, useContext } from "react";
import { ProgressModalController } from "../components/progressModal/ProgressModal";

export const ProgressModalContext = createContext<Nullable<RefObject<ProgressModalController>>>(null);

export function useProgressModal()
{
    const ref = useContext(ProgressModalContext);

    return ref;
}
