import { ReactNode } from "react";
import { Outlet } from "react-router-dom";

export type MainLayoutProps = {
    children?: ReactNode;
};

export function MainLayout(props: MainLayoutProps)
{
    return (
        <div className="content-area" style={ { minHeight: "100vh" } }>
            <div className="site-main">
                <div className="container">
                    { props.children ?? <Outlet /> }
                </div>
            </div>
        </div>
    );
}
