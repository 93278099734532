import * as AssetsService from "../services/assets/assetsService";

import { formatWithoutScale } from "@artbanx/nexera/helpers";
import { RequestSaleStages } from "@artbanx/nexera/onchain";
import { BigNumber, ethers } from "ethers";
import { useState } from "react";
import { ProgressState } from "../components/progressModal/ProgressModal";
import { useOnchain } from "../hooks/onchain";
import { useProgressModal } from "../hooks/progressModal";
import { useWallet } from "../hooks/wallet";
import { AssetType } from "../services/assets/models/assetType";

export function RequestSale()
{
    const onchain = useOnchain();
    const wallet = useWallet();

    const [ minAmountOfFractions, setMinAmountOfFractions ] = useState<string>("50");
    const [ maxAmountOfFractions, setMaxAmountOfFractions ] = useState<string>("100");
    const [ pricePerFraction, setPricePerFraction ] = useState<string>("1");
    const [ interestPercentage, setInterestPercentage ] = useState<string>("0.1");
    const [ fundsProvisionDuration, setFundsProvisionDuration ] = useState<string>("500");
    const [ buybackDuration, setBuybackDuration ] = useState<string>("750");

    const [ saleId, setSaleId ] = useState<BigNumber>();
    const [ assetId, setAssetId ] = useState<string>();
    const progressModal = useProgressModal();

    async function requestSale()
    {
        if (!onchain || !progressModal?.current)
        {
            return;
        }

        const modal = progressModal.current;

        try
        {
            modal
                .setVisible(true)
                .setUserCanHide(false)
                .setProgressState(ProgressState.LOADING)
                .setMessage("Fetching accepted funding currencies...")
                .setChildren(undefined)
                .execute();

            const currencies = await onchain.fetchAcceptedFundingCurrencies();

            const saleId = await onchain.requestSale(
                currencies[0].address,
                ethers.utils.parseUnits(maxAmountOfFractions, 0),
                ethers.utils.parseUnits(minAmountOfFractions, 0),
                ethers.utils.parseEther(pricePerFraction),
                ethers.utils.parseUnits(interestPercentage, 6),
                ethers.utils.parseUnits(fundsProvisionDuration, 0),
                ethers.utils.parseUnits(buybackDuration, 0)
            )
                .on(
                    RequestSaleStages.RequestSale,
                    () =>
                    {
                        modal
                            .setMessage("Waiting signer approval for sale request...")
                            .execute();
                    }
                )
                .onSuccess(
                    () =>
                    {
                        modal
                            .setProgressState(ProgressState.SUCCESS)
                            .setMessage("Request has been successfully created")
                            .execute();
                    }
                )
                .onFail(
                    (exc) =>
                    {
                        modal
                            .setProgressState(ProgressState.FAIL)
                            .setMessage(exc.reason ?? exc.message ?? "Something went wrong")
                            .setUserCanHide(true)
                            .execute();
                    }
                )
                .execute();

            if (!saleId)
            {
                return;
            }

            setSaleId(saleId);

            modal
                .setProgressState(ProgressState.LOADING)
                .setMessage("Creating asset...")
                .execute();

            const response = await AssetsService.put(
                {
                    tokenId: saleId.toNumber(),
                    type: AssetType.ARTWORK,
                    seller: wallet?.account.address,
                    expectedReturn: {
                        from: 4,
                        till: 50
                    },
                    artists: [
                        {
                            dadaId: "undefined",
                            name: "Danh Võ",
                            country: "Germany",
                            city: "Berlin",
                            yearOfBirth: 1975,
                            yearOfDeath: undefined,
                            imageUrl: "https://www.arkefi.com/wp-content/uploads/2023/06/Danh_Vo_cropped.jpg",
                            imageBase64: undefined
                        }
                    ],
                    artworks: [
                        {
                            dadaId: "1231231qwdwd23e1",
                            name: "SomeArtwork",
                            imageBase64: undefined,
                            imageUrl: "https://www.arkefi.com/wp-content/uploads/2023/05/Screenshot-2023-04-05-at-14.38-1-1.png"
                        }
                    ]
                }
            );

            setAssetId(response.assetId);

            modal
                .setProgressState(ProgressState.SUCCESS)
                .setUserCanHide(true)
                .setMessage("Completed!")
                .execute();
        }
        catch (exc)
        {
            modal
                .setProgressState(ProgressState.FAIL)
                .setUserCanHide(true)
                .setMessage("Something went wrong!")
                .execute();
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">Request sale</h4>
            </div>
            <div className="card-body row">
                <div className="col-6">
                    <div className="mb-3">
                        <label className="form-label">Min Amount Of Fractions</label>
                        <input
                            type="text"
                            className="form-control"
                            value={ minAmountOfFractions }
                            onChange={ (event) => setMinAmountOfFractions(event.currentTarget.value) }
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="mb-3">
                        <label className="form-label">Max Amount Of Fractions</label>
                        <input
                            type="text"
                            className="form-control"
                            value={ maxAmountOfFractions }
                            onChange={ (event) => setMaxAmountOfFractions(event.currentTarget.value) }
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="mb-3">
                        <label className="form-label">Price Per Fraction</label>
                        <input
                            type="text"
                            className="form-control"
                            value={ pricePerFraction }
                            onChange={ (event) => setPricePerFraction(event.currentTarget.value) }
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="mb-3">
                        <label className="form-label">Interest Percentage</label>
                        <input
                            type="text"
                            className="form-control"
                            value={ interestPercentage }
                            onChange={ (event) => setInterestPercentage(event.currentTarget.value) }
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="mb-3">
                        <label className="form-label">Funds Provision Duration</label>
                        <input
                            type="text"
                            className="form-control"
                            value={ fundsProvisionDuration }
                            onChange={ (event) => setFundsProvisionDuration(event.currentTarget.value) }
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="mb-3">
                        <label className="form-label">Buyback Duration</label>
                        <input
                            type="text"
                            className="form-control"
                            value={ buybackDuration }
                            onChange={ (event) => setBuybackDuration(event.currentTarget.value) }
                        />
                    </div>
                </div>

                <div className="col-6">
                    <div className="mb-3">
                        <label className="form-label">Sale id</label>
                        <input type="text" className="form-control" readOnly value={ formatWithoutScale(saleId) } />
                    </div>
                </div>
                <div className="col-6">
                    <div className="mb-3">
                        <label className="form-label">Asset id</label>
                        <input type="text" className="form-control" readOnly value={ assetId } />
                    </div>
                </div>
            </div>

            <div className="card-footer text-end">
                <div className="d-flex">
                    <button
                        disabled={ !wallet?.signer }
                        type="submit"
                        className="btn btn-primary ms-auto waves-effect waves-themed"
                        value="Request"
                        onClick={ requestSale }
                    >
                        Request
                    </button>
                </div>
            </div>
        </div>
    );
}

export namespace RequestSale
{
    export const route = "/ui/request-sale";
}
