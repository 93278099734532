import { StatusCodes } from "http-status-codes";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useAccount } from "wagmi";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { useNotifications } from "../../hooks/notifications";
import { getCurrentIdentity } from "../../services/user/userService";
import { UserWalletDto } from "../../services/userWallets/dto/userWalletDto";
import { Wallet } from "../../services/userWallets/models/wallet";
import * as userWalletService from "../../services/userWallets/userWalletsService";
import { NotificationQueue, NotificationType } from "../notificationQueue/NotificationQueue";
import css from "./UserWallet.module.css";
export interface CreateUserModalProps
{
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
}

export function CreateUserWalletModal(props: CreateUserModalProps)
{
    const notificationController = useNotifications();

    const [ _addressTitle, setAddressTitle ] = useState<string>("");
    // const notifications = useNotifications();
    const account = useAccount();
    const user = getCurrentIdentity();

    function isNotEmpty()
    {
        if (_addressTitle.trim().length > 0)
        {
            return true;
        }
        return false;
    }

    async function saveUserAddress()
    {
        let wallet = { walletAddress: account.address, title: _addressTitle } as Wallet;
        let userWallet = { userId: user.id.toString(), wallet: wallet } as UserWalletDto;
        if (isNotEmpty())
        {
            let statusCode = await userWalletService.addUserWallet(userWallet);
            if (statusCode === StatusCodes.OK)
            {
                notificationController.current?.push(
                    <NotificationQueue.Notification type={ NotificationType.Success } delay={ 3000 }>
                        <NotificationQueue.Notification.Heading>
                            <span className={ classNames("text-dark", font(FontDefinition.T4)) }>Wallet Saved Successfully!</span>
                        </NotificationQueue.Notification.Heading>
                    </NotificationQueue.Notification>
                );
            }
            else
            {
                notificationController.current?.push(
                    <NotificationQueue.Notification type={ NotificationType.Error } delay={ 3000 }>
                        <NotificationQueue.Notification.Heading>
                            <span className={ classNames("text-dark", font(FontDefinition.T4)) }>
                                Something went wrong while saving wallet! Please try again later
                            </span>
                        </NotificationQueue.Notification.Heading>
                    </NotificationQueue.Notification>
                );
            }

            props.setModalVisible(false);
            setAddressTitle("");
        }
    }

    return (
        <>
            <Modal show={ props.modalVisible } onHide={ () => props.setModalVisible(false) }>
                <Modal.Body>
                    <h1 className={ `${font(FontDefinition.T1)}` }>Add New Wallet</h1>
                    <p className={ `${font(FontDefinition.T3)}` }>
                        This address does not exists in your address-list would you like to add it ?
                    </p>
                    <div className={ `container ${css.container}` }>
                        <div className="row">
                            <div className="col-12 ">
                                <label htmlFor="addressTitle" className={ `text-secondary form-label ${font(FontDefinition.T6)}` }>Title</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={ _addressTitle }
                                    onChange={ (e) => setAddressTitle(e.target.value) }
                                />
                                { isNotEmpty()
                                    ? <></>
                                    : <p className={ `${font(FontDefinition.T6)} text-danger` }>Title is required!</p> }
                            </div>
                            <div className="col-12">
                                <label htmlFor="address" className={ `text-secondary form-label ${font(FontDefinition.T6)}` }>Address</label>
                                <input
                                    type="text"
                                    disabled={ true }
                                    className="form-control"
                                    value={ account.address }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row-reverse mt-4">
                        <button className={ `${css.btn} btn btn-primary text-white` } onClick={ async () => await saveUserAddress() }>
                            Confirm
                        </button>
                        <button className={ `btn border-primary text-primary me-3 ${css.btn}` } onClick={ () => props.setModalVisible(false) }>
                            Close
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
