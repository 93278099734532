import { Chain, connectorsForWallets } from "@rainbow-me/rainbowkit";
import { metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createConfig } from "wagmi";
import { avalanche, avalancheFuji } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { getConfiguration } from "./configuration/configuration";

const devChains: Chain[] = [ avalancheFuji, avalanche ];
const productionChains: Chain[] = [ avalanche ];

const configuration = getConfiguration();

console.info("Running vite VITE_APP_MODE: " + configuration.chainMode);

const appChains = configuration.chainMode === "development" ? devChains : productionChains;

const { chains, publicClient } = configureChains(
    appChains,
    [ publicProvider() ]
);

const connectors = connectorsForWallets(
    [
        {
            groupName: "Supported wallets",
            wallets: [
                metaMaskWallet(
                    {
                        chains,
                        projectId: configuration.walletConnectProjectId,
                        walletConnectVersion: "2"
                    }
                )
            ]
        }
    ]
);

export const config = createConfig(
    {
        autoConnect: true,
        connectors,
        publicClient
    }
);

export { chains };
