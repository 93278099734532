import { Delegate } from "@artbanx/nexera/system";
import { ReactNode, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { Condition } from "../condition/Condition";
import { Spinner } from "../spinner/Spinner";

export type LoadedDependencies<TDependencies extends DependenciesContainer> = {
    [key in keyof TDependencies]: NonNullable<TDependencies[key]>;
};

export type LoaderProps<TDependencies extends DependenciesContainer> = {
    dependencies: TDependencies;
    message?: string;
    children: Delegate<[LoadedDependencies<TDependencies>], ReactNode>;
};

export type DependenciesContainer = {
    [key: string | number | symbol]: any;
};

function dependenciesLoaded<TDependencies extends DependenciesContainer>(
    dependencies: TDependencies
): dependencies is LoadedDependencies<TDependencies>
{
    for (const key of Object.keys(dependencies))
    {
        const value = dependencies[key];

        if (value === undefined || value === null)
        {
            return false;
        }
    }

    return true;
}

export function Loader<TDependencies extends DependenciesContainer>(props: LoaderProps<TDependencies>)
{
    const loaded = dependenciesLoaded(props.dependencies);

    useEffect(
        () =>
        {
            if (loaded)
            {
                animateScroll.scrollToTop(
                    {
                        duration: 500,
                        smooth: "easeOutQuart" // Easing function for the animation
                    }
                );
            }
        },
        [ loaded ]
    );

    if (loaded)
    {
        return <>{ props.children(props.dependencies) }</>;
    }

    return (
        <div className="d-flex justify-content-center align-items-center">
            <Spinner size="2rem" className="mx-4" />
            <Condition>
                <Condition.If expression={ !!props.message }>
                    <span className={ classNames(font(FontDefinition.T3)) }>
                        { props.message }
                    </span>
                </Condition.If>
            </Condition>
        </div>
    );
}
