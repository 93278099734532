import { isIterable } from "@artbanx/nexera/helpers";
import { Delegate, Emptyable, Nullable, Undefinable } from "@artbanx/nexera/system";
import * as Enumerable from "linq-es5";
import { Children, cloneElement, ForwardedRef, forwardRef, Fragment, isValidElement, ReactElement, ReactNode, RefAttributes } from "react";

export function* filterNodes(nodes: Iterable<ReactNode>)
{
    for (const node of nodes)
    {
        if (isValidElement(node))
        {
            yield node;
        }
    }
}

export function mutateNode(node: ReactNode, onMutate: Delegate<[ReactNode], Undefinable<ReactNode>>): ReactNode
{
    if (isIterable(node))
    {
        return Enumerable.From(filterNodes(node))
            .Select(
                (node, index) =>
                {
                    const mutatedNode = mutateNode(node, onMutate) as ReactElement;

                    return (
                        <Fragment key={ mutatedNode.key ?? index }>
                            { mutatedNode }
                        </Fragment>
                    );
                }
            )
            .ToArray();
    }

    const replacement = onMutate(node);

    if (replacement)
    {
        return replacement;
    }

    if (isValidElement(node))
    {
        // If the node is a valid React element
        const newProps = { ...node.props }; // Copy the props
        let newChildren;

        if (Children.count(node.props.children) > 0)
        {
            // If the node has children, recursively rebuild the subtree
            newChildren = Children.map(node.props.children, (node) => mutateNode(node, onMutate));
        }

        return cloneElement(node, newProps, newChildren);
    }

    return node;
}

export function scanNode(node: ReactNode, onScan: Delegate<[ReactNode]>): void
{
    if (isValidElement(node))
    {
        if (Children.count(node.props.children) > 0)
        {
            // If the node has children, recursively scan the subtree
            Children.forEach(
                node.props.children,
                (node) =>
                {
                    onScan(node);
                    scanNode(node, onScan);
                }
            );
        }
    }
}

export function mapNode<TResult>(node: ReactNode, onMap: Delegate<[ReactNode], Undefinable<TResult>>): TResult[]
{
    const result: TResult[] = [];

    scanNode(
        node,
        (node) =>
        {
            const value = onMap(node);

            if (value !== undefined)
            {
                result.push(value);
            }
        }
    );

    return result;
}

export function safeForwardRef<TRef, TProps = {}>(
    render: (props: TProps, ref: ForwardedRef<TRef>) => Nullable<ReactElement>
): (props: TProps & RefAttributes<TRef>) => Nullable<ReactElement>
{
    return forwardRef(render) as (props: TProps & RefAttributes<TRef>) => Nullable<ReactElement>;
}

export function classNames(...names: Emptyable<string>[])
{
    return names
        .filter((value) => value !== undefined && value !== null)
        .join(" ");
}
