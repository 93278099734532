import { CSSProperties, ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { MediaHeader } from "../components/mediaHeader/MediaHeader";
import { classNames } from "../helpers/react";
import { font, FontDefinition } from "../helpers/typography";

export type MediaHeaderLayoutProps = {
    children?: ReactNode;
};

export function MediaHeaderLayout(props: MediaHeaderLayoutProps)
{
    const slideStyle: CSSProperties = {
        height: "16.8125rem"
    };

    return (
        <>
            <div style={ { width: "101%", position: "absolute", left: 0, top: 0 } }>
                <div
                    className="text-white d-flex flex-column align-items-start justify-content-center p-5"
                    style={ { ...slideStyle, backgroundImage: `url("https://www.arkefi.com/assets/slide.svg")` } }
                >
                    <div className="container">
                        <div className="container">
                            <h3 className={ classNames(font(FontDefinition.H2), "text-nowrap", "d-block") }>
                                Real Assets, <wbr /> Digital Future
                            </h3>
                            <span className={ classNames(font(FontDefinition.T4)) }>Explore Investment Opportunities</span>
                        </div>
                    </div>
                </div>
            </div>
            {
                /* <MediaHeader style={ { width: "101%", position: "absolute", left: 0, top: 0 } }>
                <MediaHeader.Item key={ 0 }>
                    <div
                        className="text-white d-flex flex-column align-items-start justify-content-center p-5"
                        style={ { ...slideStyle, backgroundImage: `url("https://www.arkefi.com/assets/slide.svg")` } }
                    >
                        <div className="container">
                            <div className="container">
                                <h3 className={ classNames(font(FontDefinition.H2), "text-nowrap", "d-block") }>
                                    Real Assets, <wbr /> Digital Future
                                </h3>
                                <span className={ classNames(font(FontDefinition.T4)) }>Explore Investment Opportunities</span>
                            </div>
                        </div>
                    </div>
                </MediaHeader.Item>
                <MediaHeader.Item key={ 1 }>
                    <div
                        className="text-white d-flex flex-column align-items-start justify-content-center p-5"
                        style={ { ...slideStyle, backgroundImage: `url("https://www.arkefi.com/assets/slide.svg")` } }
                    >
                        <div className="container">
                            <div className="container">
                                <h3 className={ classNames(font(FontDefinition.H2), "text-nowrap", "d-block") }>
                                    Real Assets, <wbr /> Digital Future
                                </h3>
                                <span className={ classNames(font(FontDefinition.T4)) }>Explore Investment Opportunities</span>
                            </div>
                        </div>
                    </div>
                </MediaHeader.Item>
                <MediaHeader.Item key={ 2 }>
                    <div
                        className="text-white d-flex flex-column align-items-start justify-content-center p-5"
                        style={ { ...slideStyle, backgroundImage: `url("https://www.arkefi.com/assets/slide.svg")` } }
                    >
                        <div className="container">
                            <div className="container">
                                <h3 className={ classNames(font(FontDefinition.H2), "text-nowrap", "d-block") }>
                                    Real Assets, <wbr /> Digital Future
                                </h3>
                                <span className={ classNames(font(FontDefinition.T4)) }>Explore Investment Opportunities</span>
                            </div>
                        </div>
                    </div>
                </MediaHeader.Item>
            </MediaHeader> */
            }
            <div style={ slideStyle } />
            { props.children ?? <Outlet /> }
        </>
    );
}
