import { useEffect, useState } from "react";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { useNotifications } from "../../hooks/notifications";
import * as CountryService from "../../services/countries/countryService";
import { CountryDto } from "../../services/countries/dto/countryDto";
import { Address } from "../../services/countries/models/address";
import { MappedCountry } from "../../services/countries/models/mappedCountry";
import { User } from "../../services/user/models/user";
import { getCurrentIdentity } from "../../services/user/userService";
import * as userProfileService from "../../services/userProfile/userProfileService";
import * as UserProfileService from "../../services/userProfile/userProfileService";
import { NotificationQueue, NotificationType } from "../notificationQueue/NotificationQueue";
import { AddressSearch } from "../shared/AddressSearch";
import CountrySelect from "../shared/CountrySelect";
import DialingSelect from "../shared/DialingSelect";
import { BlackListAlert } from "./KycBlackListAlert";

interface RefObject
{
    current: {
        showValidation: () => void;
    };
    showValidation: () => void;
}

interface KycProps
{
    setPhase?: (phaseNumber: number) => void;
}

interface PropertyValidation
{
    fnValid: boolean;
    lnValid: boolean;
    nationality: boolean;
    dob: boolean;
    showValidation: boolean;
    isYearValid: boolean;
}

export function KycForm(props: KycProps)
{
    const notificationController = useNotifications();
    const identity = getCurrentIdentity();

    let defaultUser = {
        billingInfo: {
            sameUserAddress: true,
            billingAddress: {}
        },
        companyInfo: {
            isCompany: false,
            companyAddress: {},
            companyName: ""
        },
        userInfo: {
            nationality: {},
            countryOfResidence: "",
            dateOfBirth: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            userAddress: {}
        },
        identity: {
            id: identity.id,
            email: identity.email,
            emailVerified: false,
            login: identity.login
        }
    } as User;

    const [ _user, setUser ] = useState<User>(defaultUser);

    const [ _blacklistedCountries, setBlacklistedCountries ] = useState<CountryDto[]>([]);
    const [ _blackListAlert, setBlackListAlert ] = useState<boolean>(false);

    const [ _propertyValidation, setPropertyValidation ] = useState<PropertyValidation>({
        fnValid: false,
        dob: false,
        lnValid: false,
        nationality: false,
        showValidation: false,
        isYearValid: true
    });

    useEffect(() =>
    {
        getBlacklistedCountries();
        getUserProfile();
    }, []);

    async function getUserProfile()
    {
        let userProfile = await userProfileService.getOneUserProfile();
        console.log(userProfile);
        if (userProfile)
        {
            setUser(userProfile);
        }
    }

    function setDialNumber(e: any)
    {
        setUser((oldUser) =>
        {
            return { ...oldUser, userInfo: { ...oldUser.userInfo, dialNumber: e } };
        });
    }

    function setSelectedCountry(country: MappedCountry)
    {
        if (_user)
        {
            setUser((oldUser) =>
            {
                console.log("Selected country is: " + oldUser.userInfo.nationality?.label);
                return { ...oldUser, userInfo: { ...oldUser.userInfo, nationality: country } };
            });
        }
    }

    async function getBlacklistedCountries()
    {
        let result = await CountryService.getBlackListedCountries();
        console.log(result);
        setBlacklistedCountries(result);
    }

    function setDateofBirth(e: any)
    {
        if (_user)
        {
            const selectedDate = new Date(e.target.value);
            const currentYear = new Date().getFullYear();

            const isYearValid = selectedDate.getFullYear() <= currentYear;

            setPropertyValidation((prevState) => ({
                ...prevState,
                isYearValid: isYearValid
            }));

            setUser((oldUser) =>
            {
                return { ...oldUser, userInfo: { ...oldUser.userInfo, dateOfBirth: e.target.value } };
            });
        }
    }

    function setFirstName(e: any)
    {
        setUser((oldUser) =>
        {
            return { ...oldUser, userInfo: { ...oldUser.userInfo, firstName: e.target.value } };
        });
    }

    function setLastName(e: any)
    {
        setUser((oldUser) =>
        {
            return { ...oldUser, userInfo: { ...oldUser.userInfo, lastName: e.target.value } };
        });
    }

    function setEmail(e: any)
    {
        setUser((oldUser) =>
        {
            return { ...oldUser, identity: { ...oldUser.identity, email: e.target.value } };
        });
    }

    function setPhone(e: any)
    {
        setUser((oldUser) =>
        {
            return { ...oldUser, userInfo: { ...oldUser.userInfo, phoneNumber: e.target.value } };
        });
    }

    function checkBillAddress()
    {
        setUser((oldUser) =>
        {
            return {
                ...oldUser,
                billingInfo: { ...oldUser.billingInfo, sameUserAddress: !oldUser.billingInfo?.sameUserAddress }
            };
        });
    }

    function setBillAddress(e: Address | undefined)
    {
        setUser((oldUser) =>
        {
            return {
                ...oldUser,
                billingInfo: { ...oldUser.billingInfo, billingAddress: e }
            };
        });
    }

    function setUserAddress(address: Address | undefined)
    {
        setUser((oldUser) =>
        {
            return {
                ...oldUser,
                userInfo: { ...oldUser.userInfo, userAddress: address }
            };
        });
    }

    useEffect(() =>
    {
        validateAll();
    }, [ _user.userInfo ]);

    function NationalityBlacklisted()
    {
        let userNationality = _user.userInfo.nationality?.value;

        let mappedBlacklistCountries = _blacklistedCountries.filter((x) => x.code === userNationality);

        if (mappedBlacklistCountries.length > 0)
        {
            setBlackListAlert(true);
            return true;
        }
        else
        {
            return false;
        }
    }

    async function SaveChanges(e: any)
    {
        e.preventDefault();
        let isNationalityBlacklisted = NationalityBlacklisted();
        if (isNationalityBlacklisted)
        {
            return;
        }
        setPropertyValidation((old) =>
        {
            return { ...old, showValidation: true };
        });
        let isFormValid = _propertyValidation.nationality && _propertyValidation.fnValid && _propertyValidation.dob
            && _propertyValidation.lnValid && _propertyValidation.isYearValid;

        if (
            isFormValid
        )
        {
            let data = await userProfileService.saveOneUserProfile(_user);
            let result = await userProfileService.saveUserProfileInWordPress(_user);
            if (data)
            {
                notificationController.current?.push(
                    <NotificationQueue.Notification type={ NotificationType.Success }>
                        <NotificationQueue.Notification.Heading>
                            <span className={ classNames("text-dark", font(FontDefinition.T4)) }>User Profile Saved Successfully!</span>
                        </NotificationQueue.Notification.Heading>
                    </NotificationQueue.Notification>
                );
            }
            if (props.setPhase)
            {
                props.setPhase(1);
            }
        }
        else
        {
            window.scrollTo({ top: 0 });
        }
    }

    function validateAll()
    {
        if (_user.userInfo?.firstName)
        {
            setPropertyValidation((old) =>
            {
                return { ...old, fnValid: true };
            });
        }
        else
        {
            setPropertyValidation((old) =>
            {
                return { ...old, fnValid: false };
            });
        }
        if (_user.userInfo?.lastName)
        {
            setPropertyValidation((old) =>
            {
                return { ...old, lnValid: true };
            });
        }
        else
        {
            setPropertyValidation((old) =>
            {
                return { ...old, lnValid: false };
            });
        }
        setPropertyValidation((old) =>
        {
            return { ...old, dob: validateAge() };
        });
        if (_user.userInfo?.nationality?.label)
        {
            setPropertyValidation((old) =>
            {
                return { ...old, nationality: true };
            });
        }
        else
        {
            setPropertyValidation((old) =>
            {
                return { ...old, nationality: false };
            });
        }
    }

    function validateAge()
    {
        let userDate = _user.userInfo?.dateOfBirth;
        if (userDate)
        {
            var ageDifMs = Date.now() - new Date(userDate)?.getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            let age = Math.abs(ageDate.getUTCFullYear() - 1970);
            let result = age >= 18;
            return result;
        }
        return false;
    }

    let container;

    return (
        <>
            { _blackListAlert
                ? <BlackListAlert />
                : (
                    <div className="container">
                        <form onSubmit={ SaveChanges } className="card p-4">
                            <p className="text-dark fs-t1 lh-t1 fw-t1 ls-t1" style={ { fontFamily: "Outfit" } }>
                                <b>Personal Information</b>
                            </p>

                            <div
                                className={ classNames(
                                    "border border-warning bg-warning-lt rounded text-dark d-flex align-items-center p-2 mb-6",
                                    font(FontDefinition.T5)
                                ) }
                            >
                                <i className={ classNames("bi-exclamation-circle-fill text-warning m-3", font(FontDefinition.T1)) }></i>

                                Please ensure that the information in the profile matches excatly the information in the provided document. <br />
                                Make sure that the image of the document is fitting excatly the frame, that the camera is directly positioned above the document
                                and does not differ in angle.
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-6">
                                        <label className={ `${font(FontDefinition.T4)} form-label` }>
                                            First Name *
                                        </label>
                                        <input
                                            type="text"
                                            value={ _user.userInfo?.firstName }
                                            onChange={ (e) => setFirstName(e) }
                                            className={ `form-control ${
                                                (!_propertyValidation.fnValid && _propertyValidation.showValidation)
                                                    ? "border-red"
                                                    : ""
                                            }` }
                                        />
                                        { _propertyValidation.showValidation && !_propertyValidation.fnValid
                                            ? (
                                                <span className="text-danger">
                                                    First name is required
                                                </span>
                                            )
                                            : <></> }
                                    </div>
                                    <div className="col-6">
                                        <label className={ `${font(FontDefinition.T4)} form-label` }>Last Name *</label>
                                        <input
                                            type="text"
                                            value={ _user.userInfo?.lastName }
                                            onChange={ (e) => setLastName(e) }
                                            style={ { marginTop: "0.5rem" } }
                                            className={ `form-control ${
                                                (!_propertyValidation.lnValid && _propertyValidation.showValidation)
                                                    ? "border-red"
                                                    : ""
                                            }` }
                                        />
                                        { _propertyValidation.showValidation && !_propertyValidation.lnValid
                                            ? (
                                                <span className="text-danger">
                                                    Last name is required
                                                </span>
                                            )
                                            : <></> }
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-xs-12 mobile-mb-2">
                                        <label className={ `${font(FontDefinition.T4)} form-label` }>Nationality *</label>
                                        <div className="input-group-flat border rounded" style={ { marginTop: "0.5rem" } }>
                                            <CountrySelect
                                                id={ 2 }
                                                onChange={ setSelectedCountry }
                                                selectedValue={ _user.userInfo?.nationality }
                                                disabled={ false }
                                                cssClass={ `${
                                                    !_propertyValidation.nationality && _propertyValidation.showValidation
                                                        ? "select-border-red"
                                                        : ""
                                                }` }
                                            />
                                            { _propertyValidation.showValidation && !_propertyValidation.nationality
                                                ? (
                                                    <span className="text-danger">
                                                        Nationality is required
                                                    </span>
                                                )
                                                : <></> }
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-xs-12 ">
                                        <label className={ `${font(FontDefinition.T4)} form-label` }>
                                            Date of Birth *
                                        </label>
                                        <input
                                            type="date"
                                            className={ `form-control ${
                                                (!_propertyValidation.dob && _propertyValidation.showValidation)
                                                    ? "border-red"
                                                    : ""
                                            }` }
                                            onChange={ setDateofBirth }
                                            value={ _user.userInfo?.dateOfBirth }
                                            style={ { marginTop: "0.5rem" } }
                                        />
                                        { _propertyValidation.showValidation && !_propertyValidation.dob
                                                && !_user.userInfo?.dateOfBirth
                                            ? (
                                                <span className="text-danger">
                                                    Date of birth is required
                                                </span>
                                            )
                                            : <></> }
                                        { _propertyValidation.showValidation && !_propertyValidation.dob
                                            ? (
                                                <span className="text-danger">
                                                    Minors are not allowed!
                                                </span>
                                            )
                                            : <></> }
                                        { _propertyValidation.showValidation && !_propertyValidation.isYearValid
                                            ? (
                                                <span className="text-danger">
                                                    Selected year is not valid!
                                                </span>
                                            )
                                            : <></> }
                                    </div>
                                </div>
                            </div>

                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-xs-12 display-none">
                                        <label htmlFor="">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            value={ getCurrentIdentity().email ?? undefined }
                                            disabled={ true }
                                        />
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-xs-12 mobile-mb-2">
                                        <label className={ `${font(FontDefinition.T4)} form-label` }>
                                            Telephone Number
                                        </label>
                                        <div
                                            className="d-flex flex-row align-items-end border"
                                            style={ { borderRadius: "5px", marginTop: "0.5rem" } }
                                        >
                                            <DialingSelect
                                                cssClass=""
                                                onChange={ (e) => setDialNumber(e) }
                                                value={ _user?.userInfo?.dialNumber }
                                                key={ 323 }
                                                placeHolder="CountryCode"
                                            />
                                            <input
                                                type="number"
                                                className="form-control no-borders"
                                                placeholder="Phone Number"
                                                value={ _user?.userInfo?.phoneNumber }
                                                onChange={ (e) =>
                                                {
                                                    if (e.target.value.length <= 18)
                                                    {
                                                        setPhone(e);
                                                    }
                                                } }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <AddressSearch
                                        selectedAddress={ _user.userInfo?.userAddress }
                                        id={ 1 }
                                        key={ 1 }
                                        setAddress={ setUserAddress }
                                        title="Residency Address"
                                    />
                                </div>
                            </div>

                            <hr />

                            <h3 className="text-dark">Billing Information</h3>
                            <div className="form-group mb-2">
                                <div className="row d-flex align-items-center">
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={ _user.billingInfo?.sameUserAddress }
                                            onChange={ checkBillAddress }
                                            id="billChecked"
                                            className="form-check-input"
                                        />
                                        <label htmlFor="" style={ { marginLeft: "1rem", marginTop: "0.5rem" } }>
                                            My billing address is the same as my personal address
                                        </label>
                                    </div>
                                </div>
                            </div>
                            { !_user.billingInfo?.sameUserAddress
                                ? (
                                    <AddressSearch
                                        selectedAddress={ _user.billingInfo?.billingAddress }
                                        setAddress={ setBillAddress }
                                        id={ 3 }
                                        key={ 3 }
                                        title="Billing Address"
                                    />
                                )
                                : <></> }

                            <div className="row mt-5">
                                <div className="d-flex flex-row-reverse">
                                    <button className="btn btn-primary text-white mobile-w-100">Continue</button>
                                </div>
                            </div>
                        </form>
                    </div>
                ) }
        </>
    );
}

export namespace KycForm
{
    export const route = "/ui/kyc/profile";
}
