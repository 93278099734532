import { PropertyType, Undefinable } from "@artbanx/nexera/system";
import { ConnectedWalletButton } from "./ConnectedWalletButton";
import { ConnectWalletButton } from "./ConnectWalletButton";
import { RainbowKitConnectButtonRendererProps } from "./RainbowKitConnectButtonRendererProps";
import { WrongNetworkButton } from "./WrongNetworkButton";

export type WalletStatusProps = {
    connected?: Undefinable<boolean>;
    chain?: PropertyType<RainbowKitConnectButtonRendererProps, "chain">;
    account?: PropertyType<RainbowKitConnectButtonRendererProps, "account">;
    openConnectModal: PropertyType<RainbowKitConnectButtonRendererProps, "openConnectModal">;
    openChainModal: PropertyType<RainbowKitConnectButtonRendererProps, "openChainModal">;
    openAccountModal: PropertyType<RainbowKitConnectButtonRendererProps, "openAccountModal">;
};

export function WalletStatus(props: WalletStatusProps)
{
    if (!props.connected)
    {
        return <ConnectWalletButton { ...props } />;
    }

    if (props.chain?.unsupported)
    {
        return <WrongNetworkButton { ...props } />;
    }

    return <ConnectedWalletButton { ...props } />;
}
