import { providers, Signer } from "ethers";
import * as React from "react";
import { type HttpTransport } from "viem";
import { type PublicClient, usePublicClient, useWalletClient, WalletClient } from "wagmi";

function walletClientToSigner(walletClient: WalletClient): Signer
{
    const network = {
        chainId: walletClient.chain.id,
        name: walletClient.chain.name,
        ensAddress: walletClient.chain.contracts?.ensRegistry?.address
    };

    const provider = new providers.Web3Provider(walletClient.transport, network);
    const signer = provider.getSigner(walletClient.account.address);

    return signer;
}

export function useEthersSigner({ chainId }: { chainId?: number; } = {})
{
    const { data: walletClient } = useWalletClient({ chainId });

    return React.useMemo(
        () => (walletClient ? walletClientToSigner(walletClient) : undefined),
        [ walletClient ]
    );
}

function publicClientToProvider(publicClient: PublicClient): providers.Provider
{
    const network = {
        chainId: publicClient.chain.id,
        name: publicClient.chain.name,
        ensAddress: publicClient.chain.contracts?.ensRegistry?.address
    };

    if (publicClient.transport.type === "fallback")
    {
        const transports: ReturnType<HttpTransport>[] = publicClient.transport.transports;

        return new providers.FallbackProvider(
            transports.map(
                (transport) => new providers.JsonRpcProvider(transport.value?.url, network)
            )
        );
    }

    return new providers.JsonRpcProvider(publicClient.transport.url, network);
}

export function useEthersProvider({ chainId }: { chainId?: number; } = {})
{
    const publicClient = usePublicClient({ chainId });

    return React.useMemo(() => publicClientToProvider(publicClient), [ publicClient ]);
}
