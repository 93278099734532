import { StatusCodes } from "http-status-codes";
import { getConfiguration } from "../../configuration/configuration";
import { User } from "./models/user";
import { AuthData } from "./models/wordpressApi/authData";
import { AuthValidationData } from "./models/wordpressApi/authValidationData";
import { ErrorData } from "./models/wordpressApi/errorData";
import { Response } from "./models/wordpressApi/response";
import { UserData } from "./models/wordpressApi/userData";
import { WordpressUser } from "./models/wordpressUser";
import { WordpressBilling, WordpressUserData } from "./models/wordpressUserData";
import { UserIdentity } from "./userIdentity";

const configuration = getConfiguration();
const AUTH_KEY = "wordpress_auth";

export function getCurrentIdentity(): UserIdentity
{
    if (window.wordpress)
    {
        return {
            id: window.wordpress.user_id,
            email: window.wordpress.user_email,
            login: window.wordpress.user_email,
            avatar: window.wordpress.user_avatar
        };
    }

    return { id: 0 };
}

export async function login(email: string, password: string)
{
    const url = `${configuration.publicBaseUrl}/wp-json/?rest_route=/simple-jwt-login/v1/auth`;

    const form = new FormData();

    form.append("email", email);
    form.append("password", password);

    const response = await fetch(
        url,
        {
            method: "POST",
            body: form
        }
    );

    if (response.status === StatusCodes.OK)
    {
        const authData = await response.json() as Response<AuthData>;

        return authData;
    }

    if (response.status >= StatusCodes.INTERNAL_SERVER_ERROR)
    {
        throw new Error(`Internal error happened on ${configuration.publicBaseUrl}`, { cause: response.status });
    }
    else
    {
        const error = await response.json() as Response<ErrorData>;

        throw new Error(error.data.message, { cause: error.data.errorCode });
    }
}

export async function logout()
{
    if (!window?.wordpress?.token)
    {
        return;
    }

    const url = `${configuration.publicBaseUrl}/wp-json/?rest_route=/simple-jwt-login/v1/auth/revoke`;

    const form = new FormData();

    form.append("JWT", window.wordpress.token);

    const response = await fetch(
        url,
        {
            method: "POST",
            body: form
        }
    );

    window.wordpress = { user_id: 0 };
    localStorage.removeItem(AUTH_KEY);

    if (response.status === StatusCodes.OK)
    {
        return;
    }

    if (response.status >= StatusCodes.INTERNAL_SERVER_ERROR)
    {
        throw new Error(`Internal error happened on ${configuration.publicBaseUrl}`, { cause: response.status });
    }
    else
    {
        const error = await response.json() as Response<ErrorData>;

        throw new Error(error.data.message, { cause: error.data.errorCode });
    }
}

async function validate(data: AuthData)
{
    const url = `${configuration.publicBaseUrl}/wp-json/?rest_route=/simple-jwt-login/v1/auth/validate`;

    const form = new FormData();

    form.append("JWT", data.jwt);

    const response = await fetch(
        url,
        {
            method: "POST",
            body: form
        }
    );

    if (response.status === StatusCodes.OK)
    {
        const authData = await response.json() as Response<AuthValidationData>;

        return authData.data;
    }

    if (response.status >= StatusCodes.INTERNAL_SERVER_ERROR)
    {
        throw new Error(`Internal error happened on ${configuration.publicBaseUrl}`, { cause: response.status });
    }
    else
    {
        const error = await response.json() as Response<ErrorData>;

        throw new Error(error.data.message, { cause: error.data.errorCode });
    }
}

export async function useAuthorization(data: AuthData)
{
    const validationData = await validate(data);

    const url = `${configuration.publicBaseUrl}/wp-json/wp/v2/users/${validationData.user.ID}`;

    const response = await fetch(
        url,
        {
            method: "GET",
            headers: {
                Authorization: `Bearer ${data.jwt}`
            }
        }
    );

    if (response.status === StatusCodes.OK)
    {
        const user = await response.json() as UserData;

        function avatar(size: number = 50)
        {
            for (const key in user.avatar_urls)
            {
                const avatarSize = parseInt(key);

                if (isNaN(avatarSize) || avatarSize < size)
                {
                    continue;
                }

                return user.avatar_urls[key];
            }

            return null;
        }

        window.wordpress = {
            user_id: user.id,
            user_email: validationData.user.user_email,
            user_login: validationData.user.user_login,
            token: data.jwt,
            user_avatar: avatar()
        };

        localStorage.setItem(AUTH_KEY, JSON.stringify(window.wordpress));

        return;
    }

    if (response.status >= StatusCodes.INTERNAL_SERVER_ERROR)
    {
        throw new Error(`Internal error happened on ${configuration.publicBaseUrl}`, { cause: response.status });
    }
    else
    {
        const error = await response.json() as Response<ErrorData>;

        throw new Error(error.data.message, { cause: error.data.errorCode });
    }
}

export function isAnonymous(user: UserIdentity)
{
    return user.id === 0;
}

export function convert(user: User): WordpressUserData
{
    return {
        first_name: user.userInfo.firstName,
        last_name: user.userInfo.lastName,
        id: user.identity.id,
        billing: checkBillingAddress(user),
        shipping: { ...checkBillingAddress(user) }
    } as WordpressUserData;
}

function checkBillingAddress(user: User): WordpressBilling
{
    const address = user.billingInfo?.sameUserAddress
        ? user.userInfo?.userAddress
        : user.billingInfo?.billingAddress;

    return {
        address_1: address?.route,
        address_2: address?.detailedAddress,
        city: address?.locality,
        country: address?.country,
        email: user.identity.email,
        first_name: user.userInfo.firstName,
        last_name: user.userInfo.lastName,
        phone: user.userInfo.phoneNumber,
        postcode: address?.postalCode,
        state: address?.administrativeArea
    } as WordpressBilling;
}

export async function useAuthorizationCache()
{
    const cache = localStorage.getItem(AUTH_KEY);

    if (cache)
    {
        const wordpress = JSON.parse(cache) as WordpressUser;

        if (wordpress.token)
        {
            try
            {
                await validate({ jwt: wordpress.token });

                window.wordpress = wordpress;

                return;
            }
            catch
            {
                // swallow
            }
        }
    }

    localStorage.removeItem(AUTH_KEY);
}
