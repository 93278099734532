import { Delegate, Emptyable, Nullable } from "@artbanx/nexera/system";
import {
    Children,
    createRef,
    ForwardedRef,
    forwardRef,
    isValidElement,
    Key,
    ReactElement,
    RefObject,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from "react";
import { ReactNode } from "react";
import { Toast } from "react-bootstrap";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { ReactChildren } from "../../types/reactChildren";

export type NotificationChild =
    | NotificationBodyProps
    | NotificationHeadingProps
    | NotificationFooterProps;

export type NotificationProps = {
    children: ReactChildren<NotificationChild>;
    type: NotificationType;
    delay?: Nullable<number>;
    onClose?: Delegate;
};

export type NotificationHeadingProps = {
    children: ReactNode;
};

export type NotificationBodyProps = {
    children: ReactNode;
};

export type NotificationFooterProps = {
    children: ReactNode;
};

export enum NotificationType
{
    Info,
    Success,
    Warning,
    Error
}

type NotificationMeta = {
    ref: RefObject<NotificationControllerImpl>;
    props: NotificationProps;
    key: Emptyable<Key>;
};

type ToastBodyProps = {
    type: NotificationType;
    onClose: Delegate;
    heading?: ReactNode;
    body?: ReactNode;
    footer?: ReactNode;
};

function ToastBody(props: ToastBodyProps)
{
    var borderTypeClass;
    var backgroundColorClass;
    var iconClass;
    switch (props.type)
    {
        case NotificationType.Error:
        {
            borderTypeClass = "border-danger";
            backgroundColorClass = "bg-danger-lt";
            iconClass = "bi-x-circle-fill";

            break;
        }

        case NotificationType.Warning:
        {
            borderTypeClass = "border-warning";
            backgroundColorClass = "bg-warning-lt";
            iconClass = "bi-exclamation-circle-fill";

            break;
        }

        case NotificationType.Success:
        {
            borderTypeClass = "border-success";
            backgroundColorClass = "bg-success-lt";
            iconClass = "bi-check-circle-fill";

            break;
        }

        default:
        {
            borderTypeClass = "border-info";
            backgroundColorClass = "bg-info-lt";
            iconClass = "bi-info-circle-fill";

            break;
        }
    }

    return (
        <Toast.Body
            className={ classNames(
                "py-1 py-md-3 text-justify border",
                borderTypeClass,
                "rounded",
                backgroundColorClass
            ) }
        >
            <div className="row px-2">
                <div className="col-1 d-flex align-items-center">
                    <i className={ classNames("bi", iconClass, font(FontDefinition.T1)) }></i>
                </div>
                <div className="col-auto col-md-10 text-dark d-flex align-items-center">
                    { props.heading }
                </div>
                <div className="d-md-none col d-flex align-items-center">
                    { props.footer }
                </div>
                <div className="col-1 d-flex align-items-center justify-content-center">
                    <button
                        onClick={ props.onClose }
                        className={ classNames("btn-close") }
                    >
                    </button>
                </div>
                <div className="col-1 d-none d-md-block">
                </div>
                <div className="col-10 d-none d-md-block">
                    { props.body }
                </div>
                <div className="col-1 d-none d-md-block">
                </div>
                <div className="col-1 d-none d-md-block">
                </div>
                <div className="col-10 d-none d-md-block">
                    { props.footer }
                </div>
                <div className="col-1 d-none d-md-block">
                </div>
            </div>
        </Toast.Body>
    );
}

export interface NotificationController
{
    close(): void;
}

class NotificationControllerImpl implements NotificationControllerImpl
{
    public constructor(
        private readonly onClose: Delegate
    )
    {
    }

    public close()
    {
        this.onClose();
    }
}

type InternalNotificationProps = {
    heading?: ReactNode;
    body?: ReactNode;
    footer?: ReactNode;
    type: NotificationType;
    delay?: Nullable<number>;
    onClose: Delegate;
};

function InternalNotificationComponent(
    props: InternalNotificationProps,
    ref: ForwardedRef<NotificationControllerImpl>
)
{
    const [ visible, setVisible ] = useState<boolean>(true);

    function onClose()
    {
        setVisible(false);
    }

    useImperativeHandle(
        ref,
        () => new NotificationControllerImpl(onClose)
    );

    useEffect(
        () =>
        {
            if (!visible)
            {
                // Leaving a sec for animation
                const timeout = setTimeout(props.onClose, 1000);

                return () => clearTimeout(timeout);
            }
        },
        [ visible ]
    );

    return (
        <Toast
            className="w-100 px-1 pb-3 pb-md-0 pt-md-3 bg-transparent border-0"
            { ...{ ...props, show: visible, delay: props.delay ?? undefined } }
        >
            <ToastBody { ...props } onClose={ onClose } />
        </Toast>
    );
}

const InternalNotification = forwardRef(InternalNotificationComponent);

export type NotificationQueueProps = {
    visibleNotificationsCount?: number;
    delay?: number;
};

function NotificationQueueComponent(props: NotificationQueueProps, ref: ForwardedRef<NotificationQueueController>)
{
    const key = useRef(0);
    const autoCollectTimer = useRef<NodeJS.Timeout>();
    const [ notificationsMeta, setNotificationsMeta ] = useState<NotificationMeta[]>([]);

    function add(notifcation: NotificationMeta)
    {
        setNotificationsMeta(
            (notifications) =>
            {
                const result = [ ...notifications, notifcation ];

                resetAutoCollectTimer();

                return result;
            }
        );
    }

    function remove(notification: NotificationMeta)
    {
        setNotificationsMeta(
            (notifications) =>
            {
                const result = notifications
                    .filter((current) => current.key !== notification.key);

                resetAutoCollectTimer();

                return result;
            }
        );
    }

    function dispose()
    {
        if (autoCollectTimer.current)
        {
            clearTimeout(autoCollectTimer.current);
        }
    }

    function resetAutoCollectTimer()
    {
        dispose();

        if (notificationsMeta.length <= 0)
        {
            return;
        }

        const lastNotificationMeta = notificationsMeta
            .findLast((notificationMeta) => notificationMeta.props.delay !== null);

        if (!lastNotificationMeta)
        {
            return;
        }

        autoCollectTimer.current = setTimeout(
            () => lastNotificationMeta.ref.current?.close(),
            lastNotificationMeta.props.delay
                ?? props.delay
                ?? 3000
        );
    }

    useImperativeHandle(
        ref,
        () => new NotificationQueueController(() => key.current++, add)
    );

    useEffect(
        () =>
        {
            resetAutoCollectTimer();

            return dispose;
        },
        [ notificationsMeta ]
    );

    function* generateActiveNotifications()
    {
        const start = notificationsMeta.length - 1;
        const endOffset = start - (props.visibleNotificationsCount ?? 5) + 1;
        const end = endOffset > 0 ? endOffset : 0;

        for (let index = start; index >= end; --index)
        {
            const notificationMeta = notificationsMeta[index];

            function onClose()
            {
                remove(notificationMeta);
                notificationMeta.props.onClose?.();
            }

            const body = Children.map(
                notificationMeta.props.children,
                (node) =>
                {
                    if (!isValidElement(node))
                    {
                        return;
                    }

                    switch (node.type)
                    {
                        case NotificationQueue.Notification.Body:
                            return node.props as NotificationBodyProps;
                    }
                }
            )?.[0]?.children;

            const heading = Children.map(
                notificationMeta.props.children,
                (node) =>
                {
                    if (!isValidElement(node))
                    {
                        return;
                    }

                    switch (node.type)
                    {
                        case NotificationQueue.Notification.Heading:
                            return node.props as NotificationHeadingProps;
                    }
                }
            )?.[0]?.children;

            const footer = Children.map(
                notificationMeta.props.children,
                (node) =>
                {
                    if (!isValidElement(node))
                    {
                        return;
                    }

                    switch (node.type)
                    {
                        case NotificationQueue.Notification.Footer:
                            return node.props as NotificationFooterProps;
                    }
                }
            )?.[0]?.children;

            yield (
                <InternalNotification
                    ref={ notificationMeta.ref }
                    key={ notificationMeta.key }
                    { ...{
                        ...notificationMeta.props,
                        onClose,
                        heading,
                        body,
                        footer
                    } }
                />
            );
        }
    }

    return (
        <div
            className="position-fixed start-0 bottom-0 start-md-unset bottom-md-unset end-md-0 top-md-header w-toast-wide w-md-50 w-xl-toast-thin"
            style={ { zIndex: 900000 } }
        >
            { [ ...generateActiveNotifications() ] }
        </div>
    );
}

export const NotificationQueue = Object.assign(
    forwardRef(NotificationQueueComponent),
    {
        Notification: Object.assign(
            (props: NotificationProps): JSX.Element =>
            {
                throw "Notification can't be rendered outside of notification queue!";
            },
            {
                Heading: (props: NotificationHeadingProps): JSX.Element =>
                {
                    throw "Notification header can't be rendered outside of notification!";
                },
                Body: (props: NotificationBodyProps): JSX.Element =>
                {
                    throw "Notification body can't be rendered outside of notification!";
                },
                Footer: (props: NotificationBodyProps): JSX.Element =>
                {
                    throw "Notification footer can't be rendered outside of notification!";
                }
            }
        )
    }
);

export class NotificationQueueController
{
    public constructor(
        private readonly keyGenerator: Delegate<[], number>,
        private readonly add: Delegate<[NotificationMeta]>
    )
    {
    }

    public push(notification: ReactElement<NotificationProps>): RefObject<NotificationControllerImpl>
    {
        const resolvedProps = Children.map(
            notification,
            (node) =>
            {
                if (!isValidElement(node))
                {
                    return;
                }

                switch (node.type)
                {
                    case NotificationQueue.Notification:
                        return node.props;
                }
            }
        );

        if (!resolvedProps || resolvedProps.length <= 0)
        {
            throw "Not supported item was pushed to notifications queue!";
        }

        const props = resolvedProps[0];
        const key = this.keyGenerator();
        const ref = createRef<NotificationControllerImpl>();

        const meta: NotificationMeta = {
            key,
            props,
            ref
        };

        this.add(meta);

        return ref;
    }
}
