import { DurationUnit } from "./components/countdownTimer/CountdownTimer";

export const DEFAULT_DISPLAYABLE_UNITS = [
    DurationUnit.YEAR,
    DurationUnit.MONTH,
    DurationUnit.DAY,
    DurationUnit.HOUR,
    DurationUnit.MINUTE,
    DurationUnit.SECOND
];

export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_TIME_FORMAT = "HH:mm:ss UTC";
export const DEFAULT_DATETIME_FORMAT = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;
