import { StatusCodes } from "http-status-codes";
import { RefObject } from "react";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { NotificationQueue, NotificationQueueController, NotificationType } from "../notificationQueue/NotificationQueue";

export function NotifySuccess(notifications: RefObject<NotificationQueueController>, textMessage: string)
{
    notifications.current?.push(
        <NotificationQueue.Notification type={ NotificationType.Success } delay={ 2000 }>
            <NotificationQueue.Notification.Heading>
                <span className={ classNames("text-dark", font(FontDefinition.T4)) }>
                    { textMessage }
                </span>
            </NotificationQueue.Notification.Heading>
        </NotificationQueue.Notification>
    );
}

export function NotifyError(notifications: RefObject<NotificationQueueController>, textMessage: string)
{
    notifications.current?.push(
        <NotificationQueue.Notification type={ NotificationType.Warning } delay={ 2000 }>
            <NotificationQueue.Notification.Heading>
                <span className={ classNames("text-dark", font(FontDefinition.T4)) }>
                    { textMessage }
                </span>
            </NotificationQueue.Notification.Heading>
        </NotificationQueue.Notification>
    );
}

export function NotifyUser(notifications: RefObject<NotificationQueueController>, status: StatusCodes, successMessage: string, failureMessage: string)
{
    switch (status)
    {
        case StatusCodes.OK:
            NotifySuccess(notifications, successMessage);
            break;
        default:
            NotifyError(notifications, failureMessage);
            break;
    }
}
