export enum Breakpoint
{
    XS = "xs", // Extra Small
    SM = "sm", // Small
    MD = "md", // Medium
    LG = "lg", // Large
    XL = "xl", // Extra Large
    XXL = "xxl" // Extra Extra Large
}

export type BootstrapClassName = {
    utility: string;
    value?: string;
    breakpoint?: Breakpoint;
};

function suffix(value?: string)
{
    return value ? `-${value}` : "";
}

export function className({ utility: action, breakpoint, value }: BootstrapClassName)
{
    return `${action}${suffix(breakpoint)}${suffix(value)}`;
}
