import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { font, FontDefinition } from "../../helpers/typography";
import { IdentityVerificationsView } from "../identityVerificationsView/IdentityVerificationsView";
import { UserWallets } from "../userWallet/UserWallets";
import { ProfileDetail } from "./profileDetails";
import css from "./profileManager.css";
const accountUrls = {
    details: "account-details",
    wallets: "account-wallets"
};

function buildTabsforDesktop(parameter: string | undefined)
{
    function handleClick(tabUrl: string)
    {
        window.location.replace(`${window.location.origin}/wallet/#/ui/user-manager/${tabUrl}`);
    }

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <ul className={ `list-group custom-ul` }>
                <li
                    className={ `list-group-item p-4 ${parameter === accountUrls.details ? "li-active" : ""}` }
                    onClick={ () => handleClick(accountUrls.details) }
                    style={ { backgroundColor: "#ffffff", cursor: "pointer" } }
                >
                    Personal Details
                </li>
                <li
                    className={ `list-group-item p-4 ${parameter === accountUrls.wallets ? "li-active" : ""}` }
                    onClick={ () => handleClick(accountUrls.wallets) }
                    style={ { backgroundColor: "#ffffff", cursor: "pointer" } }
                >
                    My Wallets
                </li>
            </ul>
        </div>
    );
}

function buildTabsforMobile(parameter: string | undefined)
{
    function handleClick(tabUrl: string)
    {
        window.location.replace(`${window.location.origin}/wallet/#/ui/user-manager/${tabUrl}`);
    }

    return (
        <div
            className="card d-flex flex-row justify-content-center align-items-center p-3"
            style={ { marginBottom: "20px", marginTop: "-20px", marginLeft: "-0.1875rem" } }
        >
            <div className="col p-2 d-flex " onClick={ () => handleClick(accountUrls.details) }>
                <p
                    className={ `w-100 text-center ${font(FontDefinition.T7)} ${parameter === accountUrls.details ? "li-active" : ""}` }
                    style={ { marginBottom: "0px", cursor: "pointer" } }
                >
                    Personal Details
                </p>
            </div>
            <div className="col p-2 border-start" onClick={ () => handleClick(accountUrls.wallets) }>
                <p
                    className={ `w-100 text-center ${font(FontDefinition.T7)} ${parameter === accountUrls.wallets ? "li-active" : ""}` }
                    style={ { marginBottom: "0px", cursor: "pointer" } }
                >
                    My Wallets
                </p>
            </div>
        </div>
    );
}

export function UserManager()
{
    const parameters = useParams();
    const [ desktopHidden, setHideDesktop ] = useState(false);

    function showHideTabs()
    {
        let width = window.innerWidth;
        if (width < 767.98)
        {
            setHideDesktop(true);
        }
        else
        {
            setHideDesktop(false);
        }
    }

    window.onresize = () =>
    {
        showHideTabs();
    };

    useEffect(() =>
    {
        showHideTabs();
    }, []);

    return (
        <>
            <div className="row" style={ { paddingLeft: "20px", paddingRight: "20px" } }>
                <h2 className={ font(FontDefinition.H2) }>Manage Profile</h2>
            </div>
            <div className="row g-6" style={ { paddingLeft: "20px", paddingRight: "20px" } }>
                <div className="col-12 marginb-30" style={ { paddingLeft: "1.3rem" } }>
                    { <IdentityVerificationsView /> }
                </div>
                <div className="col-12">
                    <div className="row">
                        <div
                            style={ { paddingRight: "0px" } }
                            className={ `col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 ${desktopHidden ? "hidden" : ""}` }
                        >
                            { buildTabsforDesktop(parameters["tab"]) }
                        </div>

                        <div className={ `col-12 mb-3 ${!desktopHidden ? "hidden" : ""}` }>
                            { buildTabsforMobile(parameters["tab"]) }
                        </div>
                        <div
                            className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 marginb-30"
                            style={ { paddingLeft: "0px", marginTop: "-1rem" } }
                        >
                            { parameters["tab"] === "account-details" ? <ProfileDetail /> : <></> }
                            { parameters["tab"] === "account-wallets" ? <UserWallets /> : <></> }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export namespace UserManager
{
    export const route = "/ui/user-manager/:tab";
}
