import { PropertyType } from "@artbanx/nexera/system";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { useWalletConnectModal } from "../../hooks/walletConnectModal";
import { RainbowKitConnectButtonRendererProps } from "./RainbowKitConnectButtonRendererProps";

export type ConnectedWalletButtonProps = {
    openAccountModal: PropertyType<RainbowKitConnectButtonRendererProps, "openAccountModal">;
};

export function ConnectedWalletButton(props: ConnectedWalletButtonProps)
{
    const walletModal = useWalletConnectModal();

    const onClick = () =>
    {
        walletModal
            .setVisible(true)
            .setUserCanHide(true);
    };

    return (
        <button className="btn btn-pill shadow btn-secondary-outline ps-3" onClick={ onClick } type="button">
            <span className="status-indicator status-green status-indicator-animated me-2">
                <span className="status-indicator-circle"></span>
                <span className="status-indicator-circle"></span>
                <span className="status-indicator-circle"></span>
            </span>
            <span className={ classNames(font(FontDefinition.T4)) }>Wallet Connected</span>
        </button>
    );
}
