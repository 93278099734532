import { isString } from "@artbanx/nexera/helpers";
import { Delegate, Equality, EqualityComparer, Nullable, Undefinable } from "@artbanx/nexera/system";
import { ForwardedRef, Fragment, isValidElement, ReactNode, useImperativeHandle, useState } from "react";
import { mapNode, mutateNode, safeForwardRef } from "../../helpers/react";

export enum SortType
{
    None = 0,
    Ascending = 1,
    Descending = 2
}

const DEFAULT_SORT_TYPES: readonly SortType[] = [
    SortType.None,
    SortType.Ascending,
    SortType.Descending
];

export type CollectionItemContentRenderer<TCortege> = Delegate<[cortege: TCortege, id: string], ReactNode>;

export class CollectionController<TCortege>
{
    public constructor(
        private readonly onSortChangedAction: Delegate<[number]>,
        private readonly options: CollectionOptions<TCortege>
    )
    {
    }

    private getIndex(value: number | string)
    {
        if (isString(value))
        {
            const index = this.options.values?.findIndex((e) => e.name === value);

            return index === undefined || index < 0 ? undefined : index;
        }
        else
        {
            return value;
        }
    }

    public updateSort(value: number | string): this
    {
        const index = this.getIndex(value);

        if (index !== undefined)
        {
            this.onSortChangedAction(index);
        }

        return this;
    }
}

type CollectionOptions<TCortege> = {
    values?: readonly BindingProps<TCortege, any>[];
    content: CollectionItemContentRenderer<TCortege>;
    data: Undefinable<Iterable<TCortege>>;
    idSelector?: Delegate<[TCortege], any>;
    multiValueSort?: boolean;
};

function buildItemContent<TCortege>(id: string, cortege: TCortege, markup: ReactNode)
{
    let index = 0;

    const node = mutateNode(
        markup,
        (node) =>
        {
            if (!isValidElement(node))
            {
                return;
            }

            switch (node.type)
            {
                case Binding<TCortege, any>:
                {
                    const props = node.props as BindingProps<TCortege, any>;
                    const value = props.value(cortege, index++);

                    return <>{ props.children?.(value, cortege) ?? value }</>;
                }
            }
        }
    );

    return (
        <Fragment key={ id }>
            { node }
        </Fragment>
    );
}

export interface BindingsBuilder<TCortege>
{
    addBinding<TValue>(
        name: string,
        value: Delegate<[TCortege, number], TValue>,
        allowedSortTypes?: Nullable<readonly SortType[]>,
        equalityComparer?: EqualityComparer<TValue>
    ): this;
}

class BindingsBuilderImlp<TCortege> implements BindingsBuilderImlp<TCortege>
{
    private readonly bindings: BindingProps<TCortege, any>[] = [];

    public addBinding<TValue>(
        name: string,
        value: Delegate<[TCortege, number], TValue>,
        allowedSortTypes?: Nullable<readonly SortType[]>,
        equalityComparer?: EqualityComparer<TValue>
    ): this
    {
        this.bindings.push({ name, value, allowedSortTypes, equalityComparer });

        return this;
    }

    public build()
    {
        return [ ...this.bindings ];
    }
}

function buildDelegatedContent<TCortege>(
    id: string,
    cortege: TCortege,
    delegate: Delegate<[TCortege], ReactNode>
)
{
    return (
        <Fragment key={ id }>
            { delegate(cortege) }
        </Fragment>
    );
}

function buildCollectionOptions<TCortege>(props: CollectionProps<TCortege>): CollectionOptions<TCortege>
{
    if (props.delegated)
    {
        const builder = new BindingsBuilderImlp<TCortege>();

        props.bindings?.(builder);

        return {
            data: props.data,
            idSelector: props.idSelector,
            multiValueSort: props.multiValueSort,
            content: (cortege, id) => buildDelegatedContent(id, cortege, props.children),
            values: builder.build()
        };
    }
    else
    {
        const values = mapNode(
            props.children,
            (element) =>
            {
                if (!isValidElement(element))
                {
                    return;
                }

                switch (element.type)
                {
                    case Binding<TCortege, any>:
                        return element.props as BindingProps<TCortege, any>;
                }
            }
        );

        return {
            ...props,
            content: (cortege, id) => buildItemContent(id, cortege, props.children),
            values
        };
    }
}

export type CollectionProps<TCortege> =
    & {
        multiValueSort?: boolean;
        data: Undefinable<Iterable<TCortege>>;
        idSelector?: Delegate<[TCortege], any>;
    }
    & (
        | {
            delegated: true;
            bindings?: Delegate<[BindingsBuilderImlp<TCortege>]>;
            children: Delegate<[TCortege], ReactNode>;
        }
        | {
            delegated: Undefinable<false>;
            children: ReactNode;
        }
    );

function CollectionComponent<TCortege>(
    props: CollectionProps<TCortege>,
    ref: ForwardedRef<CollectionController<TCortege>>
)
{
    const options = buildCollectionOptions(props);

    const [ valuesSortTypesIndexes, setColumnsSortTypesIndexes ] = useState(options.values?.map(() => 0));

    function getAllowedSortTypes(index: number)
    {
        if (!options.values || index < 0 || index >= options.values.length)
        {
            return;
        }

        const sortTypes = options.values[index].allowedSortTypes;

        return sortTypes === null
            ? undefined
            : sortTypes ?? DEFAULT_SORT_TYPES;
    }

    function onSortChanged(index: number)
    {
        setColumnsSortTypesIndexes(
            (columnsSortTypesIndexes) =>
            {
                if (!columnsSortTypesIndexes)
                {
                    return undefined;
                }

                const allowedSortTypes = getAllowedSortTypes(index);

                if (!allowedSortTypes || allowedSortTypes.length <= 0)
                {
                    return;
                }

                let sortTypeIndex = columnsSortTypesIndexes[index];

                if (++sortTypeIndex >= allowedSortTypes.length)
                {
                    sortTypeIndex = 0;
                }

                const updatedIndexes = options.multiValueSort
                    ? [ ...columnsSortTypesIndexes ]
                    : (
                        columnsSortTypesIndexes.map(
                            (value, offset) =>
                            {
                                const result = options.values?.[offset].allowedSortTypes
                                    ?.indexOf(SortType.None);

                                return result === undefined || result === -1
                                    ? 0
                                    : result;
                            }
                        )
                    );

                updatedIndexes[index] = sortTypeIndex;

                return updatedIndexes;
            }
        );
    }

    function compareCortages(
        left: TCortege,
        right: TCortege
    ): Equality
    {
        if (!options.values || !valuesSortTypesIndexes)
        {
            return Equality.Equal;
        }

        for (let index = 0; index < options.values.length; ++index)
        {
            const allowedSortTypes = getAllowedSortTypes(index);

            if (!allowedSortTypes || allowedSortTypes.length <= 0)
            {
                continue;
            }

            const sortTypeIndex = valuesSortTypesIndexes[index];

            const sortType = allowedSortTypes[sortTypeIndex];
            const value = options.values[index].value;

            const comparer = options.values[index].equalityComparer ?? EqualityComparer.Default();

            const compareValues: EqualityComparer<any> = (left, right) =>
            {
                switch (sortType)
                {
                    case SortType.Ascending:
                        return comparer(left, right);
                    case SortType.Descending:
                        return comparer(right, left);
                    default:
                        return Equality.Equal;
                }
            };

            const leftValue = value(left, index);
            const rightValue = value(right, index);

            const result = compareValues(leftValue, rightValue);

            if (result !== Equality.Equal)
            {
                return result;
            }
        }

        return Equality.Equal;
    }

    const corteges = options.data
        ? Array
            .from(options.data)
            .sort(compareCortages)
        : undefined;

    function* generateItems()
    {
        if (!corteges)
        {
            return <></>;
        }

        for (let index = 0; index < corteges.length; ++index)
        {
            const cortege = corteges[index];

            yield options.content(
                cortege,
                options.idSelector
                    ? options.idSelector(cortege)
                    : index
            );
        }
    }

    const controller = new CollectionController<TCortege>(
        onSortChanged,
        options
    );

    useImperativeHandle(ref, () => controller);

    return (
        <>
            { [ ...generateItems() ] }
        </>
    );
}

export const Collection = safeForwardRef(CollectionComponent);

export type BindingProps<TCortege, TValue> = {
    name: string;
    value: Delegate<[TCortege, number], TValue>;
    children?: Delegate<[TValue, TCortege], ReactNode>;
    allowedSortTypes?: Nullable<readonly SortType[]>;
    equalityComparer?: EqualityComparer<TValue>;
};

export function Binding<TCortege, TValue>(props: BindingProps<TCortege, TValue>): ReactNode
{
    throw "Value never should be rendered outside of the Collection!";
}
