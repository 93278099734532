import { Nullable } from "@artbanx/nexera/system";
import { createContext, RefObject, useContext } from "react";
import { KycProviderController } from "../components/kycProvider/KycProvider";

export const KycContext = createContext<Nullable<RefObject<KycProviderController>>>(null);

export function useKyc()
{
    const controller = useContext(KycContext);

    return controller?.current!;
}
