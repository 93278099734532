import { StatusCodes } from "http-status-codes";
import { KycUserStatusResultDto } from "../../components/kycProvider/dto/KycUserStatusResultDto";
import { getConfiguration } from "../../configuration/configuration";
import { KycErrorDto } from "./dto/kycErrorDto";
import { KycStartFlowPayload } from "./dto/kycStartFlowPayload";
import { KycUrlDto } from "./dto/kycUrlDto";

const BASE_URL = getConfiguration().assetApiBaseUrl;

export function isError(obj: any): obj is KycErrorDto
{
    return obj && obj.error !== undefined;
}

export async function getKycUrl(jsonData: KycStartFlowPayload): Promise<KycUrlDto>
{
    const response = await fetch(`${BASE_URL}/api/kyc/url`, {
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify(jsonData),
        method: "POST"
    });

    return await response.json();
}

export async function getKycUserStatus(): Promise<KycUserStatusResultDto>
{
    const url = `${BASE_URL}/api/kyc/status`;

    const response = await fetch(
        url,
        {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        }
    );

    if (response.status !== StatusCodes.OK)
    {
        throw new Error("Couldn't fetch KYC status!");
    }

    return await response.json();
}
