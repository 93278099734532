import cards from "../components/styles/Cards.module.css";

import * as AssetsSalesService from "../services/assetsSales/assetsSalesService";

import { formatSaleCurrencyScale } from "@artbanx/nexera/helpers";
import { BuybackStages, BuybackTransferInfo, BuybackTrsnaferValidationResult, Onchain, Seller } from "@artbanx/nexera/onchain";
import { Undefinable } from "@artbanx/nexera/system";
import { ChangeEvent, useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Loader } from "../components/loader/Loader";
import { ProgressState } from "../components/progressModal/ProgressModal";
import { SaleStatusView } from "../components/saleStatusView/SaleStatusView";
import { useOnchain } from "../hooks/onchain";
import { useProgressModal } from "../hooks/progressModal";
import { AssetSaleDetails } from "../services/assetsSales/models/assetSaleDetails";
import { InvestmentDetails } from "./InvestmentDetails";

type WarningMessageProps = {
    transfer: BuybackTransferInfo;
    transferValidationResult: BuybackTrsnaferValidationResult;
    assetSale: AssetSaleDetails;
};

function WarningMessage(props: WarningMessageProps)
{
    if (!props.transferValidationResult.valid)
    {
        return (
            <span className="text-warning">
                { `Not enough funds, please transfer at least`
                    + `${
                        formatSaleCurrencyScale(props.assetSale.sale, props.transferValidationResult.fundsMissing)
                    } ${props.assetSale.sale.fundingCurrency.symbol} `
                    + `to complete your payment` }
            </span>
        );
    }

    return <></>;
}

type BuyBackBodyProps = {
    assetSale: AssetSaleDetails;
    seller: Seller;
    onchain: Onchain;
};

function BuyBackBody(props: BuyBackBodyProps)
{
    const navigate = useNavigate();

    const [ acceptTermsAndPolicy, setAcceptTermsAndPolicy ] = useState<boolean>(false);
    const progressModal = useProgressModal();
    const transfer = props.onchain.fetchBuyBackTransferInfo(props.assetSale.sale);
    const transferValidationResult = props.onchain.fetchBuyBackTransferValidationResult(props.seller, transfer);

    async function buyBack()
    {
        if (!progressModal?.current)
        {
            return;
        }

        const modal = progressModal.current;

        modal
            .setVisible(true)
            .setUserCanHide(false)
            .setProgressState(ProgressState.LOADING)
            .setMessage(undefined)
            .setChildren(undefined)
            .execute();

        await props.onchain.buyBack(props.assetSale.sale.id)
            .on(
                BuybackStages.GetSaleMonetaryInfo,
                () =>
                {
                    modal
                        .setMessage("Receiving sale monetary info...")
                        .execute();
                }
            )
            .on(
                BuybackStages.GetSaleInfo,
                () =>
                {
                    modal
                        .setMessage("Receiving sale info...")
                        .execute();
                }
            )
            .on(
                BuybackStages.ApproveERC20AmountForABX,
                () =>
                {
                    modal
                        .setMessage("Waiting for ERC20 approval from signer...")
                        .execute();
                }
            )
            .on(
                BuybackStages.Buyback,
                () =>
                {
                    modal
                        .setMessage("Waiting for buyback approval from signer...")
                        .execute();
                }
            )
            .onSuccess(
                () =>
                {
                    modal
                        .setProgressState(ProgressState.SUCCESS)
                        .setMessage("Payment has been successfully completed")
                        .setUserCanHide(true)
                        .setOnHide(() => navigate(generatePath(InvestmentDetails.route, { assetId: props.assetSale.asset.assetId })))
                        .execute();
                }
            )
            .onFail(
                (exc) =>
                {
                    modal
                        .setProgressState(ProgressState.FAIL)
                        .setMessage(exc.reason ?? exc.message ?? "Something went wrong")
                        .setUserCanHide(true)
                        .execute();
                }
            )
            .execute();
    }

    function cancel()
    {
        navigate(generatePath(InvestmentDetails.route, { assetId: props.assetSale.asset.assetId }));
    }

    function handleAcceptTermsAndPolicy(event: ChangeEvent<HTMLInputElement>)
    {
        setAcceptTermsAndPolicy(event.target.checked);
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-6 text-left">
                    <div className="row">
                        <div className="col-3">
                            <h1>Buy-Back</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <SaleStatusView
                                sale={ props.assetSale.sale }
                                showStatus
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={ `card card-md shadow-none ${cards["info-card"]}` }>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 text-left">
                            <h4 className="text-primary">Total Balance</h4>
                        </div>
                        <div className="col-12 text-left">
                            <h4>
                                { formatSaleCurrencyScale(props.assetSale.sale, props.seller.saleCurrencyBalance) }{" "}
                                { props.assetSale.sale.fundingCurrency.symbol }
                            </h4>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <br />
            <h3>Send</h3>
            <br />

            <div className="row">
                <div className="col-5">
                    <div className="row">
                        <div className="col-6 border border-1">
                            <h3 className="my-3">{ props.assetSale.sale.fundingCurrency.symbol }</h3>
                        </div>

                        <div className="col-6 border border-1">
                            <h3
                                className={ `my-3${!transferValidationResult.valid ? " text-warning" : " text-secondary"}` }
                            >
                                { formatSaleCurrencyScale(props.assetSale.sale, transfer.total) }
                            </h3>
                        </div>
                    </div>
                </div>

                <div className="col-7 d-flex align-items-center justify-content-center">
                    <WarningMessage { ...{ assetSale: props.assetSale, transfer, transferValidationResult } } />
                </div>
            </div>

            <br />
            <br />
            <br />
            <br />

            <div className="row">
                <div className="col-4">
                    <div className="form-check d-flex">
                        <input
                            type="checkbox"
                            className="form-check-input align-self-start me-3"
                            checked={ acceptTermsAndPolicy }
                            onChange={ handleAcceptTermsAndPolicy }
                        />
                        <div className="text-primary align-self-center h-100">Accept Terms and Conditions</div>
                    </div>
                </div>

                <div className="col-4">
                    <input
                        type="button"
                        className="btn btn-outline-primary rounded-pill w-100"
                        value="Cancel"
                        onClick={ cancel }
                    />
                </div>

                <div className="col-4">
                    <input
                        type="button"
                        className="btn btn-primary rounded-pill w-100"
                        value="Pay"
                        onClick={ buyBack }
                        disabled={ !acceptTermsAndPolicy || !transferValidationResult.valid }
                    />
                </div>
            </div>
        </div>
    );
}

export function BuyBack()
{
    const onchain = useOnchain();

    const { assetId } = useParams();

    const [ assetSale, setAssetSale ] = useState<Undefinable<AssetSaleDetails>>();
    const [ seller, setSeller ] = useState<Undefinable<Seller>>();

    useEffect(fetchAssetSale.asVoid(), [ onchain ]);
    useEffect(fetchSeller.asVoid(), [ assetSale ]);

    async function fetchAssetSale()
    {
        if (!onchain || !assetId)
        {
            return;
        }

        const assetSale = await AssetsSalesService.getDetails(onchain, { id: assetId });

        setAssetSale(assetSale);
    }

    async function fetchSeller()
    {
        if (!onchain || !assetSale)
        {
            return;
        }

        const seller = await onchain.fetchSeller(assetSale.sale);

        setSeller(seller);
    }

    return (
        <Loader dependencies={ { onchain, assetSale, seller } }>
            { (dependencies) => <BuyBackBody { ...dependencies } /> }
        </Loader>
    );
}

export namespace BuyBack
{
    export const route = "/ui/buy-back/:assetId";
}
