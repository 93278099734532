import { CSSProperties } from "react";
import { RainbowKitConnectButtonRendererProps } from "./RainbowKitConnectButtonRendererProps";
import { WalletStatus } from "./WalletStatus";

export type WalletConnectorBodyProps = RainbowKitConnectButtonRendererProps;

export function WalletConnectorBody(props: WalletConnectorBodyProps)
{
    const ready = props.mounted && props.authenticationStatus !== "loading";
    const connected = (ready && props.account && props.chain)
        && (!props.authenticationStatus || props.authenticationStatus === "authenticated");

    const style: CSSProperties = {
        opacity: !ready ? 0 : undefined,
        pointerEvents: !ready ? "none" : undefined,
        userSelect: !ready ? "none" : undefined
    };

    return (
        <div
            aria-hidden={ !ready }
            style={ style }
            className="d-flex align-items-center justify-content-center"
        >
            <WalletStatus
                { ...{
                    openAccountModal: props.openAccountModal,
                    openChainModal: props.openChainModal,
                    openConnectModal: props.openConnectModal,
                    account: props.account,
                    chain: props.chain,
                    connected
                } }
            />
        </div>
    );
}
