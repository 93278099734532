import * as Enumerable from "linq-es5";
import { FormEvent, useState } from "react";
import { Avatar } from "../components/avatar/Avatar";
import { NotificationQueue, NotificationType } from "../components/notificationQueue/NotificationQueue";
import { classNames } from "../helpers/react";
import { font, FontDefinition } from "../helpers/typography";
import { useNotifications } from "../hooks/notifications";
import * as UserService from "../services/user/userService";

export function Login()
{
    const notificationsController = useNotifications();
    const [ loading, setLoading ] = useState(false);

    const identity = UserService.getCurrentIdentity();

    async function onLogin(event: FormEvent<HTMLFormElement>)
    {
        setLoading(true);

        event.preventDefault();
        event.stopPropagation();

        const elements = Enumerable.From(event.currentTarget.elements);

        const email = elements.Cast<HTMLInputElement>()
            .First((e) => e.name === "email")
            .value;

        const password = elements.Cast<HTMLInputElement>()
            .First((e) => e.name === "password")
            .value;

        try
        {
            const auth = await UserService.login(email, password);

            await UserService.useAuthorization(auth.data);

            notificationsController.current?.push(
                <NotificationQueue.Notification type={ NotificationType.Success }>
                    <NotificationQueue.Notification.Heading>
                        <span className={ classNames("text-dark", font(FontDefinition.T4)) }>
                            Successfully logged in!
                        </span>
                    </NotificationQueue.Notification.Heading>
                </NotificationQueue.Notification>
            );
        }
        catch (exc: unknown)
        {
            if (exc instanceof Error)
            {
                notificationsController.current?.push(
                    <NotificationQueue.Notification type={ NotificationType.Error }>
                        <NotificationQueue.Notification.Heading>
                            <span className={ classNames("text-dark", font(FontDefinition.T4)) }>
                                Could not login
                            </span>
                        </NotificationQueue.Notification.Heading>
                        <NotificationQueue.Notification.Body>
                            <p className={ classNames("text-dark", font(FontDefinition.T5)) }>
                                { exc.message }
                            </p>
                        </NotificationQueue.Notification.Body>
                    </NotificationQueue.Notification>
                );
            }
            else
            {
                notificationsController.current?.push(
                    <NotificationQueue.Notification type={ NotificationType.Error }>
                        <NotificationQueue.Notification.Heading>
                            <span className={ classNames("text-dark", font(FontDefinition.T4)) }>
                                Internal error happened during login
                            </span>
                        </NotificationQueue.Notification.Heading>
                    </NotificationQueue.Notification>
                );
            }
        }
        finally
        {
            setLoading(false);
        }
    }

    async function onLogout()
    {
        setLoading(true);

        try
        {
            await UserService.logout();

            notificationsController.current?.push(
                <NotificationQueue.Notification type={ NotificationType.Success }>
                    <NotificationQueue.Notification.Heading>
                        <span className={ classNames("text-dark", font(FontDefinition.T4)) }>
                            Successfully logged out!
                        </span>
                    </NotificationQueue.Notification.Heading>
                </NotificationQueue.Notification>
            );
        }
        catch (exc: unknown)
        {
            if (exc instanceof Error)
            {
                notificationsController.current?.push(
                    <NotificationQueue.Notification type={ NotificationType.Error }>
                        <NotificationQueue.Notification.Heading>
                            <span className={ classNames("text-dark", font(FontDefinition.T4)) }>
                                Could not logout
                            </span>
                        </NotificationQueue.Notification.Heading>
                        <NotificationQueue.Notification.Body>
                            <p className={ classNames("text-dark", font(FontDefinition.T5)) }>
                                { exc.message }
                            </p>
                        </NotificationQueue.Notification.Body>
                    </NotificationQueue.Notification>
                );
            }
            else
            {
                notificationsController.current?.push(
                    <NotificationQueue.Notification type={ NotificationType.Error }>
                        <NotificationQueue.Notification.Heading>
                            <span className={ classNames("text-dark", font(FontDefinition.T4)) }>
                                Internal error happened during logout
                            </span>
                        </NotificationQueue.Notification.Heading>
                    </NotificationQueue.Notification>
                );
            }
        }
        finally
        {
            setLoading(false);
        }
    }

    if (UserService.isAnonymous(identity))
    {
        return (
            <form onSubmit={ onLogin } className="d-flex justify-content-center">
                <div className="row g-4">
                    <div className="col-12">
                        <label className="form-label" htmlFor="login">Login</label>
                        <input type="text" placeholder="name@example.com" id="login" name="email" className="form-control" disabled={ loading } />
                    </div>
                    <div className="col-12">
                        <label className="form-label" htmlFor="password">Password</label>
                        <input type="password" id="password" name="password" className="form-control" disabled={ loading } />
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary btn-pill w-100" disabled={ loading }>
                            { !loading
                                ? "Login"
                                : (
                                    <>
                                        <span className="m-2 spinner-border spinner-border-sm" role="status" aria-hidden="true">
                                        </span>
                                        Loading...
                                    </>
                                ) }
                        </button>
                    </div>
                </div>
            </form>
        );
    }

    return (
        <div className="row g-4">
            <div className="col-6 d-flex justify-content-center align-items-center">
                <Avatar size="10rem" src={ identity.avatar ?? undefined } />
            </div>
            <div className="col-6">
                <label className="form-label" htmlFor="login">Login</label>
                <input type="text" id="login" value={ identity.login ?? undefined } className="form-control" readOnly />

                <label className="form-label" htmlFor="email">Email</label>
                <input type="text" id="email" value={ identity.email ?? undefined } className="form-control" readOnly />

                <label className="form-label" htmlFor="id">Id</label>
                <input type="text" id="id" value={ identity.id } className="form-control" readOnly />
            </div>
            <div className="col-6">
            </div>
            <div className="col-6">
                <button className="btn btn-primary btn-pill" onClick={ onLogout } disabled={ loading }>
                    { !loading
                        ? "Logout"
                        : (
                            <>
                                <span className="m-2 spinner-border spinner-border-sm" role="status" aria-hidden="true">
                                </span>
                                Loading...
                            </>
                        ) }
                </button>
            </div>
        </div>
    );
}

export namespace Login
{
    export const route = "/ui/login";
}
