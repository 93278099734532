import { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { useAccount } from "wagmi";
import { IdentityVerificationsProvider } from "../components/identityVerificationsProvider/IdentityVerificationsProvider";
import { OnchainProvider } from "../components/onchainProvider/OnchainProvider";
import { StickyWalletButton } from "../components/StickyWalletButton";
import { SubgraphProvider } from "../components/subgraphProvider/SubgraphProvider";
import { WalletConnectModalProvider } from "../components/walletConnectModalProvider/WalletConnectModalProvider";
import { WalletProvider } from "../components/walletProvider/WalletProvider";

export type WalletLayoutProps = {
    children?: ReactNode;
};

export function WalletLayout(props: WalletLayoutProps)
{
    const account = useAccount();

    return (
        <WalletProvider>
            <OnchainProvider>
                <SubgraphProvider>
                    <WalletConnectModalProvider>
                        <IdentityVerificationsProvider>
                            <div className="row">
                                <div className="col">
                                    { props.children ?? <Outlet /> }
                                    <StickyWalletButton account={ account } />
                                </div>
                            </div>
                        </IdentityVerificationsProvider>
                    </WalletConnectModalProvider>
                </SubgraphProvider>
            </OnchainProvider>
        </WalletProvider>
    );
}
