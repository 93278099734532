import { Sale } from "@artbanx/nexera/onchain";
import { Delegate } from "@artbanx/nexera/system";
import { ReactNode } from "react";
import { classNames } from "../../helpers/react";
import { RadialBar } from "../radialBar/RadialBar";

export type CaptionProps = {
    show?: boolean;
    className?: string;
};

export type SaleFundingStatusProps = {
    radius: string;
    strokeWidth: string;
    sale: Sale;
    className?: string;
    children?: Delegate<[progress: number], ReactNode>;
};

export function SaleFundingStatus(props: SaleFundingStatusProps)
{
    // const progress = props.sale.monetaryInfo.fractionsPurchased
    //     ?.mul(100)
    //     ?.div(props.sale.monetaryInfo.maxAmountOfFractions)
    //     ?.toNumber()
    //     ?? 0;

    const progress = Number(
        ((props.sale.monetaryInfo.fractionsPurchased.toNumber() * 100) / props.sale.monetaryInfo.maxAmountOfFractions.toNumber()).toFixed(2)
    );

    return (
        <RadialBar
            { ...{
                progress,
                fillColor: "var(--bs-primary)",
                backgroundColor: "var(--bs-secondary-lt)",
                radius: props.radius,
                strokeWidth: props.strokeWidth,
                className: props.className,
                containerClassName: classNames(
                    "d-flex",
                    "align-items-center",
                    "justify-content-center"
                )
            } }
        >
            { props.children?.(progress) }
        </RadialBar>
    );
}
