import { StatusCodes } from "http-status-codes";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useAccount } from "wagmi";
import { useDisconnect } from "wagmi";
import { font, FontDefinition } from "../../helpers/typography";
import { useNotifications } from "../../hooks/notifications";
import { getCurrentIdentity } from "../../services/user/userService";
import { UserWalletDto } from "../../services/userWallets/dto/userWalletDto";
import { Wallet } from "../../services/userWallets/models/wallet";
import * as userWalletService from "../../services/userWallets/userWalletsService";
import css from "./UserWallet.module.css";
import { NotifyUser } from "./UserWalletNotifications";
export interface IUserWalletConfirmationProps
{
    wallet: Wallet;
    show: boolean;
    setModalVisible: (show: boolean) => void;
    updateList: (shouldUpdate: boolean) => void;
}
export function UserWalletConfirmation(props: IUserWalletConfirmationProps)
{
    const notifications = useNotifications();
    const account = useAccount();
    const disconnect = useDisconnect();

    async function deleteWallet()
    {
        let userWalletDto = { userId: getCurrentIdentity().id.toString(), wallet: props.wallet } as UserWalletDto;
        let statusCode = await userWalletService.deleteUserWallet(userWalletDto);
        if (statusCode === StatusCodes.OK)
        {
            props.updateList(true);
            props.setModalVisible(false);
            if (props.wallet.walletAddress === account.address)
            {
                disconnect.disconnect();
            }
        }
        NotifyUser(notifications, statusCode, "Wallet successfully deleted!", "Something went wrong while deleting!");
    }

    return (
        <Modal show={ props.show } onHide={ () => props.setModalVisible(false) }>
            <Modal.Body>
                <div className="row">
                    <h4 className={ `${font(FontDefinition.T1)}` }>Delete Wallet Confirmation</h4>

                    <p className={ `${font(FontDefinition.T3)}` }>
                        Confirm deletion of { props.wallet.title }{" "}
                        from your wallet list? This action will only remove the wallet from your list and won't affect your actual digital wallet or its
                        contents.
                    </p>
                </div>
                <div className="d-flex flex-row-reverse mt-4">
                    <button className={ `btn btn-primary text-white  ${css.btn} ` } onClick={ () => deleteWallet() }>
                        Confirm
                    </button>
                    <button className={ ` btn border-primary text-primary me-2 ${css.btn}` } onClick={ () => props.setModalVisible(false) }>
                        Cancel
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
