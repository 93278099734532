import { StatusCodes } from "http-status-codes";
import { getConfiguration } from "../../configuration/configuration";
import { HttpResponse } from "../shared/httpResponse";
import { UserWalletDto, UserWalletsDto } from "./dto/userWalletDto";

const USER_WALLET_URL = `${getConfiguration().assetApiBaseUrl}/api/user/wallet`;

export async function getUserWallets(userId: number): Promise<UserWalletsDto>
{
    if (!userId)
    {
        throw new Error("User is anonymous");
    }

    const response = await fetch(
        USER_WALLET_URL,
        {
            headers: {
                "content-type": "application/json"
            },
            method: "GET"
        }
    );

    if (response.status != StatusCodes.OK)
    {
        throw new Error("Something went wrong!", { cause: response.status });
    }

    return await response.json();
}

export async function addUpdateUserWallets(userWallet: UserWalletsDto)
{
    if (!userWallet)
    {
        return;
    }

    const response = await fetch(
        USER_WALLET_URL,
        {
            headers: {
                "content-type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(userWallet)
        }
    );

    if (response.status !== StatusCodes.OK)
    {
        throw new Error("Something went wrong while Saving", { cause: response.status });
    }
}

export async function isWalletExists(userId: number, walletAddress?: string)
{
    if (!userId || !walletAddress)
    {
        return false;
    }
    const request = await fetch(
        `${USER_WALLET_URL}/exists/${walletAddress}`,
        {
            headers: {
                "content-type": "application/json"
            },
            method: "GET"
        }
    );

    return request.json();
}

export async function addUserWallet(userWallet: UserWalletDto)
{
    if (!userWallet.userId || !userWallet.wallet)
    {
        return 400;
    }

    const request = await fetch(
        USER_WALLET_URL,
        {
            headers: {
                "content-type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(userWallet)
        }
    );

    return request.status;
}

export async function deleteUserWallet(userWallet: UserWalletDto)
{
    if (!userWallet.userId || !userWallet.wallet)
    {
        return StatusCodes.BAD_REQUEST;
    }
    const request = await fetch(
        USER_WALLET_URL,
        {
            headers: {
                "content-type": "application/json"
            },
            method: "DELETE",
            body: JSON.stringify(userWallet)
        }
    );

    return request.status;
}

export async function isWalletForOtherUser(userId: string, walletAddress?: string)
{
    if (!userId || !walletAddress)
    {
        return { status: StatusCodes.BAD_REQUEST, data: null } as HttpResponse;
    }
    const request = await fetch(
        `${USER_WALLET_URL}/other-user/${walletAddress}`,
        {
            headers: {
                "content-type": "application/json"
            },
            method: "GET"
        }
    );
    return {
        data: await request.json(),
        status: request.status
    } as HttpResponse;
}
