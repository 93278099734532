import { Undefinable } from "@artbanx/nexera/system";
import { createContext, ReactNode, RefObject, useRef } from "react";
import { WalletConnectedModal, WalletConnectedModalController } from "../WalletConnectedModal";

export type WalletConnectModalProviderProps = {
    children: ReactNode;
};

export const WallectConnectModalContext = createContext<Undefinable<RefObject<WalletConnectedModalController>>>(
    undefined
);

export function WalletConnectModalProvider(props: WalletConnectModalProviderProps)
{
    const modalRef = useRef<WalletConnectedModalController>(null);

    return (
        <WallectConnectModalContext.Provider value={ modalRef }>
            { props.children }
            <WalletConnectedModal ref={ modalRef } />
        </WallectConnectModalContext.Provider>
    );
}
