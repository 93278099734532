import { useNavigate } from "react-router-dom";
import { classNames } from "../../helpers/react";

export type BackButtonProps = {
    className?: string;
};

export function BackButton(props: BackButtonProps)
{
    const navigate = useNavigate();

    function onClick()
    {
        navigate(-1);
    }

    return (
        <div className="d-flex justify-content-start align-items-center w-auto" role="button" onClick={ onClick }>
            <i className={ classNames("bi bi-chevron-left me-2", props.className) }></i>
            <span className={ classNames(props.className) }>Back</span>
        </div>
    );
}
