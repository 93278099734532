import { SaleStatus } from "@artbanx/nexera/onchain";
import { Topics } from "@artbanx/nexera/subgraph";
import { Nullable, Undefinable } from "@artbanx/nexera/system";
import { BigNumber } from "ethers";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { AssetDetailsFilter } from "../services/assets/assetsService";
import { AssetSaleDetails } from "../services/assetsSales/models/assetSaleDetails";
import { useOnchain } from "./onchain";
import { useAssetSaleDetailsQuery } from "./queries";
import { useSubgraphTopicsConfigurator } from "./subgraph";

export function useAssetDetailsTracking(
    filter: Nullable<AssetDetailsFilter>
)
{
    const onchain = useOnchain();
    const queryClient = useQueryClient();
    const assetDetailsQuery = useAssetSaleDetailsQuery(filter);
    const topicsConfigurator = useSubgraphTopicsConfigurator();

    function initialize()
    {
        if (!onchain || !topicsConfigurator || filter === null || !assetDetailsQuery.query.isFetchedAfterMount)
        {
            return;
        }

        const topics = topicsConfigurator
            .addTopic(Topics.AssetOraclePriceSet)
            .addTopic(Topics.Purchase)
            .addTopic(Topics.SaleStatusUpdate)
            .build();

        const subscriptions = [
            topics.assetOraclePriceSet.subscribe(
                (event) =>
                {
                    queryClient.setQueryData<Undefinable<AssetSaleDetails>>(
                        assetDetailsQuery.key,
                        (assetSale) =>
                        {
                            if (assetSale && assetSale.sale.id.eq(event.nftId))
                            {
                                assetSale.sale.oracleInfo.price = BigNumber.from(event.newPrice);

                                return { ...assetSale };
                            }

                            return assetSale;
                        }
                    );
                }
            ),
            topics.purchase.subscribe(
                (event) =>
                {
                    queryClient.setQueryData<Undefinable<AssetSaleDetails>>(
                        assetDetailsQuery.key,
                        (assetSale) =>
                        {
                            if (assetSale && assetSale.sale.id.eq(event.saleId))
                            {
                                assetSale.sale.monetaryInfo.fractionsPurchased = assetSale.sale.monetaryInfo
                                    .fractionsPurchased
                                    .add(event.fractionsPurchased);

                                return { ...assetSale };
                            }

                            return assetSale;
                        }
                    );
                }
            ),
            topics.saleStatusUpdate.subscribe(
                async (event) =>
                {
                    const assetSale = queryClient.getQueryData<Undefinable<AssetSaleDetails>>(assetDetailsQuery.key);
                    const status = Number(event.status);

                    if (assetSale && assetSale.sale.id.eq(event.saleId))
                    {
                        if (status === SaleStatus.APPROVED_FOR_FUNDING)
                        {
                            const sale = await onchain.fetchSale(assetSale.sale.id);

                            queryClient.setQueryData<Undefinable<AssetSaleDetails>>(
                                assetDetailsQuery.key,
                                { ...assetSale, sale }
                            );
                        }
                        else
                        {
                            assetSale.sale.saleStatus = status;

                            queryClient.setQueryData<Undefinable<AssetSaleDetails>>(
                                assetDetailsQuery.key,
                                { ...assetSale }
                            );
                        }
                    }
                }
            )
        ];

        return () =>
        {
            for (const subscription of subscriptions)
            {
                subscription.dispose();
            }
        };
    }

    useEffect(initialize, [ !!onchain, !!topicsConfigurator, filter, assetDetailsQuery.query.isFetchedAfterMount ]);

    return assetDetailsQuery.query.data;
}
