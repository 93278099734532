import { Dispatch, ForwardedRef, forwardRef, RefObject, SetStateAction, useImperativeHandle, useState } from "react";
import { Breakpoint } from "../../helpers/bootstrap";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { useNotifications } from "../../hooks/notifications";
import { getKycUserStatus } from "../../services/kyc/service";
import { NotificationQueue, NotificationQueueController, NotificationType } from "../notificationQueue/NotificationQueue";
import FailedReasonEnum from "./dto/FailedReasonEnum";
import { KycStatus, KycUserStatusResultDto } from "./dto/KycUserStatusResultDto";

export type KycProviderProps = {};

export interface KycProviderController
{
    startKycCheck(): void;
}

class KycProviderControllerImpl implements KycProviderController
{
    constructor(
        private readonly setTimer: Dispatch<SetStateAction<NodeJS.Timeout | undefined>>,
        private readonly notifications: RefObject<NotificationQueueController>
    )
    {
    }

    startKycCheck()
    {
        this.setTimer(() =>
        {
            return setInterval(() =>
            {
                this.kycCheck();
            }, 10000);
        });
    }

    private async kycCheck()
    {
        const kycUserStatus = await getKycUserStatus();

        if (kycUserStatus.status != KycStatus.CHECK_PENDING)
        {
            this.setTimer((timer) =>
            {
                if (timer)
                {
                    clearInterval(timer);
                }
                return undefined;
            });
        }

        if (kycUserStatus.status === KycStatus.ALL_CHECKS_CLEAR)
        {
            this.notifications.current?.push(
                <NotificationQueue.Notification type={ NotificationType.Success } delay={ 5000 }>
                    <NotificationQueue.Notification.Heading>
                        KYC process finished successfully!
                    </NotificationQueue.Notification.Heading>
                </NotificationQueue.Notification>
            );
        }
        else if (kycUserStatus.status === KycStatus.NO_CHECKS_FOUND || kycUserStatus.status === KycStatus.CHECK_FAILED)
        {
            const userStatusFeedback = generateKycUserFeedback(kycUserStatus);

            this.notifications.current?.push(
                <NotificationQueue.Notification type={ NotificationType.Error } delay={ 5000 }>
                    <NotificationQueue.Notification.Heading>
                        <span
                            className={ classNames(
                                "text-dark",
                                font(FontDefinition.T6),
                                font(FontDefinition.T4, Breakpoint.MD)
                            ) }
                        >
                            Failed: KYC Process was not successful.
                        </span>
                    </NotificationQueue.Notification.Heading>
                    <NotificationQueue.Notification.Body>
                        <p className={ classNames("text-dark", font(FontDefinition.T5)) }>
                            { userStatusFeedback.length < 2
                                ? (
                                    <ul>
                                        <li>
                                            Ensure the profile information matches the provided document precisely.
                                        </li>
                                        <li>
                                            Ensure the document image fits the frame perfectly and that the camera is directly above it without any angle
                                            difference.
                                        </li>
                                    </ul>
                                )
                                : (
                                    <p>
                                        { userStatusFeedback }
                                    </p>
                                ) }
                        </p>
                    </NotificationQueue.Notification.Body>
                </NotificationQueue.Notification>
            );
        }
    }
}

function KycProviderComponent(props: KycProviderProps, ref: ForwardedRef<KycProviderController>)
{
    const notifications = useNotifications();
    const [ timer, setTimer ] = useState<NodeJS.Timeout>();

    useImperativeHandle(ref, () =>
    {
        return new KycProviderControllerImpl(setTimer, notifications);
    });

    return (
        <>
        </>
    );
}

function generateKycUserFeedback(kycUserStatus: KycUserStatusResultDto)
{
    const containsNameMismatch = kycUserStatus.userFeedback.some((innerArr) => innerArr.userFeedback.includes(FailedReasonEnum.NAME_MISMATCH));
    const containsAgeBelowRange = kycUserStatus.userFeedback.some((innerArr) => innerArr.userFeedback.includes(FailedReasonEnum.AGE_BELOW_RANGE));

    let kycUserFeedback = "";

    if (containsNameMismatch && containsAgeBelowRange)
    {
        kycUserFeedback = "Extracted age is below the defined range. The extracted firstname and lastname from the document do not match the client record.";
    }
    else if (containsNameMismatch)
    {
        kycUserFeedback = "The extracted firstname and lastname from the document do not match the client record.";
    }
    else if (containsAgeBelowRange)
    {
        kycUserFeedback = "Extracted age is below the defined range.";
    }

    return kycUserFeedback;
}

export const KycProvider = forwardRef(KycProviderComponent);
