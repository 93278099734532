import { formatNumber, formatSaleCurrencyScale } from "@artbanx/nexera/helpers";
import { Undefinable } from "@artbanx/nexera/system";
import moment from "moment";
import { ForwardedRef, forwardRef } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { InvestmentDetails } from "../../pages/InvestmentDetails";
import { AssetSale } from "../../services/assetsSales/models/assetSale";
import { BindingsBuilder, Collection, CollectionController, SortType } from "../collection/Collection";
import { Condition } from "../condition/Condition";
import { InvestmentImage } from "../investmentImage/InvestmentImage";
import { SaleStatusBadge } from "../saleStatusBadge/SaleStatusBadge";
import { getActivePeriodEndDate, SaleStatusView } from "../saleStatusView/SaleStatusView";

export type AssetsListProps = {
    assetsSales: Undefinable<AssetSale[]>;
};

function AssetsListComponent(props: AssetsListProps, ref: ForwardedRef<CollectionController<AssetSale>>)
{
    const navigate = useNavigate();

    function createBindings(builder: BindingsBuilder<AssetSale>)
    {
        builder
            .addBinding(
                "Active Period Status",
                (assetSale) =>
                {
                    const period = getActivePeriodEndDate(assetSale?.sale);

                    if (period)
                    {
                        if (!moment().isAfter(period))
                        {
                            return 0;
                        }
                        else
                        {
                            return 2;
                        }
                    }
                    else
                    {
                        return 1;
                    }
                },
                [ SortType.Ascending ]
            );
    }

    return (
        <Condition>
            <Condition.If expression={ (props.assetsSales?.length ?? 0) > 0 }>
                <div className="col-12">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-xxl-1 g-4">
                        <Collection
                            delegated={ true }
                            ref={ ref }
                            data={ props.assetsSales }
                            idSelector={ (assetSale) => assetSale.asset.assetId }
                            multiValueSort={ false }
                            bindings={ createBindings }
                        >
                            { (assetSale) => (
                                <div className="col">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <div className="row g-xxl-0 g-4">
                                                <div className="col-6 col-xxl-1 d-flex align-items-center">
                                                    <div className="d-none d-xxl-block">
                                                        <InvestmentImage
                                                            assetSale={ assetSale }
                                                            fundingValueClassName={ classNames(
                                                                font(FontDefinition.T6)
                                                            ) }
                                                            fundingStatusSize="3rem"
                                                            fundingStatusStrokeWidth="0.22rem"
                                                            imageSize={ {
                                                                width: "3rem",
                                                                height: "3rem"
                                                            } }
                                                        />
                                                    </div>
                                                    <div className="d-block d-xxl-none">
                                                        <InvestmentImage
                                                            assetSale={ assetSale }
                                                            fundingValueClassName={ classNames(
                                                                font(FontDefinition.T2)
                                                            ) }
                                                            fundingStatusSize="8rem"
                                                            fundingStatusStrokeWidth="0.5rem"
                                                            imageSize={ {
                                                                width: "8rem",
                                                                height: "8rem"
                                                            } }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="me-xxl-6 col-6 col-xxl-1 d-flex flex-column align-items-start justify-content-center">
                                                    <div className="text-left">
                                                        <SaleStatusBadge
                                                            assetSale={ assetSale }
                                                            className={ classNames(
                                                                font(FontDefinition.Small)
                                                                // "w-auto"
                                                            ) }
                                                        />
                                                    </div>
                                                    <span
                                                        className={ classNames(
                                                            "text-left",
                                                            font(FontDefinition.T2)
                                                        ) }
                                                    >
                                                        { assetSale.asset.assetShortId }
                                                    </span>
                                                </div>
                                                <div className="col-12 d-xxl-none">
                                                    <hr className="my-0" />
                                                </div>
                                                <div className="col-6 col-xxl d-flex flex-column align-items-start justify-content-center">
                                                    <span
                                                        className={ classNames(
                                                            "text-secondary",
                                                            font(FontDefinition.Small)
                                                        ) }
                                                    >
                                                        Asset Value
                                                    </span>
                                                    <span className={ classNames(font(FontDefinition.T6)) }>
                                                        { formatSaleCurrencyScale(assetSale.sale, assetSale.sale.oracleInfo.price) }
                                                        &nbsp;
                                                        { assetSale.sale.fundingCurrency.symbol }
                                                    </span>
                                                </div>
                                                <div className="col-6 col-xxl d-flex flex-column align-items-start justify-content-center">
                                                    <span
                                                        className={ classNames(
                                                            "text-secondary",
                                                            font(FontDefinition.Small)
                                                        ) }
                                                    >
                                                        Investment Size
                                                    </span>
                                                    <span className={ classNames(font(FontDefinition.T6)) }>
                                                        { (() =>
                                                        {
                                                            const value = assetSale.sale.monetaryInfo
                                                                .maxAmountOfFractions
                                                                .mul(
                                                                    assetSale.sale.monetaryInfo
                                                                        .pricePerFraction
                                                                );

                                                            return `${formatSaleCurrencyScale(assetSale.sale, value)} ${assetSale.sale.fundingCurrency.symbol}`;
                                                        })() }
                                                    </span>
                                                </div>
                                                <div className="col-6 col-xxl d-flex flex-column align-items-start justify-content-center">
                                                    <span className={ classNames("text-secondary", font(FontDefinition.Small)) }>Expected ROI</span>
                                                    <span className={ classNames(font(FontDefinition.T6)) }>
                                                        { assetSale.asset.expectedReturn.from }-{ assetSale.asset.expectedReturn.till }%
                                                    </span>
                                                </div>
                                                <div className="col-6 col-xxl d-flex flex-column align-items-start justify-content-center">
                                                    <span
                                                        className={ classNames(
                                                            "text-secondary",
                                                            font(FontDefinition.Small)
                                                        ) }
                                                    >
                                                        Fractions Available
                                                    </span>
                                                    <span className={ classNames(font(FontDefinition.T6)) }>
                                                        { (() =>
                                                        {
                                                            const value = assetSale.sale.monetaryInfo
                                                                .maxAmountOfFractions
                                                                .sub(
                                                                    assetSale.sale.monetaryInfo
                                                                        .fractionsPurchased
                                                                );

                                                            return `${formatNumber(value)}/${formatNumber(assetSale.sale.monetaryInfo.maxAmountOfFractions)}`;
                                                        })() }
                                                    </span>
                                                </div>
                                                <div className="col-6 col-xxl d-flex flex-column align-items-start justify-content-center">
                                                    <span
                                                        className={ classNames(
                                                            "text-secondary",
                                                            font(FontDefinition.Small)
                                                        ) }
                                                    >
                                                        Cost per Fraction
                                                    </span>
                                                    <span className={ classNames(font(FontDefinition.T6)) }>
                                                        { (() =>
                                                        {
                                                            return `${
                                                                formatSaleCurrencyScale(
                                                                    assetSale.sale,
                                                                    assetSale.sale.monetaryInfo
                                                                        .pricePerFraction
                                                                )
                                                            } ${assetSale.sale.fundingCurrency.symbol}`;
                                                        })() }
                                                    </span>
                                                </div>
                                                <div className="col-12 d-xxl-none">
                                                    <hr className="my-0" />
                                                </div>
                                                <div className="me-xxl-3 col-12 col-xxl-2 justify-content-center">
                                                    <Condition>
                                                        <Condition.If expression={ !!getActivePeriodEndDate(assetSale.sale) }>
                                                            <SaleStatusView
                                                                className={ classNames(
                                                                    "h-100 justify-content-center align-items-center rounded bg-body"
                                                                ) }
                                                                timerClassName="w-75 w-xxl-100 p-2"
                                                                statusTitleClassName={ classNames(font(FontDefinition.Small)) }
                                                                unitTitleClassName={ classNames(
                                                                    font(FontDefinition.SuperSmall)
                                                                ) }
                                                                unitValueClassName={ classNames(
                                                                    font(FontDefinition.T5)
                                                                ) }
                                                                unitSeparatorClassName={ classNames(
                                                                    font(FontDefinition.T5),
                                                                    "pb-3"
                                                                ) }
                                                                statusDescriptionTextClassName={ classNames("text-secondary", font(FontDefinition.T6)) }
                                                                sale={ assetSale?.sale }
                                                            />
                                                        </Condition.If>
                                                    </Condition>
                                                </div>
                                                <div className="col-12 col-xxl d-flex flex-column align-items-start justify-content-center">
                                                    <button
                                                        className="btn btn-pill-outline w-100"
                                                        onClick={ () =>
                                                            navigate(
                                                                generatePath(
                                                                    InvestmentDetails.route,
                                                                    {
                                                                        assetId: assetSale?.asset
                                                                            .assetId
                                                                            ?? null
                                                                    }
                                                                )
                                                            ) }
                                                    >
                                                        View
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) }
                        </Collection>
                    </div>
                </div>
            </Condition.If>
            <Condition.Else>
                <div className="col-12 my-6 text-center">
                    <span className={ classNames(font(FontDefinition.T3)) }>
                        <b>
                            There is currently no data available.
                        </b>
                    </span>
                </div>
            </Condition.Else>
        </Condition>
    );
}

export const AssetsList = forwardRef(AssetsListComponent);
