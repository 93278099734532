import { ReactNode, RefObject, useEffect, useRef } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import { Outlet } from "react-router-dom";
import { NotificationController, NotificationQueue, NotificationQueueController, NotificationType } from "../components/notificationQueue/NotificationQueue";
import { Breakpoint } from "../helpers/bootstrap";
import { classNames } from "../helpers/react";
import { font, FontDefinition } from "../helpers/typography";
import { IdentityVerification, useIdentityVerifications, VerificationStatus } from "../hooks/identityVerifications";
import { NotificationsContext } from "../hooks/notifications";

export type NotificationsLayoutProps = {
    children?: ReactNode;
};

function createVerificationNotification(
    identityVerification: IdentityVerification,
    verifiedText: string,
    notVerifiedTitle: string,
    notVerifiedText: string,
    verificationActionText: string
)
{
    switch (identityVerification.status)
    {
        case VerificationStatus.NotVerified:
        {
            return (
                <NotificationQueue.Notification type={ NotificationType.Warning } delay={ null }>
                    <NotificationQueue.Notification.Heading>
                        <span
                            className={ classNames(
                                "text-dark",
                                font(FontDefinition.T6),
                                font(FontDefinition.T4, Breakpoint.MD)
                            ) }
                        >
                            { notVerifiedTitle }
                        </span>
                    </NotificationQueue.Notification.Heading>
                    <NotificationQueue.Notification.Body>
                        <p className={ classNames("text-dark", font(FontDefinition.T5)) }>
                            { notVerifiedText }
                        </p>
                    </NotificationQueue.Notification.Body>
                    <NotificationQueue.Notification.Footer>
                        <div className="w-100 me-4 d-flex justify-content-end justify-content-md-start">
                            <span
                                className={ classNames(
                                    "text-primary",
                                    "text-decoration-underline",
                                    font(FontDefinition.T6),
                                    font(FontDefinition.T5, Breakpoint.MD)
                                ) }
                                role="button"
                                onClick={ identityVerification.verify }
                            >
                                { verificationActionText }
                            </span>
                        </div>
                    </NotificationQueue.Notification.Footer>
                </NotificationQueue.Notification>
            );
        }

        case VerificationStatus.Verified:
        {
            return (
                <NotificationQueue.Notification type={ NotificationType.Success } delay={ 500 }>
                    <NotificationQueue.Notification.Heading>
                        <span className={ classNames("text-dark", font(FontDefinition.T4)) }>
                            { verifiedText }
                        </span>
                    </NotificationQueue.Notification.Heading>
                </NotificationQueue.Notification>
            );
        }
    }
}

export function NotificationsLayout(props: NotificationsLayoutProps)
{
    const notificationsController = useRef<NotificationQueueController>(null);
    const identityVerifications = useIdentityVerifications();

    const kyc = useRef<RefObject<NotificationController>>();
    const email = useRef<RefObject<NotificationController>>();
    const walletConnection = useRef<RefObject<NotificationController>>();

    /*useEffect(
        () =>
        {
            if (!identityVerifications)
            {
                return;
            }

            const notifications = notificationsController.current!;

            kyc.current = notifications.push(
                createVerificationNotification(
                    identityVerifications.kyc,
                    "Success: KYC Verified",
                    "Warning: KYC Verification Incomplete",
                    "Your KYC verification is pending or incomplete. Please ensure you have provided all required information and complete the verification process to proceed.",
                    "Begin"
                )
            );

            email.current = notifications.push(
                createVerificationNotification(
                    identityVerifications.email,
                    "Success: Email Verified",
                    "Warning: Email Verification Incomplete",
                    "Your Email verification is pending or incomplete. Please ensure you have verified your email.",
                    "Begin"
                )
            );

            walletConnection.current = notifications.push(
                createVerificationNotification(
                    identityVerifications.walletConnection,
                    "Success: Wallet Connected",
                    "Warning: Wallet is not connected",
                    "Please connect your wallet to access full functionality and enjoy all the features Arkefi has to offer.",
                    "Begin"
                )
            );
        },
        [ !!identityVerifications ]
    );

    useEffect(
        () =>
        {
            if (!identityVerifications)
            {
                return;
            }

            return () => walletConnection.current?.current?.close();
        },
        [ identityVerifications?.walletConnection.status ]
    );

    useEffect(
        () =>
        {
            if (!identityVerifications)
            {
                return;
            }

            return () => kyc.current?.current?.close();
        },
        [ identityVerifications?.kyc.status ]
    );

    useEffect(
        () =>
        {
            if (!identityVerifications)
            {
                return;
            }

            return () => email.current?.current?.close();
        },
        [ identityVerifications?.email.status ]
    );*/

    return (
        <NotificationsContext.Provider value={ notificationsController }>
            { props.children ?? <Outlet /> }
            <NotificationQueue ref={ notificationsController } />
        </NotificationsContext.Provider>
    );
}
