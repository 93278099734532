import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import Select, { SingleValue } from "react-select";
import { getAllCountries } from "../../services/countries/countryService";
import { CountryDto } from "../../services/countries/dto/countryDto";
import { MappedCountry } from "../../services/countries/models/mappedCountry";
import styles from "./Shared.module.css";

type CountryProps = {
    id: number;
    selectedValue: MappedCountry | undefined;
    onChange: (option: any) => void;
    cssClass?: string;
    disabled: boolean;
};

const CountrySelect = (countryProps: CountryProps) =>
{
    const [ _countries, setCountries ] = useState<CountryDto[]>([]);
    const [ _mappedCountries, setMappedCountries ] = useState<MappedCountry[]>();

    async function getCountries()
    {
        let countries = await getAllCountries();
        let mappedCountries = countries.map((c) =>
        {
            return { label: c.name, value: c.code } as MappedCountry;
        });
        setCountries(countries);
        setMappedCountries(mappedCountries);
    }

    useEffect(() =>
    {
        getCountries();
    }, []);

    const customOptionRenderer = (country: MappedCountry) => (
        <div className="hover:bg-gray-300">
            <img
                loading="lazy"
                width="20"
                src={ `https://flagcdn.com/w20/${country.value?.toLowerCase()}.png` }
                srcSet={ `https://flagcdn.com/w40/${country.value?.toLowerCase()}.png 2x` }
                alt=""
            />{" "}
            { country.label }
        </div>
    );

    const customFormatOptionLabel = (option: MappedCountry) => customOptionRenderer(option);

    const handleChange = (option: SingleValue<MappedCountry>) =>
    {
        countryProps.onChange(option);
    };

    return (
        <>
            { _mappedCountries
                ? (
                    <Select
                        isDisabled={ countryProps.disabled }
                        components={ { DropdownIndicator: () => null, IndicatorSeparator: () => null } }
                        formatOptionLabel={ customFormatOptionLabel }
                        options={ _mappedCountries }
                        onChange={ handleChange }
                        value={ countryProps.selectedValue }
                        className={ countryProps?.cssClass }
                        placeholder={ <div className={ styles["select-placeholder-text"] }>Select</div> }
                        styles={ {
                            control: (base, props) => ({
                                ...base,
                                border: "none",
                                boxShadow: props.isFocused ? "none" : base.boxShadow
                            })
                        } }
                    />
                )
                : <></> }
        </>
    );
};

export default CountrySelect;
