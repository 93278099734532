import { newOnchain, Onchain } from "@artbanx/nexera/onchain";
import { ChainId } from "@artbanx/nexera/system";
import { Undefinable } from "@artbanx/nexera/system";
import { createContext, ReactNode } from "react";
import { useResource } from "../../hooks/resource";
import { useWallet } from "../../hooks/wallet";
import { chains } from "../../wagmi";

export type OnchainProviderProps = {
    children: ReactNode;
};

export const OnchainContext = createContext<Undefinable<Onchain>>(undefined);

export function OnchainProvider(props: OnchainProviderProps)
{
    const wallet = useWallet();

    const onchainQuery = useResource(
        [ wallet ],
        async () =>
        {
            if (!wallet)
            {
                return;
            }

            return await newOnchain(
                (wallet.network.chain?.id ?? chains[0].id) as ChainId,
                wallet.provider,
                wallet.signer
            );
        },
        (onchain) => onchain?.dispose()
    );

    return (
        <OnchainContext.Provider value={ onchainQuery.value }>
            { props.children }
        </OnchainContext.Provider>
    );
}
