import { useEffect, useState } from "react";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { useNotifications } from "../../hooks/notifications";
import { getCurrentIdentity } from "../../services/user/userService";
import { UserWalletsDto } from "../../services/userWallets/dto/userWalletDto";
import * as userWalletService from "../../services/userWallets/userWalletsService";
import { NotificationQueue, NotificationType } from "../notificationQueue/NotificationQueue";
import { UserWalletView } from "./UserWalletView";

export function UserWallets()
{
    const notificationController = useNotifications();

    let defaultUserWallets = {
        userId: "",
        wallets: []
    } as UserWalletsDto;
    const [ _userWallets, setUserWallets ] = useState<UserWalletsDto>(defaultUserWallets);
    const [ shouldUpdateList, setUpdateList ] = useState<boolean>(false);

    const user = getCurrentIdentity();

    async function getUserWallets()
    {
        try
        {
            const walletList = await userWalletService.getUserWallets(user.id);

            if (walletList)
            {
                setUserWallets(walletList);
            }
        }
        catch
        {
            notificationController.current?.push(
                <NotificationQueue.Notification type={ NotificationType.Error }>
                    <NotificationQueue.Notification.Heading>
                        <span className={ classNames("text-dark", font(FontDefinition.T4)) }>Something went wrong!</span>
                    </NotificationQueue.Notification.Heading>
                </NotificationQueue.Notification>
            );
        }
    }

    useEffect(() =>
    {
        getUserWallets();
    }, []);

    useEffect(() =>
    {
        if (shouldUpdateList)
        {
            setUserWallets(defaultUserWallets);
            getUserWallets();
            setUpdateList(false);
        }
    }, [ shouldUpdateList ]);

    function walletList()
    {
        return _userWallets?.wallets.map((wallet, index) =>
        {
            return <UserWalletView updateList={ setUpdateList } wallet={ wallet } key={ index } />;
        });
    }

    function noWallets()
    {
        return (
            <div className="card">
                <div className="text-info text-center">
                    No wallets are yet registered!
                </div>
            </div>
        );
    }

    return (
        <>
            <h1 className={ font(FontDefinition.T1) }></h1>
            { _userWallets ? walletList() : noWallets() }
        </>
    );
}
