import { ConnectButton } from "@rainbow-me/rainbowkit";
import { WalletConnectorBody } from "./WalletConnectorBody";

export function WallectConnector()
{
    return (
        <ConnectButton.Custom>
            { WalletConnectorBody }
        </ConnectButton.Custom>
    );
}
