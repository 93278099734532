import { Undefinable } from "@artbanx/nexera/system";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { createContext, ReactNode, useEffect, useState } from "react";
import { getConfiguration } from "../../configuration/configuration";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { IdentityVerifications, VerificationStatus } from "../../hooks/identityVerifications";
import { useOnchain } from "../../hooks/onchain";
import { useProgressModal } from "../../hooks/progressModal";
import { useEmailStatus, useKycStatus } from "../../hooks/queries";
import { useWallet } from "../../hooks/wallet";
import { getCurrentIdentity } from "../../services/user/userService";
import { KycStatus } from "../kycProvider/dto/KycUserStatusResultDto";

export const IdentityVerificationsContext = createContext<Undefinable<IdentityVerifications>>(undefined);

export type IdentityVerificationsProviderProps = {
    children: ReactNode;
};

export function IdentityVerificationsProvider(props: IdentityVerificationsProviderProps)
{
    const onchain = useOnchain();
    const wallet = useWallet();
    const connectModal = useConnectModal();
    const progressModal = useProgressModal();
    const user = getCurrentIdentity();
    const [ verifications, setVerifications ] = useState<IdentityVerifications>();

    const [ walletStatus, setWalletStatus ] = useState<VerificationStatus>();
    const [ kycStatus, setKycStatus ] = useState<VerificationStatus>();
    const [ emailStatus, setEmailStatus ] = useState<VerificationStatus>();
    const kycQuery = useKycStatus();
    const emailQuery = useEmailStatus();

    function fetchEmailStatus()
    {
        if (emailQuery.query.isLoading)
        {
            return;
        }

        setEmailStatus(emailQuery.query.data ?? VerificationStatus.NotVerified);
    }

    function fetchKycStatus()
    {
        if (kycQuery.query.isLoading)
        {
            return;
        }

        switch (kycQuery.query.data)
        {
            case KycStatus.NO_CHECKS_FOUND:
            case KycStatus.CHECK_FAILED:
                setKycStatus(VerificationStatus.NotVerified);
                break;
            case KycStatus.ALL_CHECKS_CLEAR:
                setKycStatus(VerificationStatus.Verified);
                break;
            default:
                break;
        }
    }

    function fetchWalletStatus()
    {
        if (!wallet)
        {
            return;
        }

        setWalletStatus(
            wallet.account.isConnected
                ? VerificationStatus.Verified
                : VerificationStatus.NotVerified
        );
    }

    function fetchIdentityVerifications()
    {
        console.log(walletStatus, kycStatus, emailStatus);

        // Set verifications only when we certainly know all statuses
        if (walletStatus === undefined || kycStatus === undefined || emailStatus === undefined)
        {
            return;
        }

        const statusesValue = {
            walletConnection: walletStatus,
            email: emailStatus,
            kyc: kycStatus
        };

        const statuses: typeof statusesValue & { [key: string]: VerificationStatus; } = statusesValue;

        function verificationComplete()
        {
            for (const key in statuses)
            {
                if (statuses[key] === VerificationStatus.NotVerified)
                {
                    return false;
                }
            }

            return true;
        }

        const verifications: IdentityVerifications = {
            verificationComplete: verificationComplete(),
            email: {
                status: statuses.email,
                verify: () =>
                {
                    if (!progressModal?.current)
                    {
                        return;
                    }

                    progressModal.current
                        .setProgressState(undefined)
                        .setUserCanHide(true)
                        .setMessage(undefined)
                        .setChildren(
                            <div className="d-flex justify-content-center flex-column align-items-center">
                                <span className={ classNames(font(FontDefinition.T3), "text-center") }>
                                    Please ensure that you have confirmed the verification mail on{" "}
                                    <span className={ classNames(font(FontDefinition.T2)) }>{ user.email }</span>.
                                </span>
                                {
                                    /*
                                <span className={classNames(font(FontDefinition.T4))}>
                                    You can resend the verification e-mail
                                    &nbsp;
                                    <a href="">here</a>.
                                </span>
                                */
                                }
                                <button
                                    onClick={ () => progressModal.current?.setVisible(false).execute() }
                                    className="mt-4 btn btn-primary btn-pill"
                                >
                                    Close
                                </button>
                            </div>
                        )
                        .setVisible(true)
                        .execute();
                }
            },
            kyc: {
                status: statuses.kyc,
                verify: () =>
                {
                    const BASE_URL = getConfiguration().cors.allowedOrigin;
                    window.location.href = `${BASE_URL}/wallet/#/ui/kyc`;
                }
            },
            walletConnection: {
                status: statuses.walletConnection,
                verify: () =>
                {
                    if (!connectModal.connectModalOpen)
                    {
                        connectModal.openConnectModal?.();
                    }
                }
            }
        };

        setVerifications(verifications);
    }

    useEffect(fetchEmailStatus, [ emailQuery.query.isLoading ]);
    useEffect(fetchKycStatus, [ kycQuery.query.isLoading ]);
    useEffect(fetchWalletStatus, [ wallet?.account.isConnected ]);
    useEffect(fetchIdentityVerifications, [ walletStatus, kycStatus, emailStatus ]);

    return (
        <IdentityVerificationsContext.Provider value={ verifications }>
            { props.children }
        </IdentityVerificationsContext.Provider>
    );
}
