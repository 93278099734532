import { Delegate, Undefinable } from "@artbanx/nexera/system";
import * as Enumerable from "linq-es5";
import {
    Dispatch,
    ForwardedRef,
    forwardRef,
    HTMLInputTypeAttribute,
    isValidElement,
    ReactNode,
    SetStateAction,
    useImperativeHandle,
    useLayoutEffect,
    useState
} from "react";
import { classNames, mutateNode } from "../../helpers/react";
import { ReactState } from "../../types/reactState";
import { Condition } from "../condition/Condition";

type ValidationInputProps = {
    id: string;
    value?: string;
    placeholder?: string;
    caption?: string;
    labelClassName?: string;
    inputClassName?: string;
    validationMessageClassName?: string;
    type?: HTMLInputTypeAttribute;
    onChange: Delegate<[id: string, value: Undefinable<string>]>;
    onValidationTriggered: Delegate<[id: string]>;
    errors: Iterable<ValidationError>;
};

function ValidationInput(props: ValidationInputProps)
{
    function* generateMessages()
    {
        let index = 0;
        for (const error of props.errors)
        {
            yield (
                <li key={ index++ } className={ classNames("text-danger", props.validationMessageClassName) }>
                    <span>
                        { error.message }
                    </span>
                </li>
            );
        }
    }

    return (
        <div className="form-group">
            <Condition>
                <Condition.If expression={ !!props.caption }>
                    <label htmlFor={ props.id } className={ classNames("form-label", props.labelClassName) }>
                        { props.caption }
                    </label>
                    <input
                        id={ props.id }
                        name={ props.id }
                        type={ props.type }
                        value={ props.value }
                        placeholder={ props.placeholder }
                        onBlur={ () => props.onValidationTriggered(props.id) }
                        onChange={ (event) => props.onChange(props.id, event.target.value) }
                        className={ classNames("form-control", props.inputClassName) }
                    />
                </Condition.If>
                <Condition.Else>
                    <input
                        id={ props.id }
                        name={ props.id }
                        type={ props.type }
                        value={ props.value }
                        onBlur={ () => props.onValidationTriggered(props.id) }
                        onChange={ (event) => props.onChange(props.id, event.target.value) }
                        className={ classNames("form-control", props.inputClassName) }
                    />
                </Condition.Else>
            </Condition>
            <ul>
                { [ ...generateMessages() ] }
            </ul>
        </div>
    );
}

export type ValidationFormBinding = {
    id: string;
    value: Undefinable<string>;
    errors: Iterable<ValidationError>;
};

type ValidationInputState = {
    id: string;
    value?: string;
    validate: Delegate<[value: Undefinable<string>], Iterable<ValidationError>>;
    errors: Iterable<ValidationError>;
};

export interface ValidationFormController
{
    isValid(): boolean;
    validate(): boolean;
    getBindings(): ValidationFormBinding[];
}

class ValidationFormControllerImpl implements ValidationFormController
{
    public readonly inputs: ValidationInputState[];
    public readonly setInputs: Dispatch<SetStateAction<ValidationInputState[]>>;

    public constructor(inputs: ReactState<ValidationInputState[]>)
    {
        [ this.inputs, this.setInputs ] = inputs;
    }

    public getBindings(): ValidationFormBinding[]
    {
        return this.inputs.map((input) => ({ id: input.id, value: input.value, errors: input.errors }));
    }

    public isValid(): boolean
    {
        return !Enumerable.from(this.inputs)
            .Any((input) => Enumerable.From(input.errors).Any());
    }

    public validate(): boolean
    {
        const inputs = this.inputs.map(
            (state) =>
            {
                const errors = state.validate(state.value);

                return { ...state, errors: errors ?? [] };
            }
        );

        this.setInputs(inputs);

        return !Enumerable.from(inputs).Any((input) => Enumerable.from(input.errors).Any());
    }

    public changeInput(id: string, value: Undefinable<string>)
    {
        this.setInputs(
            (inputs) =>
            {
                const input = inputs.find((state) => state.id === id);

                if (!input)
                {
                    return inputs;
                }

                const newState: ValidationInputState = {
                    ...input,
                    value,
                    errors: []
                };

                return inputs.map((state) => state.id === newState.id ? newState : state);
            }
        );
    }

    public validateInput(id: string)
    {
        this.setInputs(
            (inputs) =>
            {
                return inputs.map(
                    (input) =>
                    {
                        if (input.id === id)
                        {
                            const errors = input.validate(input.value);

                            return { ...input, errors: errors ?? [] };
                        }

                        return input;
                    }
                );
            }
        );
    }
}

export type ValidationFormProps = {
    children?: ReactNode;
    controller: ValidationFormController;
};

export function useValidationFormController(): ValidationFormController
{
    const inputs = useState<ValidationInputState[]>([]);

    return new ValidationFormControllerImpl(inputs);
}

export function ValidationForm(props: ValidationFormProps)
{
    const controller = props.controller as ValidationFormControllerImpl;

    const inputs: ValidationInputState[] = [];

    const rendered = mutateNode(
        props.children,
        (node) =>
        {
            if (!isValidElement(node))
            {
                return;
            }

            switch (node.type)
            {
                case ValidationForm.Input:
                {
                    const props = node.props as ValidationForm.InputProps;

                    const input = controller.inputs.find((state) => state.id === props.id)
                        ?? { id: props.id, validate: props.validate, value: props.value, errors: [] };

                    inputs.push(input);

                    return (
                        <ValidationInput
                            key={ props.id }
                            { ...{
                                id: props.id,
                                caption: props.caption,
                                inputClassName: props.inputClassName,
                                value: props.value,
                                labelClassName: props.labelClassName,
                                placeholder: props.placeholder,
                                validationMessageClassName: props.validationMessageClassName,
                                type: props.type,
                                errors: input.errors,
                                onValidationTriggered: (id) => controller.validateInput(id),
                                onChange: (id, value) => controller.changeInput(id, value)
                            } }
                        />
                    );
                }
            }
        }
    );

    useLayoutEffect(
        () => controller.setInputs(inputs),
        []
    );

    return <>{ rendered }</>;
}

export type ValidationError = {
    message: string;
};

export namespace ValidationForm
{
    export type InputProps =
        & Omit<ValidationInputProps, "errors" | "onChange" | "onValidationTriggered">
        & {
            id: string;
            validate: Delegate<[value: Undefinable<string>], Iterable<ValidationError>>;
        };

    export function Input(props: InputProps): JSX.Element
    {
        throw new Error("Validation form input must be rendered inside validation form!");
    }
}
