import { Delegate, Undefinable } from "@artbanx/nexera/system";
import { Children, isValidElement, ReactElement, ReactNode } from "react";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { IdentityVerification, VerificationStatus } from "../../hooks/identityVerifications";
import { Condition } from "../condition/Condition";

type VerificationStatusChild = ReactElement<VerificationStatusView.VerificationProps>;

export type VerificationStatusViewProps = {
    verification: Undefinable<IdentityVerification>;
    onVerify?: Delegate<[IdentityVerification]>;
    resolveNowText: string;
    children: VerificationStatusChild | VerificationStatusChild[];
};

export function VerificationStatusView(props: VerificationStatusViewProps)
{
    const verificationContent = Children.map(
        props.children,
        (node) =>
        {
            if (!isValidElement(node))
            {
                return;
            }

            switch (node.type)
            {
                case VerificationStatusView.Verified:
                {
                    if (props.verification?.status === VerificationStatus.Verified)
                    {
                        return node.props as VerificationStatusView.VerificationProps;
                    }

                    break;
                }
                case VerificationStatusView.NotVerified:
                {
                    if (props.verification?.status === VerificationStatus.NotVerified)
                    {
                        return node.props as VerificationStatusView.VerificationProps;
                    }

                    break;
                }
                case VerificationStatusView.Pending:
                {
                    if (!props.verification)
                    {
                        return node.props as VerificationStatusView.VerificationProps;
                    }

                    break;
                }
            }
        }
    );

    return (
        <div className="d-flex justify-content-start justify-content-lg-center align-items-center">
            <div className={ classNames("m-4", font(FontDefinition.T1)) }>
                <Condition>
                    <Condition.If expression={ () => !props.verification }>
                        <i className="bi bi-hourglass-split"></i>
                    </Condition.If>
                    <Condition.ElseIf expression={ () => props.verification!.status === VerificationStatus.Verified }>
                        <i className="text-success bi bi-check-circle-fill"></i>
                    </Condition.ElseIf>
                    <Condition.Else>
                        <i className="text-danger bi bi-x-circle-fill"></i>
                    </Condition.Else>
                </Condition>
            </div>
            <div>
                <div className="d-flex flex-column">
                    <div>
                        { verificationContent?.length > 0 ? verificationContent[0].children : <></> }
                    </div>
                    <Condition>
                        <Condition.If
                            expression={ () => props.verification?.status === VerificationStatus.NotVerified }
                        >
                            <div>
                                <a
                                    onClick={ () =>
                                    {
                                        props.onVerify?.(props.verification!);
                                        props.verification?.verify();
                                    } }
                                    role="button"
                                    className={ classNames("text-primary", font(FontDefinition.T5)) }
                                >
                                    { props.resolveNowText }
                                </a>
                            </div>
                        </Condition.If>
                    </Condition>
                </div>
            </div>
        </div>
    );
}

export namespace VerificationStatusView
{
    export type VerificationProps = {
        children: ReactNode;
    };

    export function Verified(props: VerificationProps): JSX.Element
    {
        throw "Verified must appear only inside VerificationStatus!";
    }

    export function NotVerified(props: VerificationProps): JSX.Element
    {
        throw "NotVerified must appear only inside VerificationStatus!";
    }

    export function Pending(props: VerificationProps): JSX.Element
    {
        throw "Pending must appear only inside VerificationStatus!";
    }
}
