import { formatSaleCurrencyScale, formatWithoutScale } from "@artbanx/nexera/helpers";
import { Buyer, Onchain, PurchaseTokensStages, PurchaseTransferInfo, SaleStatus } from "@artbanx/nexera/onchain";
import { Undefinable } from "@artbanx/nexera/system";
import { BigNumber } from "ethers";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../components/backButton/BackButton";
import { Loader } from "../components/loader/Loader";
import { ProgressState } from "../components/progressModal/ProgressModal";
import { getActivePeriodEndDate, SaleStatusView } from "../components/saleStatusView/SaleStatusView";
import { useValidationFormController, ValidationError, ValidationForm } from "../components/validationForm/ValidationForm";
import { classNames } from "../helpers/react";
import { font, FontDefinition } from "../helpers/typography";
import { useAssetDetailsTracking } from "../hooks/assetDetailsTracking";
import { useOnchain } from "../hooks/onchain";
import { useProgressModal } from "../hooks/progressModal";
import { useBuyerQuery } from "../hooks/queries";
import { AssetSaleDetails } from "../services/assetsSales/models/assetSaleDetails";
import * as UserPurchaseService from "../services/userPurchase/userPurchase";
import { InvestmentDetails } from "./InvestmentDetails";

type InvestBodyProps = {
    onchain: Onchain;
    assetSale: AssetSaleDetails;
    buyer: Buyer;
};

function InvestBody(props: InvestBodyProps)
{
    const navigate = useNavigate();
    const progressModal = useProgressModal();
    const validationForm = useValidationFormController();
    const [ termsChecked, setTermsChecked ] = useState<boolean>(false);

    const purchaseTransferInfo = useRef<PurchaseTransferInfo>(
        props.onchain.fetchPurchaseTransferInfo(props.assetSale.sale, BigNumber.from(0))
    );

    useEffect(
        () =>
        {
            if (!progressModal?.current)
            {
                return;
            }

            const valid = (props.assetSale.sale.saleStatus === SaleStatus.APPROVED_FOR_FUNDING
                || props.assetSale.sale.saleStatus === SaleStatus.FUNDED)
                && getActivePeriodEndDate(props.assetSale.sale)?.isAfter(moment());

            if (!valid)
            {
                progressModal.current
                    .setVisible(true)
                    .setUserCanHide(false)
                    .setProgressState(ProgressState.FAIL)
                    .setMessage("Current sale is not in Funding status")
                    .setChildren(
                        <div className="d-flex justify-content-center align-items-center">
                            <button
                                className="btn btn-secondary-outline"
                                onClick={ () => navigate(generatePath(InvestmentDetails.route, { assetId: props.assetSale.asset.assetId })) }
                            >
                                Ok
                            </button>
                        </div>
                    )
                    .execute();
            }
        },
        [ progressModal?.current ]
    );

    async function proceedPayment()
    {
        if (!validationForm.validate() || !progressModal?.current)
        {
            return;
        }

        const modal = progressModal.current;

        modal
            .setVisible(true)
            .setUserCanHide(false)
            .setProgressState(ProgressState.LOADING)
            .setChildren(false)
            .setMessage("Validating user wallet...")
            .execute();

        try
        {
            await UserPurchaseService.validatePurchase(
                props.buyer.address,
                props.assetSale.asset.assetId
            );
        }
        catch (exc: any)
        {
            console.log(exc);

            modal
                .setUserCanHide(true)
                .setProgressState(ProgressState.FAIL)
                .setChildren(
                    <div className="d-flex justify-content-center align-items-center">
                        <button
                            className="btn btn-secondary-outline"
                            onClick={ () => modal.setVisible(false).execute() }
                        >
                            Ok
                        </button>
                    </div>
                )
                .setMessage(exc.message ?? "Something went wrong")
                .execute();

            return;
        }

        await props.onchain.purchaseFractions(props.assetSale.sale.id, purchaseTransferInfo.current.tokensToPurchase)
            .on(
                PurchaseTokensStages.GetSaleMonetaryInfo,
                () =>
                {
                    modal
                        .setMessage("Receiving sale monetary info...")
                        .execute();
                }
            )
            .on(
                PurchaseTokensStages.ApproveERC20AmountForABX,
                () =>
                {
                    modal
                        .setMessage("Waiting for ERC20 approval from signer...")
                        .execute();
                }
            )
            .on(
                PurchaseTokensStages.Purchase,
                () =>
                {
                    modal
                        .setMessage("Waiting for purchase approval from signer...")
                        .execute();
                }
            )
            .onSuccess(
                () =>
                {
                    modal
                        .setProgressState(ProgressState.SUCCESS)
                        .setMessage("Payment has been successfully completed")
                        .setUserCanHide(true)
                        .setOnHide(
                            () =>
                                navigate(
                                    generatePath(
                                        InvestmentDetails.route,
                                        { assetId: props.assetSale.asset.assetId }
                                    )
                                )
                        )
                        .setChildren(
                            <div className="d-flex justify-content-center align-items-center">
                                <button
                                    className="btn btn-secondary-outline"
                                    onClick={ () => modal.setVisible(false).execute() }
                                >
                                    Ok
                                </button>
                            </div>
                        )
                        .execute();
                }
            )
            .onFail(
                (exc) =>
                {
                    modal
                        .setProgressState(ProgressState.FAIL)
                        .setMessage(exc.reason ?? exc.message ?? "Something went wrong")
                        .setUserCanHide(true)
                        .setChildren(
                            <div className="d-flex justify-content-center align-items-center">
                                <button
                                    className="btn btn-secondary-outline"
                                    onClick={ () => modal.setVisible(false).execute() }
                                >
                                    Ok
                                </button>
                            </div>
                        )
                        .execute();
                }
            )
            .execute();
    }

    function* validateAmount(value: Undefinable<string>): Generator<ValidationError>
    {
        if (!value)
        {
            yield { message: "Amount is required." };
            return;
        }

        function parseAmount()
        {
            try
            {
                return BigNumber.from(value);
            }
            catch
            {
                return undefined;
            }
        }

        const amount = parseAmount();

        if (!amount)
        {
            yield { message: "Amount must be correct numeric value." };
            return;
        }

        if (amount.lte(0))
        {
            yield { message: "Amount must be greater then zero." };
            return;
        }

        purchaseTransferInfo.current = props.onchain.fetchPurchaseTransferInfo(props.assetSale.sale, amount);

        const validationResult = props.onchain.fetchPurchaseTransferValidationResult(
            props.buyer,
            props.assetSale.sale,
            purchaseTransferInfo.current
        );

        if (!validationResult.valid)
        {
            if (validationResult.fundsMissing.gt(0))
            {
                yield {
                    message: `Not enough funds, missing ${
                        formatSaleCurrencyScale(props.assetSale.sale, validationResult.fundsMissing)
                    } ${props.assetSale.sale.fundingCurrency.symbol}.`
                };
            }

            if (validationResult.tokensMissing.gt(0))
            {
                yield {
                    message: `Not enough fractions available, missing ${
                        formatWithoutScale(validationResult.tokensMissing)
                    } fractions, try to decrease fractions amount.`
                };
            }

            return;
        }
    }

    function downloadTermsAndCondition()
    {
        fetch("/downloads/pdf/terms_of_services_for_a_buy_back_option.pdf").then((response) =>
        {
            response.blob().then((blob) =>
            {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = "terms_of_service_for_a_buy_back_option.pdf";
                alink.click();
            });
        });
    }

    return (
        <div className="row g-3 pt-3">
            <div className="col-auto">
                <BackButton className={ classNames(font(FontDefinition.T3)) } />
            </div>
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row g-3">
                            <div className="col-12 col-md-6">
                                <span className={ classNames("card-title", font(FontDefinition.T0)) }>
                                    Invest
                                </span>
                                <span className={ classNames(font(FontDefinition.T5)) }>
                                    Select the amount of fractions you would like to invest in.
                                </span>
                            </div>
                            <div className="col-xl-2 d-none d-xl-block">
                            </div>
                            <div className="col-12 col-md-6 ps-md-2 px-0 pe-md-0 col-xl-4 d-flex align-items-center justify-content-center justify-content-md-end">
                                <div className="w-100 d-flex justfy-content-between bg-body rounded">
                                    <SaleStatusView
                                        showStatus
                                        infoTextPlacement="auto-end"
                                        sale={ props.assetSale.sale }
                                        className="p-3 w-100"
                                        statusTitleClassName={ classNames(
                                            "d-flex",
                                            "justify-content-end",
                                            "mb-2",
                                            font(FontDefinition.T7)
                                        ) }
                                        statusDescriptionTextClassName={ classNames(
                                            font(FontDefinition.T1),
                                            "text-secondary"
                                        ) }
                                        unitValueClassName={ classNames(font(FontDefinition.T1)) }
                                        unitSeparatorClassName={ classNames(font(FontDefinition.T2), "text-secondary", "pb-3") }
                                        unitTitleClassName={ classNames(font(FontDefinition.Small)) }
                                    />
                                </div>
                            </div>
                            <div className="col-12 px-0">
                                <div className="row gx-3 gy-3 p-0 m-0">
                                    <div className="col-12 col-md bg-body rounded me-md-2">
                                        <div className="row gy-5 h-100 p-0 m-0 px-3 pb-5">
                                            <div className="col-12 d-flex flex-column">
                                                <span className={ classNames(font(FontDefinition.T7)) }>Reference Number</span>
                                                <span className={ classNames(font(FontDefinition.T2)) }>{ props.assetSale.asset.assetShortId }</span>
                                            </div>
                                            <div className="col-12 d-flex flex-column">
                                                <span className={ classNames(font(FontDefinition.T7)) }>Fractions Available</span>
                                                <span className={ classNames(font(FontDefinition.T2)) }>
                                                    { formatWithoutScale(
                                                        props.assetSale.sale.monetaryInfo.maxAmountOfFractions.sub(
                                                            props.assetSale.sale.monetaryInfo.fractionsPurchased
                                                        )
                                                    ) }/{ formatWithoutScale(
                                                        props.assetSale.sale.monetaryInfo.maxAmountOfFractions
                                                    ) }
                                                </span>
                                            </div>
                                            <div className="col-12 d-flex flex-column">
                                                <span className={ classNames(font(FontDefinition.T7)) }>Cost per Fraction</span>
                                                <span className={ classNames(font(FontDefinition.T2)) }>
                                                    { formatSaleCurrencyScale(props.assetSale.sale, props.assetSale.sale.monetaryInfo.pricePerFraction) }
                                                    &nbsp;
                                                    { props.assetSale.sale.fundingCurrency.symbol }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md bg-primary-lt rounded text-dark ms-md-2">
                                        <div className="row h-100 gy-5 p-3 pb-5">
                                            <ValidationForm controller={ validationForm }>
                                                <div className="col-12 col-lg-6">
                                                    <ValidationForm.Input
                                                        id="amount"
                                                        type="number"
                                                        validate={ (value) => [ ...validateAmount(value) ] }
                                                        caption="Amount of Fractions to Invest"
                                                        placeholder="Amount"
                                                        labelClassName={ classNames(font(FontDefinition.T4)) }
                                                        inputClassName={ classNames(font(FontDefinition.T5)) }
                                                        validationMessageClassName={ classNames(font(FontDefinition.T7)) }
                                                    />
                                                </div>
                                                <div className="col-12 mt-4">
                                                    <hr className="my-0" />
                                                </div>
                                                <div className="col-12 d-flex justify-content-between">
                                                    <span className={ classNames(font(FontDefinition.T7)) }>Subtotal</span>
                                                    <span className={ classNames(font(FontDefinition.T7)) }>
                                                        { formatSaleCurrencyScale(props.assetSale.sale, purchaseTransferInfo.current.totalToPay) }&nbsp;{ props
                                                            .assetSale.sale
                                                            .fundingCurrency.symbol }
                                                    </span>
                                                </div>
                                                <div className="col-12 mt-4">
                                                    <hr className="my-0" />
                                                </div>
                                                <div className="col-12 d-flex justify-content-between">
                                                    <span className={ classNames(font(FontDefinition.T2)) }>Total</span>
                                                    <span className={ classNames(font(FontDefinition.T2)) }>
                                                        { formatSaleCurrencyScale(props.assetSale.sale, purchaseTransferInfo.current.totalToPay) }&nbsp;{ props
                                                            .assetSale.sale
                                                            .fundingCurrency.symbol }
                                                    </span>
                                                </div>
                                            </ValidationForm>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-check">
                                    <input
                                        id="terms-and-conditions"
                                        type="checkbox"
                                        className="form-check-input"
                                        onChange={ (event) => setTermsChecked(event.target.checked) }
                                    />
                                    <div className="d-inline d-flex align-items-center">
                                        <label htmlFor="terms-and-conditions" className={ classNames("form-label", font(FontDefinition.Small)) }>
                                            I have read and agree to the{" "}
                                            <a
                                                target="_blank"
                                                href={ window.location.origin + "/downloads/pdf/terms_of_services_for_a_buy_back_option.pdf" }
                                                rel="noopener noreferrer"
                                            >
                                                Terms of Services for a Buy-Back Option
                                            </a>{"  "}
                                            and{" "}
                                            <a target="_blank" href={ window.location.origin + "/privacy-policy/" } rel="noopener noreferrer">
                                                Privacy Policy
                                            </a>.
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col d-none d-lg-block p-0 m-0">
                            </div>
                            <div className="col-12 col-lg-4 col-xl-3 mt-0 mt-lg-3">
                                <button
                                    disabled={ !validationForm.isValid() || !termsChecked }
                                    className={ classNames("btn btn-primary btn-pill w-100", font(FontDefinition.T3)) }
                                    onClick={ proceedPayment }
                                >
                                    Proceed to Payment
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function Invest()
{
    const onchain = useOnchain();

    const { assetId } = useParams();

    const assetSale = useAssetDetailsTracking({ id: assetId });
    const buyer = useBuyerQuery(assetSale?.sale, { refetchInterval: 10000 });

    return (
        <Loader message="Please connect your wallet..." dependencies={ { buyer: buyer.query.data, assetSale, onchain, signer: onchain?.signer } }>
            { (dependencies) => <InvestBody { ...dependencies } /> }
        </Loader>
    );
}
export namespace Invest
{
    export const route = "/ui/invest/:assetId";
}
