import { Delegate } from "@artbanx/nexera/system";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { useIdentityVerifications } from "../../hooks/identityVerifications";
import { Condition } from "../condition/Condition";
import { VerificationStatusView } from "../verificationStatusView/VerificationStatusView";

export type IdentityVerificationsViewProps = {
    onVerification?: Delegate;
};

export function IdentityVerificationsView(props: IdentityVerificationsViewProps)
{
    const verifications = useIdentityVerifications();

    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-3">
                        <Condition>
                            <Condition.If expression={ () => !verifications }>
                                <div className="card bg-info-lt fg-dark rounded">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <i
                                                className={ classNames(
                                                    "bi bi-hourglass mx-2",
                                                    font(FontDefinition.T1)
                                                ) }
                                            />
                                            <p
                                                className={ classNames(
                                                    "mx-2 mb-0",
                                                    font(FontDefinition.Small)
                                                ) }
                                            >
                                                Verifications status is loading...
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Condition.If>
                            <Condition.ElseIf expression={ () => verifications!.verificationComplete }>
                                <div className="card bg-success-lt fg-dark rounded">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <i
                                                className={ classNames(
                                                    "bi bi-check-circle mx-2",
                                                    font(FontDefinition.T1)
                                                ) }
                                            />
                                            <p
                                                className={ classNames(
                                                    "mx-2 mb-0",
                                                    font(FontDefinition.Small)
                                                ) }
                                            >
                                                All steps have been successfully completed! You are now ready to begin your journey with Arkefi.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Condition.ElseIf>
                            <Condition.Else>
                                <div className="card bg-info-lt fg-dark rounded">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <i
                                                className={ classNames(
                                                    "bi bi-exclamation-circle mx-2",
                                                    font(FontDefinition.T1)
                                                ) }
                                            />
                                            <p
                                                className={ classNames(
                                                    "mx-2 mb-0 fs-6 fw-medium",
                                                    font(FontDefinition.Small)
                                                ) }
                                            >
                                                Complete the steps to begin your journey with Arkefi.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Condition.Else>
                        </Condition>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3">
                        <VerificationStatusView verification={ verifications?.email } onVerify={ props.onVerification } resolveNowText="Resolve Now">
                            <VerificationStatusView.Verified>
                                <span className={ classNames(font(FontDefinition.T2)) }>E-mail validated</span>
                            </VerificationStatusView.Verified>
                            <VerificationStatusView.NotVerified>
                                <span className={ classNames(font(FontDefinition.T2)) }>
                                    E-mail not validated
                                </span>
                            </VerificationStatusView.NotVerified>
                            <VerificationStatusView.Pending>
                                <span className={ classNames(font(FontDefinition.T2)) }>
                                    E-mail validation status is loading...
                                </span>
                            </VerificationStatusView.Pending>
                        </VerificationStatusView>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3">
                        <VerificationStatusView verification={ verifications?.walletConnection } onVerify={ props.onVerification } resolveNowText="Connect Now">
                            <VerificationStatusView.Verified>
                                <span className={ classNames(font(FontDefinition.T2)) }>Wallet connected</span>
                            </VerificationStatusView.Verified>
                            <VerificationStatusView.NotVerified>
                                <span className={ classNames(font(FontDefinition.T2)) }>
                                    Wallet not connected
                                </span>
                            </VerificationStatusView.NotVerified>
                            <VerificationStatusView.Pending>
                                <span className={ classNames(font(FontDefinition.T2)) }>
                                    Wallet connection status is loading...
                                </span>
                            </VerificationStatusView.Pending>
                        </VerificationStatusView>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3">
                        <VerificationStatusView verification={ verifications?.kyc } onVerify={ props.onVerification } resolveNowText="Resolve Now">
                            <VerificationStatusView.Verified>
                                <span className={ classNames(font(FontDefinition.T2)) }>KYC verified</span>
                            </VerificationStatusView.Verified>
                            <VerificationStatusView.NotVerified>
                                <span className={ classNames(font(FontDefinition.T2)) }>KYC not verified</span>
                            </VerificationStatusView.NotVerified>
                            <VerificationStatusView.Pending>
                                <span className={ classNames(font(FontDefinition.T2)) }>
                                    KYC verification status is loading...
                                </span>
                            </VerificationStatusView.Pending>
                        </VerificationStatusView>
                    </div>
                </div>
            </div>
        </div>
    );
}
