import { SaleStatus } from "@artbanx/nexera/onchain";
import { Topics } from "@artbanx/nexera/subgraph";
import { Undefinable } from "@artbanx/nexera/system";
import { BigNumber } from "ethers";
import * as Enumerable from "linq-es5";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { AssetSale } from "../services/assetsSales/models/assetSale";
import { AssetSaleDetails } from "../services/assetsSales/models/assetSaleDetails";
import { AssetSaleTransactionEntity } from "../services/assetsSales/models/assetSaleTransactionEntity";
import { AssetSaleTransactionType } from "../services/assetsSales/models/assetSaleTransactionType";
import { useOnchain } from "./onchain";
import { useTransactionsQuery } from "./queries";
import { useSubgraphTopicsConfigurator } from "./subgraph";

export function useTransactionsTracking(
    assetSale: Undefinable<AssetSaleDetails | AssetSale>
)
{
    const onchain = useOnchain();
    const queryClient = useQueryClient();
    const transactionsQuery = useTransactionsQuery(assetSale);
    const topicsConfigurator = useSubgraphTopicsConfigurator();

    function initialize()
    {
        if (!onchain || !topicsConfigurator || !transactionsQuery.query.isFetchedAfterMount)
        {
            return;
        }

        const topics = topicsConfigurator
            .addTopic(Topics.Purchase)
            .addTopic(Topics.SaleStatusUpdate)
            .addTopic(Topics.FundsReceipt)
            .build();

        const subscriptions = [
            topics.fundsReceipt.subscribe(
                (event) =>
                {
                    queryClient.setQueryData<AssetSaleTransactionEntity[]>(
                        transactionsQuery.key,
                        (transactions) =>
                        {
                            const isOldEvent = Enumerable.from(transactions).Any((transaction) => transaction.value.id.eq(event.id));

                            if (isOldEvent)
                            {
                                return transactions ?? [];
                            }

                            return [ ...transactions ?? [], {
                                type: AssetSaleTransactionType.FundsReceival,
                                value: {
                                    id: BigNumber.from(event.id),
                                    blockNumber: BigNumber.from(event.blockNumber),
                                    blockTimestamp: BigNumber.from(event.blockTimestamp),
                                    buyer: event.buyer,
                                    saleId: BigNumber.from(event.saleId),
                                    transactionHash: event.transactionHash,
                                    amountReceived: BigNumber.from(event.amountReceived)
                                }
                            } ];
                        }
                    );
                }
            ),
            topics.purchase.subscribe(
                (event) =>
                {
                    queryClient.setQueryData<AssetSaleTransactionEntity[]>(
                        transactionsQuery.key,
                        (transactions) =>
                        {
                            const isOldEvent = Enumerable.from(transactions).Any((transaction) => transaction.value.id.eq(event.id));

                            if (isOldEvent)
                            {
                                return transactions ?? [];
                            }

                            return [ ...transactions ?? [], {
                                type: AssetSaleTransactionType.Purchase,
                                value: {
                                    id: BigNumber.from(event.id),
                                    blockNumber: BigNumber.from(event.blockNumber),
                                    blockTimestamp: BigNumber.from(event.blockTimestamp),
                                    buyer: event.buyer,
                                    fractionsPurchased: BigNumber.from(event.fractionsPurchased),
                                    saleId: BigNumber.from(event.saleId),
                                    transactionHash: event.transactionHash
                                }
                            } ];
                        }
                    );
                }
            ),
            topics.saleStatusUpdate.subscribe(
                async (event) =>
                {
                    queryClient.setQueryData<AssetSaleTransactionEntity[]>(
                        transactionsQuery.key,
                        (transactions) =>
                        {
                            const isOldEvent = Enumerable.from(transactions).Any((transaction) => transaction.value.id.eq(event.id));
                            const isBuyback = (Number(event.status) as SaleStatus) === SaleStatus.CLOSED_BY_BUYBACK;

                            if (isOldEvent || !isBuyback)
                            {
                                return transactions ?? [];
                            }

                            return [ ...transactions ?? [], {
                                type: AssetSaleTransactionType.Buyback,
                                value: {
                                    id: BigNumber.from(event.id),
                                    blockNumber: BigNumber.from(event.blockNumber),
                                    blockTimestamp: BigNumber.from(event.blockTimestamp),
                                    saleId: BigNumber.from(event.saleId),
                                    transactionHash: event.transactionHash
                                }
                            } ];
                        }
                    );
                }
            )
        ];

        return () =>
        {
            for (const subscription of subscriptions)
            {
                subscription.dispose();
            }
        };
    }

    useEffect(initialize, [
        !!onchain,
        !!topicsConfigurator,
        assetSale?.asset.assetId,
        transactionsQuery.query.isFetchedAfterMount
    ]);

    return transactionsQuery.query.data;
}
