import { useAccount } from "wagmi";
import { WallectConnector } from "./wallet/WallectConnector";

type Props = {
    account: ReturnType<typeof useAccount>;
};

export function StickyWalletButton(props: Props)
{
    return (
        <div
            className="position-fixed d-flex justify-content-end"
            style={ { zIndex: 800000, left: "1rem", bottom: "1rem" } }
        >
            <WallectConnector />
        </div>
    );
}
