import { ReactNode } from "react";
import { OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";
import { classNames } from "../../helpers/react";
import { Condition } from "../condition/Condition";

export enum IconPosition
{
    Start = "start",
    End = "end"
}

export type InfoTextProps = {
    title?: string;
    children?: ReactNode;
    className?: string;
    iconClassName?: string;
    placement?: Placement;
    iconPosition?: IconPosition;
};

export function InfoText(props: InfoTextProps)
{
    const icon = (
        <OverlayTrigger
            placement={ props.placement }
            overlay={ 
                <BootstrapTooltip style={ { zIndex: 900000 } }>
                    <div className="text-start p-1">
                        { props.children }
                    </div>
                </BootstrapTooltip>
             }
        >
            <i
                onClick={ (e) => e.stopPropagation() }
                className={ classNames("cursor-pointer", "bi", "bi-info-circle", props.iconClassName) }
            >
            </i>
        </OverlayTrigger>
    );

    return (
        <span className={ classNames("p-0 m-0", props.className) }>
            <Condition>
                <Condition.If expression={ props.iconPosition === IconPosition.End }>
                    { props.title }
                    &nbsp;
                    { icon }
                </Condition.If>
                <Condition.Else>
                    { icon }
                    &nbsp;
                    { props.title }
                </Condition.Else>
            </Condition>
        </span>
    );
}
