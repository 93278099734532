import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { QueryClient, QueryClientProvider } from "react-query";
import { createHashRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import { WagmiConfig } from "wagmi";
import { KycForm } from "./components/kyc/KycForm";
import { OnRamper } from "./components/onRamp/onRamper";
import { UserManager } from "./components/profileManager/userManager";
import { getConfiguration } from "./configuration/configuration";
import { KycLayout } from "./layouts/KycLayout";
import { MainLayout } from "./layouts/MainLayout";
import { MediaHeaderLayout } from "./layouts/MediaHeaderLayout";
import { NotificationsLayout } from "./layouts/NotificationsLayout";
import { ProgressModalLayout } from "./layouts/ProgressModalLayout";
import { WalletLayout } from "./layouts/WalletLayout";
import { BuyBack } from "./pages/BuyBack";
import { ClaimFunds } from "./pages/ClaimFunds";
import { Explore } from "./pages/Explore";
import { Invest } from "./pages/Invest";
import { InvestmentDetails } from "./pages/InvestmentDetails";
import { KycManager } from "./pages/KycManager";
import { Login } from "./pages/Login";
import { NotFound } from "./pages/NotFound";
import { Portfolio } from "./pages/Portfolio";
import { RedirectManager } from "./pages/RedirectManager";
import { RequestSale } from "./pages/RequestSale";
import { injectJWTInterceptor } from "./services/shared/jwtIntereceptor";
import * as UserService from "./services/user/userService";
import { chains, config } from "./wagmi";

export function App()
{
    const isDevelopment = getConfiguration().chainMode === "development";

    const routes = createHashRouter(
        createRoutesFromElements(
            <Route>
                <Route element={ <ProgressModalLayout /> }>
                    <Route element={ <NotificationsLayout /> }>
                        <Route element={ <WalletLayout /> }>
                            <Route element={ <KycLayout /> }>
                                <Route path={ KycManager.route } element={ <KycManager /> } />
                                <Route element={ <MediaHeaderLayout /> }>
                                    <Route element={ <MainLayout /> }>
                                        <Route path={ Explore.route } element={ <Explore /> } />
                                    </Route>
                                </Route>
                                <Route element={ <MainLayout /> }>
                                    <Route path={ Portfolio.route } element={ <Portfolio /> } />
                                    <Route path={ InvestmentDetails.route } element={ <InvestmentDetails /> } />
                                    <Route path="" element={ <RedirectManager /> }></Route>
                                    <Route path={ Invest.route } element={ <Invest /> } />
                                    <Route path={ ClaimFunds.route } element={ <ClaimFunds /> } />
                                    <Route path={ BuyBack.route } element={ <BuyBack /> } />
                                    { isDevelopment ? <Route path={ RequestSale.route } element={ <RequestSale /> } /> : <></> }
                                    { isDevelopment ? <Route path={ Login.route } element={ <Login /> } /> : <></> }
                                </Route>
                            </Route>
                            <Route path={ UserManager.route } element={ <UserManager /> } />

                            <Route path={ KycForm.route } element={ <KycForm /> } />
                            <Route path={ OnRamper.route } element={ <OnRamper /> } />
                        </Route>
                    </Route>
                </Route>
                <Route path={ NotFound.route } element={ <NotFound /> } />
            </Route>
        )
    );

    const client = new QueryClient(
        {
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false
                }
            }
        }
    );

    injectJWTInterceptor();

    return (
        <div>
            <QueryClientProvider client={ client }>
                <WagmiConfig config={ config }>
                    <RainbowKitProvider chains={ chains }>
                        <RouterProvider router={ routes } />
                    </RainbowKitProvider>
                </WagmiConfig>
            </QueryClientProvider>
        </div>
    );
}
