import { Undefinable } from "@artbanx/nexera/system";
import { ethers } from "ethers";
import { createContext, ReactNode } from "react";
import { useAccount, useDisconnect, useNetwork, useSwitchNetwork } from "wagmi";
import { useEthersProvider, useEthersSigner } from "../../hooks/ethers";
import { useResource } from "../../hooks/resource";
import { chains } from "../../wagmi";
import { UserWalletModal } from "../userWallet/UserWalletModal";

export type Signer = ethers.Signer;
export type Provider = ethers.providers.Provider;
export type Account = ReturnType<typeof useAccount>;
export type Network = ReturnType<typeof useNetwork>;
export type NetworkSwitch = ReturnType<typeof useSwitchNetwork>;
export type WalletDisconnect = ReturnType<typeof useDisconnect>;

export interface Wallet
{
    signer: Undefinable<Signer>;
    provider: Provider;
    account: Account;
    network: Network;
    networkSwitch: NetworkSwitch;
    disconnect: WalletDisconnect;
}

export const WalletContext = createContext<Undefinable<Wallet>>(undefined);

export type WalletProviderProps = {
    children: ReactNode;
};

export function WalletProvider(props: WalletProviderProps)
{
    const network = useNetwork();
    const account = useAccount();
    const signer = useEthersSigner();
    const provider = useEthersProvider({ chainId: chains[0]?.id });
    const networkSwitch = useSwitchNetwork();
    const disconnect = useDisconnect();

    const walletQuery = useResource(
        [ signer, account.address, network.chain?.id, provider, networkSwitch.data ],
        () =>
        {
            const newWallet: Wallet = {
                provider,
                signer,
                account,
                network,
                networkSwitch,
                disconnect
            };

            return newWallet;
        }
    );

    return (
        <>
            <WalletContext.Provider value={ walletQuery.value }>
                { props.children }
                <UserWalletModal />
            </WalletContext.Provider>
        </>
    );
}
function useEffect(arg0: () => void, arg1: (`0x${string}` | undefined)[])
{
    throw new Error("Function not implemented.");
}
