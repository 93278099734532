import { useEffect, useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import { ConnectorStyleProps } from "react-form-stepper/dist/components/Connector/ConnectorTypes";
import { StepDTO, StepStyleDTO } from "react-form-stepper/dist/components/Step/StepTypes";
import { KycChecker } from "./KycChecker";
import KycClientInitiator from "./kycClientInitiator";
import { KycForm } from "./KycForm";
import "./KycStepper.css";
import { useSearchParams } from "react-router-dom";
import KycCongrats from "./KycCongrats";
const KycStepper = () =>
{
    const [ _activeStep, setActiveStep ] = useState<number>(0);
    const [ _firstPhaseValid, setFirstPhase ] = useState<boolean>(false);
    const [ searchParams, setSearchParams ] = useSearchParams();

    function getPhase()
    {
        let phase = searchParams.get("phase");
        if (phase)
        {
            setActiveStep(2);
        }
    }

    useEffect(() =>
    {
        getPhase();
    }, []);

    const steps = [
        { label: "Identification Confirmation & Personal Details" },
        { label: "KYC" }
    ] as StepDTO[];

    const stepStyle = {
        activeBgColor: "white",
        activeTextColor: "#0020F5",
        completedBgColor: "#0020F5",
        completedTextColor: "white",
        inactiveBgColor: "white",
        inactiveTextColor: "#808285"
    } as StepStyleDTO;

    const connectorStyle = {
        activeColor: "#0020F5",
        completedColor: "#0020F5",
        disabledColor: "#808285"
    } as ConnectorStyleProps;

    function handleActiveSteps(activeStep: number)
    {
        // steps[activeStep - 1].completed = true;
        // steps[activeStep].active = true;
        setActiveStep(activeStep);
    }
    function getSectionComponent()
    {
        switch (_activeStep)
        {
            case 0:
                return <KycForm setPhase={ handleActiveSteps } />;
            case 1:
                return <KycClientInitiator />;
            default:
                return <KycCongrats />;
        }
    }

    return (
        <>
            <div className="card mb-3 ">
                <div className="card-header d-flex flex-row justify-content-center">
                    <Stepper
                        className={ _activeStep >= 1 ? "custom-connector" : "" }
                        // stepClassName={ _activeStep >= 1 ? "custom-connector" : "" }
                        steps={ steps }
                        activeStep={ _activeStep }
                        styleConfig={ stepStyle }
                        connectorStyleConfig={ connectorStyle }
                    >
                    </Stepper>
                </div>
            </div>
            <div className="card mb-3">
                { getSectionComponent() }
            </div>
        </>
    );
};

export default KycStepper;
