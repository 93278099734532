import { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { getAllCountries } from "../../services/countries/countryService";
import { CountryDto } from "../../services/countries/dto/countryDto";
import { MappedCountry } from "../../services/countries/models/mappedCountry";
import styles from "./Shared.module.css";
type DialingSelectProps = {
    onChange: (option: any) => void;
    value: SingleValue<MappedCountry> | undefined;
    cssClass: string;
    placeHolder?: string;
};

const DialingSelect = (props: DialingSelectProps) =>
{
    const [ _countries, setCountries ] = useState<CountryDto[]>([]);
    const [ _mappedCountries, setMappedCountries ] = useState<MappedCountry[]>();
    const [ _singleSelection, setSingleSelection ] = useState([]);

    async function getCountries()
    {
        let countries = await getAllCountries();
        let mappedCountries = countries.map((c) =>
        {
            return { label: c.diallingCode, value: c.code } as MappedCountry;
        });
        setCountries(countries);
        setMappedCountries(mappedCountries);
    }

    useEffect(() =>
    {
        getCountries();
    }, []);

    const customOptionRenderer = (country: MappedCountry) => (
        <div className="hover:bg-gray-300">
            <img
                loading="lazy"
                width="20"
                src={ `https://flagcdn.com/w20/${country.value?.toLowerCase()}.png` }
                srcSet={ `https://flagcdn.com/w40/${country.value?.toLowerCase()}.png 2x` }
                alt=""
            />{" "}
            { country.label }
        </div>
    );

    const customFormatOptionLabel = (option: MappedCountry) => customOptionRenderer(option);

    const handleChange = (option: SingleValue<MappedCountry>) =>
    {
        props.onChange(option);
    };

    return (
        <>
            { _mappedCountries
                ? (
                    <Select
                        styles={ {
                            control: (base, props) => ({
                                ...base,
                                border: "none",
                                boxShadow: props.isFocused ? "none" : "none",
                                minWidth: "7rem"
                            }),
                            menuList: (base, props) => ({
                                ...base,
                                minWidth: "6rem"
                            }),
                            menu: (base, props) => ({
                                ...base,
                                minWidth: "6.5rem"
                            })
                        } }
                        components={ { DropdownIndicator: () => null, IndicatorSeparator: () => null } }
                        formatOptionLabel={ customFormatOptionLabel }
                        options={ _mappedCountries }
                        onChange={ handleChange }
                        value={ props.value }
                        className={ props?.cssClass + "border-0" }
                        placeholder={ <div className={ styles["select-placeholder-text"] }>Country Code</div> }
                    />
                )
                : <></> }
        </>
    );
};
export default DialingSelect;
