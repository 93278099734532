declare global
{
    interface BigInt
    {
        toJSON(): string;
    }
}

function toJSON(this: BigInt)
{
    return this.toString();
}

BigInt.prototype.toJSON = toJSON;

export {};
