import * as UserService from "../services/user/userService";

import { Delegate, Undefinable } from "@artbanx/nexera/system";
import { useCallback } from "react";
import { useAccount } from "wagmi";
import { AssetSale } from "../services/assetsSales/models/assetSale";
import { AssetSaleDetails } from "../services/assetsSales/models/assetSaleDetails";

export enum WalletIdentityStatus
{
    Anonymous = "anonymous",
    NotConnected = "notConnected",
    Seller = "seller",
    Buyer = "buyer"
}

export type WalletIdentityStatusFunction = Delegate<
    [Undefinable<AssetSale | AssetSaleDetails>],
    Undefinable<WalletIdentityStatus>
>;

export function useWalletIdentityStatus(): WalletIdentityStatusFunction
{
    const account = useAccount();
    const user = UserService.getCurrentIdentity();

    return useCallback((assetSale) =>
    {
        if (UserService.isAnonymous(user))
        {
            return WalletIdentityStatus.Anonymous;
        }

        if (!account.isConnected)
        {
            return WalletIdentityStatus.NotConnected;
        }

        if (!assetSale)
        {
            return undefined;
        }

        if (account.address === assetSale.sale.seller)
        {
            return WalletIdentityStatus.Seller;
        }
        else
        {
            return WalletIdentityStatus.Buyer;
        }
    }, [ account, user ]);
}
