import { Delegate, Undefinable } from "@artbanx/nexera/system";
import { useContext } from "react";
import { IdentityVerificationsContext } from "../components/identityVerificationsProvider/IdentityVerificationsProvider";

export enum VerificationStatus
{
    // NOTE: Could be something like pending or so?
    // lets leave enum
    Verified,
    NotVerified
}

export type IdentityVerification = {
    status: VerificationStatus;
    verify: Delegate;
};

export type IdentityVerifications = {
    verificationComplete: boolean;
    email: IdentityVerification;
    kyc: IdentityVerification;
    walletConnection: IdentityVerification;
};

// TODO: Check all places where we need to check these things, and do not allow certain actions on UI
// TODO: Will we have global whitelist for pilot? If no - how we would restrict user regarding email and kyc verifications?

export function useIdentityVerifications(): Undefinable<IdentityVerifications>
{
    const verifications = useContext(IdentityVerificationsContext);

    return verifications;
}
