import { StatusCodes } from "http-status-codes";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useAccount } from "wagmi";
import { font, FontDefinition } from "../../helpers/typography";
import { useNotifications } from "../../hooks/notifications";
import { getCurrentIdentity } from "../../services/user/userService";
import { UserWalletDto, UserWalletsDto } from "../../services/userWallets/dto/userWalletDto";
import { Wallet } from "../../services/userWallets/models/wallet";
import * as userWalletService from "../../services/userWallets/userWalletsService";
import { CreateUserWalletModal } from "./CreateUserWalletModal";
import { OtherUserModal } from "./OtherUserWalletModal";
import css from "./UserWallet.module.css";
import { NotifyUser } from "./UserWalletNotifications";

export function UserWalletModal()
{
    const user = getCurrentIdentity();
    const account = useAccount();
    const notifications = useNotifications();

    let defaultUserWallet = {
        userId: user.id.toString(),
        wallets: []
    } as UserWalletsDto;

    const [ _modalVisible, setModalVisible ] = useState(false);

    const [ _otherUserModal, setOtherUserModal ] = useState(false);

    async function isWalletForOtherUser()
    {
        if (!account || !notifications)
        {
            return false;
        }
        let forOtherUser = await userWalletService.isWalletForOtherUser(user.id.toString(), account.address);
        if (forOtherUser.status !== StatusCodes.OK)
        {
            NotifyUser(notifications, forOtherUser.status, "", "Something went wrong!");
        }
        if (forOtherUser.data === false)
        {
            await isAddressExists();
        }
        else
        {
            setOtherUserModal(true);
        }
    }

    async function isAddressExists()
    {
        let isExist = false;
        if (!account)
        {
            return false;
        }
        isExist = await userWalletService.isWalletExists(user.id, account.address);
        if (!isExist)
        {
            setModalVisible(true);
        }
        else
        {
            setModalVisible(false);
        }
    }

    useEffect(() =>
    {
        if (account.address)
        {
            isWalletForOtherUser();
        }
    }, [ account.address, notifications ]);

    return (
        <>
            <OtherUserModal setVisibility={ setOtherUserModal } visible={ _otherUserModal } />
            <CreateUserWalletModal modalVisible={ _modalVisible } setModalVisible={ setModalVisible } />
        </>
    );
}
