import { Undefinable } from "@artbanx/nexera/system";
import { useEffect, useState } from "react";
import { getConfiguration } from "../../configuration/configuration";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { useNotifications } from "../../hooks/notifications";
import { KycStartFlowPayload } from "../../services/kyc/dto/kycStartFlowPayload";
import { KycUrlDto } from "../../services/kyc/dto/kycUrlDto";
import { getKycUrl } from "../../services/kyc/service";
import { User } from "../../services/user/models/user";
import { getCurrentIdentity } from "../../services/user/userService";
import { getOneUserProfile } from "../../services/userProfile/userProfileService";
import { NotificationQueue, NotificationType } from "../notificationQueue/NotificationQueue";
const KycClientInitiator = () =>
{
    const notificationController = useNotifications();

    const user = getCurrentIdentity();
    const configuration = getConfiguration();
    const [ _user, setUser ] = useState<User>();
    const [ kycStatus, setKycStatus ] = useState(-1);

    useEffect(() =>
    {
        getUserProfile();
    }, []);

    async function getUserProfile()
    {
        let userProfile = await getOneUserProfile();
        if (userProfile)
        {
            setUser(userProfile);
            console.log(_user?.userInfo.nationality);
        }
    }

    async function redirectToUrl(e: any)
    {
        e.preventDefault();

        const requestBody = {
            userId: user.id.toString(),
            email: _user?.identity.email,
            firstName: _user?.userInfo.firstName,
            lastName: _user?.userInfo.lastName,
            dateOfBirth: _user?.userInfo.dateOfBirth,
            nationality: _user?.userInfo.nationality?.value
        } as KycStartFlowPayload;

        let response: Undefinable<KycUrlDto>;
        try
        {
            response = await getKycUrl(requestBody);
        }
        catch
        {
            notificationController.current?.push(
                <NotificationQueue.Notification type={ NotificationType.Error }>
                    <NotificationQueue.Notification.Heading>
                        <span className={ classNames("text-dark", font(FontDefinition.T4)) }>Something went wrong while fetching the KYC Url!</span>
                    </NotificationQueue.Notification.Heading>
                </NotificationQueue.Notification>
            );
        }

        try
        {
            if (response)
            {
                window.location.replace(response.url);
            }
        }
        catch (error)
        {
            console.error("Error fetching data:", error);
        }
    }

    return (
        <div className="card p-4">
            <div className="card-body mb-2">
                <h3 className="w-100  kyc-title mb-4 text-center">
                    KYC
                </h3>
                <p className=" kyc-description text-center">
                    We require verifying your identity and conducting a face recognition scan. <br />
                    This is to ensure that your account and personal information remain protected.
                </p>
            </div>
            <div className="card-footer d-flex justify-content-end align-items-center">
                <button className="btn btn-primary text-white mobile-w-100" onClick={ redirectToUrl }>Start KYC</button>
            </div>
        </div>
    );
};

export default KycClientInitiator;
