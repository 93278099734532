export function OnRamper()
{
    const fiats = "onlyFiats=USD,EUR,CHF";
    const cryptoNetworks = "onlyCryptoNetworks=AVAXC,AVAXX";
    const cryptoCurrencies = "onlyCryptos=AVAX_AVAXC,AVAX_AVAXX,USDC_AVAXC";
    const defaultAmount = "defaultAmount=100";
    return (
        <iframe
            style={ { borderRadius: "4px", border: "1px solid #58585f", margin: "auto", maxWidth: "420px" } }
            src={ `https://buy.onramper.com?apikey=pk_prod_01H8CWX4AN5CET1WV729CD8YPJ&${fiats}&${cryptoCurrencies}&${cryptoNetworks}&${defaultAmount}` }
            height="630px"
            width="420px"
            title="Onramper widget"
            allow="accelerometer; autoplay; camera; gyroscope; payment"
        >
        </iframe>
    );
    // &onlyCryptoNetworks=ETHEREUM,AVALANCHE&defaultCrypto=ETH"
}

export namespace OnRamper
{
    export const route = "/ui/onramp";
}
