import { Nullable } from "@artbanx/nexera/system";
import { createContext, RefObject, useContext } from "react";
import { NotificationQueueController } from "../components/notificationQueue/NotificationQueue";

export const NotificationsContext = createContext<Nullable<RefObject<NotificationQueueController>>>(null);

export function useNotifications()
{
    const controller = useContext(NotificationsContext);

    return controller!;
}
