export interface KycUserStatusResultDto
{
    status: KycStatus;
    userFeedback: KycFeedbackDto[];
}

export interface KycFeedbackDto
{
    checkId: string;
    userFeedback: string[];
}

export enum KycStatus
{
    NO_CHECKS_FOUND = "NO_CHECKS_FOUND",
    CHECK_FAILED = "CHECK_FAILED",
    CHECK_PENDING = "CHECK_PENDING",
    ALL_CHECKS_CLEAR = "ALL_CHECKS_CLEAR",
    CHECK_COMPLETED = "CHECK_COMPLETED"
}
