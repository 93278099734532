import { PropertyType } from "@artbanx/nexera/system";
import { RainbowKitConnectButtonRendererProps } from "./RainbowKitConnectButtonRendererProps";

export type WrongNetworkButtonProps = {
    openChainModal: PropertyType<RainbowKitConnectButtonRendererProps, "openChainModal">;
};

export function WrongNetworkButton(props: WrongNetworkButtonProps)
{
    return (
        <button
            className="btn btn-pill shadow btn-secondary-outline ps-3"
            onClick={ props.openChainModal }
            type="button"
        >
            <span className="status-indicator status-orange status-indicator-animated me-2">
                <span className="status-indicator-circle"></span>
                <span className="status-indicator-circle"></span>
                <span className="status-indicator-circle"></span>
            </span>
            <span>Wrong Network</span>
        </button>
    );
}
