import { Size } from "@artbanx/nexera/system";
import { CSSProperties, ReactNode } from "react";
import { classNames } from "../../helpers/react";

export enum PictureSizeMode
{
    Contain = "contain",
    Cover = "cover"
}

export type PictureProps = {
    src?: string;
    sizeMode?: PictureSizeMode;
    size?: Size;
    className?: string;
    children?: ReactNode;
    style?: CSSProperties;
};

export function Picture(props: PictureProps)
{
    if (!props.src)
    {
        if (props.children)
        {
            return <>{ props.children }</>;
        }

        return (
            <div
                className={ classNames(
                    "d-flex",
                    "justify-content-center",
                    "align-items-center",
                    props.className
                ) }
                style={ {
                    ...props.style,
                    width: props.size?.width ?? "100%",
                    height: props.size?.height ?? "100%",
                    backgroundClip: "border-box",
                    background: "linear-gradient(45deg, rgb(100 147 255) 0%, rgba(0,31,244,1) 100%)"
                } }
            >
                <i className="text-white bi bi-images"></i>
            </div>
        );
    }

    return (
        <div
            className={ classNames(props.className) }
            style={ {
                ...props.style,
                width: props.size?.width ?? "100%",
                height: props.size?.height ?? "100%",
                background: `url(${props.src})`,
                backgroundSize: props.sizeMode ?? "contain",
                backgroundClip: "border-box",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
            } }
        >
        </div>
    );
}
