export const BlackListAlert = () =>
{
    function redirectToExplore(e: any)
    {
        e.preventDefault();
        let url = window.location.origin + "/wallet/#/ui/investments";
        window.location.replace(url);
    }
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="w-100 text-center mobile-text-left">
                    Temporarily Unavailable
                </h3>
            </div>
            <div className="card-body">
                <h5 className="text-dark text-center mobile-text-left">
                    We regret to inform you that our platform is currently unavailable to users in your country due to regulatory requirements. We will inform
                    you as soon as available
                </h5>
            </div>
            <div className="card-footer d-flex justify-content-lg-end justify-content-md-end">
                <button className="btn btn-primary text-white mobile-w-100" onClick={ redirectToExplore }>
                    Close
                </button>
            </div>
        </div>
    );
};
