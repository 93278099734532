import "@rainbow-me/rainbowkit/styles.css";

import "./extensions/function";
import "./extensions/bigInt";
import "./polyfills";

import { createRoot } from "react-dom/client";
import { App } from "./app";
import { getConfiguration } from "./configuration/configuration";
import * as UserService from "./services/user/userService";

async function main()
{
    const isDevelopment = getConfiguration().chainMode === "development";

    const root = createRoot(document.getElementById("root")!);

    if (isDevelopment)
    {
        await UserService.useAuthorizationCache();
    }

    root.render(<App />);
}

main();
