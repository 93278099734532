import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDisconnect } from "wagmi";
import { font, FontDefinition } from "../../helpers/typography";
import css from "./UserWallet.module.css";

export interface OtherUserModalProps
{
    visible: boolean;
    setVisibility: (visible: boolean) => void;
}

export function OtherUserModal(props: OtherUserModalProps)
{
    const disconnect = useDisconnect();

    useEffect(() =>
    {
        if (props.visible)
        {
            disconnect.disconnect();
        }
    }, [ props.visible ]);

    return (
        <Modal show={ props.visible } onHide={ () => props.setVisibility(false) }>
            <Modal.Body>
                <div className="row">
                    <h4 className={ `${font(FontDefinition.T1)}` }>Invalid Wallet</h4>

                    <p className={ `${font(FontDefinition.T3)}` }>
                        Oops! This wallet is not valid !
                    </p>
                    <p className={ `${font(FontDefinition.T3)}` }>
                        If you believe this is a mistake, please contact our support team. Thank you.
                    </p>
                </div>
                <div className="d-flex flex-row-reverse mt-4">
                    <button className={ ` btn border-primary text-primary me-2 ${css.btn}` } onClick={ () => props.setVisibility(false) }>
                        Cancel
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
