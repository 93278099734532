import { SaleStatus } from "@artbanx/nexera/onchain";
import { classNames } from "../../helpers/react";
import { useWalletIdentityStatus, WalletIdentityStatus } from "../../hooks/walletIdentityStatus";
import { AssetSale } from "../../services/assetsSales/models/assetSale";
import { AssetSaleDetails } from "../../services/assetsSales/models/assetSaleDetails";

export type SaleStatusBadgeProps = {
    className?: string;
    assetSale: AssetSaleDetails | AssetSale;
};

export function SaleStatusBadge(props: SaleStatusBadgeProps)
{
    const getIdentityStatus = useWalletIdentityStatus();

    const status = getIdentityStatus(props.assetSale);

    const commonClassNames = classNames(
        "badge",
        "border",
        "text-dark",
        "px-3"
    );

    switch (props.assetSale.sale.saleStatus)
    {
        case SaleStatus.NON_EXISTENT:
        {
            return (
                <span
                    className={ classNames("bg-secondary-lt", "border-secondary", commonClassNames, props.className) }
                >
                    Non existent
                </span>
            );
        }
        case SaleStatus.REQUESTED:
        {
            return (
                <span
                    className={ classNames("bg-secondary-lt", "border-secondary", commonClassNames, props.className) }
                >
                    Draft
                </span>
            );
        }
        case SaleStatus.APPROVED_FOR_FUNDING:
        {
            switch (status)
            {
                case WalletIdentityStatus.Seller:
                {
                    return (
                        <span className={ classNames("bg-info-lt", "border-info", commonClassNames, props.className) }>
                            Open for Investment
                        </span>
                    );
                }
                default:
                {
                    return (
                        <span
                            className={ classNames(
                                "bg-success-lt",
                                "border-success",
                                commonClassNames,
                                props.className
                            ) }
                        >
                            Open for Investment
                        </span>
                    );
                }
            }
        }
        case SaleStatus.REJECTED:
        {
            return (
                <span className={ classNames("bg-danger-lt", "border-danger", commonClassNames, props.className) }>
                    Rejected
                </span>
            );
        }
        case SaleStatus.FUNDED:
        {
            switch (status)
            {
                case WalletIdentityStatus.Seller:
                {
                    return (
                        <span
                            className={ classNames(
                                "bg-success-lt",
                                "border-success",
                                commonClassNames,
                                props.className
                            ) }
                        >
                            Funded
                        </span>
                    );
                }
                default:
                {
                    return (
                        <span className={ classNames("bg-info-lt", "border-info", commonClassNames, props.className) }>
                            Funded
                        </span>
                    );
                }
            }
        }
        case SaleStatus.NOT_FUNDED:
        {
            return (
                <span className={ classNames("bg-warning-lt", "border-warning", commonClassNames, props.className) }>
                    Not funded
                </span>
            );
        }
        case SaleStatus.STARTED:
        {
            switch (status)
            {
                case WalletIdentityStatus.Seller:
                {
                    return (
                        <span
                            className={ classNames(
                                "bg-success-lt",
                                "border-success",
                                commonClassNames,
                                props.className
                            ) }
                        >
                            Open for Buyback
                        </span>
                    );
                }
                default:
                {
                    return (
                        <span className={ classNames("bg-info-lt", "border-info", commonClassNames, props.className) }>
                            Open for Buyback
                        </span>
                    );
                }
            }
        }
        case SaleStatus.MARGIN_CALL:
        {
            switch (status)
            {
                case WalletIdentityStatus.Seller:
                {
                    return (
                        <span
                            className={ classNames(
                                "bg-success-lt",
                                "border-success",
                                commonClassNames,
                                props.className
                            ) }
                        >
                            In margin call
                        </span>
                    );
                }
                default:
                {
                    return (
                        <span className={ classNames("bg-info-lt", "border-info", commonClassNames, props.className) }>
                            In margin call
                        </span>
                    );
                }
            }
        }
        case SaleStatus.SELLOFF_REQUEST:
        {
            return (
                <span className={ classNames("bg-info-lt", "border-info", commonClassNames, props.className) }>
                    Selloff requested
                </span>
            );
        }
        case SaleStatus.CLOSED_BY_BUYBACK:
        {
            return (
                <span className={ classNames("bg-info-lt", "border-info", commonClassNames, props.className) }>
                    Closed by buyback
                </span>
            );
        }
        case SaleStatus.CLOSED_BY_SELLOFF:
        {
            return (
                <span className={ classNames("bg-info-lt", "border-info", commonClassNames, props.className) }>
                    Closed by selloff
                </span>
            );
        }
    }
}
