import { Size } from "@artbanx/nexera/system";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { useWalletIdentityStatus, WalletIdentityStatus } from "../../hooks/walletIdentityStatus";
import { AssetSale } from "../../services/assetsSales/models/assetSale";
import { Condition } from "../condition/Condition";
import { IconPosition, InfoText } from "../infoText/InfoText";
import { Picture, PictureSizeMode } from "../picture/Picture";
import { SaleFundingStatus } from "../saleFundingStatus/SaleFundingStatus";

export type InvestmentImageProps = {
    assetSale: AssetSale;
    imageSize: Size;
    fundingStatusSize: string;
    fundingStatusStrokeWidth: string;
    showFundingStatusCaption?: boolean;
    showFundingStatusInfoText?: boolean;
    fundingCaptionClassName?: string;
    fundingValueClassName?: string;
};

export function InvestmentImage(props: InvestmentImageProps)
{
    const identityStatus = useWalletIdentityStatus();
    const status = identityStatus(props.assetSale);

    switch (status)
    {
        case WalletIdentityStatus.Seller:
        {
            return (
                <Picture
                    src={ props.assetSale?.asset?.artworks?.[0]?.imageUrl }
                    size={ props.imageSize }
                    sizeMode={ PictureSizeMode.Contain }
                />
            );
        }
        default:
        {
            return (
                <div className="d-flex flex-column justify-content-between justify-content-xxl-between align-items-center h-100">
                    <SaleFundingStatus
                        { ...{
                            sale: props.assetSale.sale,
                            radius: `calc(${props.fundingStatusSize} / 2)`,
                            strokeWidth: props.fundingStatusStrokeWidth
                        } }
                    >
                        { (value) => (
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <Condition>
                                    <Condition.If expression={ !!props.showFundingStatusCaption }>
                                        <span className={ classNames(props.fundingCaptionClassName) }>
                                            Funded
                                        </span>
                                    </Condition.If>
                                </Condition>
                                <span className={ classNames(props.fundingValueClassName) }>
                                    { value }%
                                </span>
                            </div>
                        ) }
                    </SaleFundingStatus>
                    <Condition>
                        <Condition.If expression={ !!props.showFundingStatusInfoText }>
                            <InfoText
                                title="Why can't I view the asset?"
                                iconPosition={ IconPosition.End }
                                className={ classNames("mt-auto", font(FontDefinition.Small)) }
                            >
                                <div className="d-flex flex-column">
                                    <span className={ classNames(font(FontDefinition.T6)) }>
                                        No Exposure Policy
                                    </span>
                                    <span className={ classNames(font(FontDefinition.T7)) }>
                                        We maintain asset value by avoiding exposure in the event of a non-executed buy-back.
                                    </span>
                                </div>
                            </InfoText>
                        </Condition.If>
                    </Condition>
                </div>
            );
        }
    }
}
