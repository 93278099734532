import { Dispatch, SetStateAction, useState } from "react";
import { Modal as BootstrapModal } from "react-bootstrap";
import { classNames } from "../../helpers/react";
import { font, FontDefinition } from "../../helpers/typography";
import { useIdentityVerifications } from "../../hooks/identityVerifications";
import { ReactState } from "../../types/reactState";
import { IdentityVerificationsView } from "../identityVerificationsView/IdentityVerificationsView";
import { VerificationStatusView } from "../verificationStatusView/VerificationStatusView";

type IdentityVerificationsModalState = {
    visible: boolean;
};

export interface IdentityVerificationsModalController
{
    show(): void;
    hide(): void;
}

class IdentityVerificationsModalControllerImpl implements IdentityVerificationsModalController
{
    public readonly state: IdentityVerificationsModalState;
    private readonly setState: Dispatch<SetStateAction<IdentityVerificationsModalState>>;

    public constructor(state: ReactState<IdentityVerificationsModalState>)
    {
        [ this.state, this.setState ] = state;
    }

    public show(): void
    {
        this.setState((state) => ({ ...state, visible: true }));
    }

    public hide(): void
    {
        this.setState((state) => ({ ...state, visible: false }));
    }
}

export function useIdentityVerificationsModal(): IdentityVerificationsModalController
{
    const state = useState<IdentityVerificationsModalState>({ visible: false });

    return new IdentityVerificationsModalControllerImpl(state);
}

export type IdentityVerificationsModalProps = {
    controller: IdentityVerificationsModalController;
};

export function IdentityVerificationsModal(props: IdentityVerificationsModalProps)
{
    const controller = props.controller as IdentityVerificationsModalControllerImpl;
    const verifications = useIdentityVerifications();

    function onVerify()
    {
        controller.hide();
    }

    return (
        <BootstrapModal size="lg" centered animation show={ controller.state.visible } onShow={ () => controller.show() } onHide={ () => controller.hide() }>
            <BootstrapModal.Body>
                <div className="row">
                    <div className="col-12 mb-6">
                        <h1 className={ classNames(font(FontDefinition.T1)) }>
                            Please Complete the Steps to Begin
                        </h1>
                        <span className={ classNames(font(FontDefinition.T3)) }>
                            You must complete the steps to verify your account to begin using our services today.
                        </span>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-start">
                                <VerificationStatusView verification={ verifications?.email } onVerify={ onVerify } resolveNowText="Resolve Now">
                                    <VerificationStatusView.Verified>
                                        <span className={ classNames(font(FontDefinition.T2)) }>E-mail validated</span>
                                    </VerificationStatusView.Verified>
                                    <VerificationStatusView.NotVerified>
                                        <span className={ classNames(font(FontDefinition.T2)) }>
                                            E-mail not validated
                                        </span>
                                    </VerificationStatusView.NotVerified>
                                    <VerificationStatusView.Pending>
                                        <span className={ classNames(font(FontDefinition.T2)) }>
                                            E-mail validation status is loading...
                                        </span>
                                    </VerificationStatusView.Pending>
                                </VerificationStatusView>
                            </div>
                            <div className="col-12 d-flex justify-content-start">
                                <VerificationStatusView verification={ verifications?.kyc } onVerify={ onVerify } resolveNowText="Resolve Now">
                                    <VerificationStatusView.Verified>
                                        <span className={ classNames(font(FontDefinition.T2)) }>KYC verified</span>
                                    </VerificationStatusView.Verified>
                                    <VerificationStatusView.NotVerified>
                                        <span className={ classNames(font(FontDefinition.T2)) }>KYC not verified</span>
                                    </VerificationStatusView.NotVerified>
                                    <VerificationStatusView.Pending>
                                        <span className={ classNames(font(FontDefinition.T2)) }>
                                            KYC verification status is loading...
                                        </span>
                                    </VerificationStatusView.Pending>
                                </VerificationStatusView>
                            </div>
                            <div className="col-12 d-flex justify-content-start">
                                <VerificationStatusView verification={ verifications?.walletConnection } onVerify={ onVerify } resolveNowText="Connect Now">
                                    <VerificationStatusView.Verified>
                                        <span className={ classNames(font(FontDefinition.T2)) }>Wallet connected</span>
                                    </VerificationStatusView.Verified>
                                    <VerificationStatusView.NotVerified>
                                        <span className={ classNames(font(FontDefinition.T2)) }>
                                            Wallet not connected
                                        </span>
                                    </VerificationStatusView.NotVerified>
                                    <VerificationStatusView.Pending>
                                        <span className={ classNames(font(FontDefinition.T2)) }>
                                            Wallet connection status is loading...
                                        </span>
                                    </VerificationStatusView.Pending>
                                </VerificationStatusView>
                            </div>
                        </div>
                    </div>
                    <div className="col-9">
                    </div>
                    <div className="col">
                        <button
                            className="btn btn-primary btn-pill w-100"
                            onClick={ () => controller.hide() }
                        >
                            Close
                        </button>
                    </div>
                </div>
            </BootstrapModal.Body>
        </BootstrapModal>
    );
}
