import { pollingFetch } from "@artbanx/nexera/helpers";
import { Onchain } from "@artbanx/nexera/onchain";
import { Topics } from "@artbanx/nexera/subgraph";
import { Nullable, Undefinable } from "@artbanx/nexera/system";
import { BigNumber } from "ethers";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { AssetFilter } from "../services/assets/assetsService";
import * as AssetsSalesService from "../services/assetsSales/assetsSalesService";
import { AssetSale } from "../services/assetsSales/models/assetSale";
import { useOnchain } from "./onchain";
import { useAssetsSalesQuery } from "./queries";
import { useSubgraphTopicsConfigurator } from "./subgraph";

export function useAssetsTracking(
    filter?: Nullable<AssetFilter>
)
{
    const onchain = useOnchain();
    const queryClient = useQueryClient();
    const assetsSalesQuery = useAssetsSalesQuery(filter);

    const topicsConfigurator = useSubgraphTopicsConfigurator();

    async function fetchAssetSaleBySaleId(onchain: Onchain, saleId: BigNumber)
    {
        return pollingFetch(
            async () =>
            {
                try
                {
                    const assetSale = await AssetsSalesService.getDetails(onchain, { saleId: saleId.toNumber() });

                    return assetSale;
                }
                catch
                {
                    // swallow exception, so that we can do polling
                }
            }
        );
    }

    function initialize()
    {
        if (!onchain || !topicsConfigurator || filter === null || !assetsSalesQuery.query.isFetchedAfterMount)
        {
            return;
        }

        const topics = topicsConfigurator
            .addTopic(Topics.SaleRequest)
            .addTopic(Topics.AssetOraclePriceSet)
            .addTopic(Topics.Purchase)
            .addTopic(Topics.SaleStatusUpdate)
            .build();

        const subscriptions = [
            topics.saleRequest.subscribe(
                async (event) =>
                {
                    const isNotValidSeller = !!filter?.sellerId && event.seller !== filter.sellerId;
                    const isNotValidBuyer = !!filter?.buyerId;

                    if (isNotValidBuyer || isNotValidSeller)
                    {
                        return;
                    }

                    const assetSale = await fetchAssetSaleBySaleId(onchain, BigNumber.from(event.saleId));

                    if (!assetSale)
                    {
                        return;
                    }

                    queryClient.setQueryData<Undefinable<AssetSale[]>>(
                        assetsSalesQuery.key,
                        (assetsSales) => [ ...assetsSales ?? [], assetSale ]
                    );
                }
            ),
            topics.assetOraclePriceSet.subscribe(
                (event) =>
                {
                    const assetsSales = queryClient.getQueryData<Undefinable<AssetSale[]>>(assetsSalesQuery.key);

                    if (assetsSales)
                    {
                        // TODO: Re-check nftId vs saleId
                        const assetSale = assetsSales?.find((assetSale) => assetSale.sale.id.eq(event.nftId));

                        if (assetSale)
                        {
                            assetSale.sale.oracleInfo.price = BigNumber.from(event.newPrice);

                            queryClient.setQueryData<Undefinable<AssetSale[]>>(
                                assetsSalesQuery.key,
                                (assetsSales) => assetsSales ? [ ...assetsSales ] : undefined
                            );
                        }
                    }
                }
            ),
            topics.purchase.subscribe(
                (event) =>
                {
                    const assetsSales = queryClient.getQueryData<Undefinable<AssetSale[]>>(assetsSalesQuery.key);

                    if (assetsSales)
                    {
                        const assetSale = assetsSales?.find((assetSale) => assetSale.sale.id.eq(event.saleId));

                        if (assetSale)
                        {
                            assetSale.sale.monetaryInfo.fractionsPurchased = assetSale.sale.monetaryInfo
                                .fractionsPurchased
                                .add(event.fractionsPurchased);

                            queryClient.setQueryData<Undefinable<AssetSale[]>>(
                                assetsSalesQuery.key,
                                (assetsSales) => assetsSales ? [ ...assetsSales ] : undefined
                            );
                        }
                    }
                }
            ),
            topics.saleStatusUpdate.subscribe(
                async (event) =>
                {
                    const assetsSales = queryClient.getQueryData<Undefinable<AssetSale[]>>(assetsSalesQuery.key);

                    if (assetsSales)
                    {
                        const assetSale = assetsSales?.find((assetSale) => assetSale.sale.id.eq(event.saleId));

                        if (assetSale)
                        {
                            const status = Number(event.status);

                            const sale = await onchain.fetchSale(assetSale.sale.id);

                            queryClient.setQueryData<Undefinable<AssetSale[]>>(
                                assetsSalesQuery.key,
                                (assetsSales) =>
                                    assetsSales?.map(
                                        (assetSale) =>
                                            assetSale.sale.id.eq(sale.id)
                                                ? { ...assetSale, sale }
                                                : assetSale
                                    )
                            );
                        }
                    }
                }
            )
        ];

        return () =>
        {
            for (const subscription of subscriptions)
            {
                subscription.dispose();
            }
        };
    }

    useEffect(initialize, [ !!onchain, !!topicsConfigurator, filter, assetsSalesQuery.query.isFetchedAfterMount ]);

    return assetsSalesQuery.query.data;
}
