import { ReactNode, useRef } from "react";
import { Outlet } from "react-router-dom";
import { KycProvider, KycProviderController } from "../components/kycProvider/KycProvider";
import { KycContext } from "../hooks/kyc";

export type KycLayoutProps = {
    children?: ReactNode;
};

export function KycLayout(props: KycLayoutProps)
{
    const ref = useRef<KycProviderController>(null);
    return (
        <KycContext.Provider value={ ref }>
            <KycProvider ref={ ref } />

            { props.children ?? <Outlet /> }
        </KycContext.Provider>
    );
}
