import { TopicsConfigurator } from "@artbanx/nexera/subgraph";
import { useContext, useEffect, useState } from "react";
import { SubgraphContext } from "../components/subgraphProvider/SubgraphProvider";

export function useSubgraphTopicsConfigurator()
{
    const listener = useContext(SubgraphContext);

    const [ configurator, setConfigurator ] = useState<TopicsConfigurator>();

    function initialize()
    {
        if (!listener)
        {
            return;
        }

        setConfigurator(new TopicsConfigurator(listener));
    }

    useEffect(initialize, [ !!listener ]);

    return configurator;
}
