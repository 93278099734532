import * as AssetsService from "../assets/assetsService";

import { Onchain } from "@artbanx/nexera/onchain";
import { BigNumber } from "ethers";
import { AssetDetailsFilter, AssetFilter } from "../assets/assetsService";
import { AssetSale } from "./models/assetSale";
import { AssetSaleBuyback } from "./models/assetSaleBuyback";
import { AssetSaleDetails } from "./models/assetSaleDetails";
import { AssetSaleFundsReceival } from "./models/assetSaleFundsreceival";
import { AssetSalePurchase } from "./models/assetSalePurchase";
import { AssetSaleTransactionEntity } from "./models/assetSaleTransactionEntity";
import { AssetSaleTransactionType } from "./models/assetSaleTransactionType";

export async function get(onchain: Onchain, filter?: AssetFilter): Promise<AssetSale[]>
{
    const assets = await AssetsService.get(filter);

    const promises = assets.map(
        async (asset): Promise<AssetSale> => (
            {
                asset: asset,
                sale: await onchain.fetchSale(
                    BigNumber.from(asset.tokenId)
                )
            }
        )
    );

    return Promise.all(promises);
}

export async function getDetails(
    onchain: Onchain,
    filter: AssetDetailsFilter
): Promise<AssetSaleDetails>
{
    const asset = await AssetsService.getDetails(filter);
    const sale = await onchain.fetchSale(BigNumber.from(asset.tokenId));

    return {
        asset: asset,
        sale: sale
    };
}

export async function getTransactionsHistory(
    onchain: Onchain,
    assetSale: AssetSaleDetails | AssetSale
): Promise<AssetSaleTransactionEntity[]>
{
    const purchases = await onchain.fetchPurchases(assetSale.sale.id);
    const buyBacks = await onchain.fetchBuybacks(assetSale.sale.id);
    const fundsReceivals = await onchain.fetchFundsReceivals(assetSale.sale.id);

    const mappedPurchases = purchases.map(
        (purchase): AssetSalePurchase => (
            {
                type: AssetSaleTransactionType.Purchase,
                value: purchase
            }
        )
    );

    const mappedBuyBacks = buyBacks.map(
        (buyback): AssetSaleBuyback => (
            {
                type: AssetSaleTransactionType.Buyback,
                value: buyback
            }
        )
    );

    const mappedFundsReceivals = fundsReceivals.map(
        (fundsReceival): AssetSaleFundsReceival => (
            {
                type: AssetSaleTransactionType.FundsReceival,
                value: fundsReceival
            }
        )
    );

    return [ ...mappedPurchases, ...mappedBuyBacks, ...mappedFundsReceivals ];
}
