import { useNavigate } from "react-router-dom";
import { FontDefinition } from "../helpers/typography";
import { Explore } from "./Explore";

export function NotFound()
{
    const navigate = useNavigate();
    function redirectToExplore()
    {
        navigate(Explore.route);
    }
    return (
        <div className="d-flex flex-column w-100 h-100 justify-content-center" style={ { minHeight: "100vh", marginTop: "-3rem" } }>
            <h1 className={ FontDefinition.T1 + ` text-center  w-100` }>404</h1>
            <p className={ FontDefinition.T4 + ` text-center mt-2` }>Oh no, the page you were looking for is not available.</p>
            <div className="row w-100 d-flex flex-row justify-content-center mt-2">
                <button
                    className="btn btn-primary text-white"
                    style={ { marginTop: "2rem", width: "10rem", marginLeft: "1rem" } }
                    onClick={ redirectToExplore }
                >
                    Return to Explore
                </button>
            </div>
        </div>
    );
}

export namespace NotFound
{
    export const route: string = "*";
}
