import { StatusCodes } from "http-status-codes";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { font, FontDefinition } from "../../helpers/typography";
import { useNotifications } from "../../hooks/notifications";
import { getCurrentIdentity } from "../../services/user/userService";
import { UserWalletDto } from "../../services/userWallets/dto/userWalletDto";
import { Wallet } from "../../services/userWallets/models/wallet";
import * as userWalletService from "../../services/userWallets/userWalletsService";
import css from "./UserWallet.module.css";
import { UserWalletConfirmation } from "./UserWalletConfirmation";
import { NotifyError, NotifySuccess, NotifyUser } from "./UserWalletNotifications";

export interface UserWalletViewProps
{
    updateList: (shouldUpdate: boolean) => void;
    wallet: Wallet;
}
export function UserWalletView(props: UserWalletViewProps)
{
    const [ _disabledInput, setDisabledInput ] = useState(true);
    const [ _wallet, setWallet ] = useState(props.wallet);
    const notifications = useNotifications();
    const [ _confirmationVisible, setConfirmationVisible ] = useState(false);

    function substringWalletAddress(walletAddress: string)
    {
        let result = walletAddress.substring(0, 7) + "..."
            + walletAddress.substring(
                walletAddress.length - 7,
                walletAddress.length
            );
        return result;
    }
    function randomIntFromInterval(min: number, max: number)
    { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function svg()
    {
        let key = randomIntFromInterval(1, 5);
        if (key % 2 == 0)
        {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path
                        d="M1.12351 13.3705C1.82292 11.3765 2.82738 9.53125 4.0811 7.8869L8.90253 18.4375L3.25149 30.9375C2.33631 29.5461 1.59226 28.0283 1.04911 26.4211C0.368304 24.4048 0 22.247 0 20C0 17.6786 0.398066 15.4464 1.12351 13.3705Z"
                        fill="#6390F8"
                    />
                    <path
                        d="M3.88765 31.8527C7.35119 36.5551 12.8237 39.6801 19.0365 39.9777L28.7351 18.6458L28.7537 18.4375L20.4539 0.00744048L20 0C13.8876 0 8.41518 2.7381 4.74702 7.06101L9.85863 18.244L9.94792 18.4375L9.85863 18.6384L3.88765 31.8527Z"
                        fill="#1457F5"
                    />
                    <path
                        d="M29.7991 18.4375L29.7135 18.6384L35.9338 32.0908C38.4859 28.7351 40 24.5461 40 20C40 14.9777 38.1473 10.3795 35.0893 6.86756L29.7991 18.4375Z"
                        fill="#1457F5"
                    />
                    <path d="M35.2679 32.9241L29.2225 19.7098L20 40C26.1198 40 31.5997 37.2545 35.2679 32.9241Z" fill="#6390F8" />
                    <path
                        d="M34.3899 6.10863L29.282 17.2842L21.5216 0.0595238C23.9397 0.238095 26.2388 0.855655 28.3408 1.82292C30.6213 2.86458 32.6674 4.33036 34.3899 6.10863Z"
                        fill="#0029BD"
                    />
                    <path
                        d="M34.3899 6.10863L29.282 17.2842L21.5216 0.0595238C23.9397 0.238095 26.2388 0.855655 28.3408 1.82292C30.6213 2.86458 32.6674 4.33036 34.3899 6.10863Z"
                        fill="#0029BD"
                    />
                </svg>
            );
        }
        else if (key % 3 == 0)
        {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path
                        d="M40 20C40 29.4568 33.4412 37.381 24.6243 39.4643L20.8259 31.0342L32.7381 4.58333C37.1726 8.25149 40 13.7946 40 20Z"
                        fill="#A7C0FC"
                    />
                    <path
                        d="M31.968 3.97321L20.3051 29.8735L8.48958 3.63839C11.7448 1.34673 15.7143 0 20 0C22.1057 0 24.1369 0.327381 26.0454 0.93006C28.1994 1.61458 30.1972 2.64881 31.968 3.97321Z"
                        fill="#1457F5"
                    />
                    <path
                        d="M21.9085 39.9107C22.5037 39.8586 23.0915 39.7768 23.6682 39.6652L19.8698 31.2277L19.7805 31.0342L7.70833 4.21875C3.01711 7.87946 0 13.5863 0 20C0 22.6935 0.535714 25.2679 1.50298 27.619C2.1317 29.1443 2.94271 30.5804 3.91369 31.8899C4.58333 32.7976 5.32738 33.6458 6.13839 34.4196C6.87128 35.1265 7.65997 35.7738 8.4933 36.3616C9.81027 37.2917 11.2463 38.0655 12.7716 38.6533C15.0149 39.5238 17.4516 40 20 40C20.6436 40 21.2798 39.9702 21.9085 39.9107Z"
                        fill="#6390F8"
                    />
                </svg>
            );
        }
        else
        {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path
                        d="M26.5273 1.08594C27.6289 1.46875 28.6875 1.94531 29.6914 2.5L13.1211 38.7891C5.46484 35.9844 0 28.6328 0 20C0 8.95312 8.95312 0 20 0C22.2852 0 24.4805 0.382812 26.5273 1.08594Z"
                        fill="#1457F5"
                    />
                    <path
                        d="M40 20C40 12.8281 36.2266 6.53906 30.5547 3.00781L14.0703 39.1094C15.9453 39.6875 17.9336 40 20 40C31.0469 40 40 31.0469 40 20Z"
                        fill="#1457F5"
                    />
                    <path
                        d="M40 20C40 12.8281 36.2266 6.53906 30.5547 3.00781L14.0703 39.1094C15.9453 39.6875 17.9336 40 20 40C31.0469 40 40 31.0469 40 20Z"
                        fill="#6390F8"
                    />
                </svg>
            );
        }
    }

    async function updateWalletTitle()
    {
        let userWalletDto = { userId: getCurrentIdentity().id.toString(), wallet: _wallet } as UserWalletDto;
        let status = await userWalletService.addUserWallet(userWalletDto);
        NotifyUser(notifications, status, "Wallet Title Updated!", "Something went wrong while saving");
        setDisabledInput(true);
    }

    function actionButton()
    {
        if (_disabledInput)
        {
            return (
                <button
                    className={ `btn text-primary border-primary ${css.btn}` }
                    onClick={ showConfirmationModal }
                    style={ { marginLeft: "1rem", marginRight: "2.5rem" } }
                >
                    <span style={ { marginRight: "0.4rem" } }>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                        </svg>
                    </span>
                    Delete
                </button>
            );
        }
        return (
            <button
                className={ `btn btn-primary ${css.btn}` }
                onClick={ async () => await updateWalletTitle() }
                style={ { marginLeft: "1rem", marginRight: "2.5rem" } }
            >
                Save
            </button>
        );
    }

    function actionButtonPhone()
    {
        if (_disabledInput)
        {
            return (
                <button className={ `w-100 btn text-primary border-primary ${css.btn}` } onClick={ showConfirmationModal } style={ { marginBottom: "1rem" } }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                    </svg>
                    <div style={ { paddingLeft: "10px" } }>Delete</div>
                </button>
            );
        }
        return (
            <>
                <button className={ `w-100 btn btn-primary ${css.btn}` } onClick={ async () => await updateWalletTitle() } style={ { marginBottom: "1rem" } }>
                    Save
                </button>
            </>
        );
    }

    function tooltipCopy()
    {
        return (
            <OverlayTrigger
                placement={ "top" }
                delay={ { show: 100, hide: 400 } }
                overlay={ 
                    <Tooltip>
                        copy
                    </Tooltip>
                 }
            >
                <span className={ `input-group-text` } title="copy" id="wallet-address" onClick={ copyWallet }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                        <path
                            fill-rule="evenodd"
                            d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                        />
                    </svg>
                </span>
            </OverlayTrigger>
        );
    }

    function tooltipEdit()
    {
        return (
            <OverlayTrigger
                placement={ "top" }
                delay={ { show: 100, hide: 400 } }
                overlay={ 
                    <Tooltip>
                        { _disabledInput ? "edit" : "close" }
                    </Tooltip>
                 }
            >
                { _disabledInput
                    ? (
                        <div className={ `input-group-text` } onClick={ () => setDisabledInput(false) } title="edit">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                            >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                            </svg>
                        </div>
                    )
                    : (
                        <div className={ `input-group-text` } onClick={ () => setDisabledInput(true) } title="close">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                            >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                            </svg>
                        </div>
                    ) }
            </OverlayTrigger>
        );
    }

    function copyWallet()
    {
        navigator.clipboard.writeText(_wallet.walletAddress);
    }

    async function showConfirmationModal()
    {
        setConfirmationVisible(true);
    }

    return (
        <>
            <div className="d-none d-md-block">
                <div className="card p-2 mt-2 d-flex flex-row justify-content-between">
                    <div className="d-flex flex-column justify-content-center me-2" style={ { paddingLeft: "2.5rem" } }>
                        { svg() }
                    </div>
                    <div>
                        <label htmlFor="wallet-title" className={ `${font(FontDefinition.T7)} text-muted` }>Wallet Title</label>
                        <div className="input-group mb-3 me-2">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Title"
                                aria-label="title"
                                aria-describedby="wallet-title"
                                disabled={ _disabledInput }
                                value={ _wallet.title }
                                onChange={ (e) =>
                                    setWallet((old) =>
                                    {
                                        return { ...old, title: e.target.value };
                                    }) }
                            />
                            { tooltipEdit() }
                        </div>
                    </div>

                    <div>
                        <label htmlFor="wallet-title" className={ `${font(FontDefinition.T7)} text-muted` }>Wallet Address</label>
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Wallet address"
                                aria-label="wallet-address"
                                aria-describedby="wallet-address"
                                disabled={ true }
                                value={ substringWalletAddress(props.wallet.walletAddress) }
                            />
                            { tooltipCopy() }
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                        { actionButton() }
                    </div>
                </div>
            </div>

            <div className="d-block d-md-none">
                <div className="card mt-2">
                    <div className="row">
                        <div className="col-3 d-flex flex-column justify-content-center align-items-center" style={ { marginTop: "-5rem" } }>
                            { svg() }
                        </div>
                        <div className="col-9 p-3">
                            <div className="row" style={ { paddingRight: "2rem" } }>
                                <div className="col-12">
                                    <label htmlFor="wallet-title" className={ `${font(FontDefinition.T7)} text-muted` }>Wallet Title</label>
                                    <div className="input-group mb-3 me-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Title"
                                            aria-label="title"
                                            aria-describedby="wallet-title"
                                            disabled={ _disabledInput }
                                            value={ _wallet.title }
                                            onChange={ (e) =>
                                                setWallet((old) =>
                                                {
                                                    return { ...old, title: e.target.value };
                                                }) }
                                        />
                                        { tooltipEdit() }
                                    </div>
                                </div>

                                <div className="col-12">
                                    <label htmlFor="wallet-title" className={ `${font(FontDefinition.T7)} text-muted` }>Wallet Address</label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Wallet address"
                                            aria-label="wallet-address"
                                            aria-describedby="wallet-address"
                                            disabled={ true }
                                            value={ substringWalletAddress(props.wallet.walletAddress) }
                                        />
                                        { tooltipCopy() }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ps-3 pe-3 pb-3">
                        { actionButtonPhone() }
                    </div>
                </div>
            </div>
            <UserWalletConfirmation
                setModalVisible={ setConfirmationVisible }
                show={ _confirmationVisible }
                wallet={ _wallet }
                updateList={ props.updateList }
            />
        </>
    );
}
