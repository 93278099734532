import { SaleStatus } from "@artbanx/nexera/onchain";
import { Delegate, Undefinable } from "@artbanx/nexera/system";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useIdentityVerifications } from "../../hooks/identityVerifications";
import { useOnchain } from "../../hooks/onchain";
import { AvailableAction, useBuyerQuery } from "../../hooks/queries";
import { useWallet } from "../../hooks/wallet";
import { useWalletIdentityStatus, WalletIdentityStatus } from "../../hooks/walletIdentityStatus";
import { BuyBack } from "../../pages/BuyBack";
import { ClaimFunds } from "../../pages/ClaimFunds";
import { Invest } from "../../pages/Invest";
import { AssetSale } from "../../services/assetsSales/models/assetSale";
import { AssetSaleDetails } from "../../services/assetsSales/models/assetSaleDetails";
import { getActivePeriodEndDate } from "../saleStatusView/SaleStatusView";

export enum NoActionAvailableReason
{
    AssetStatus,
    IdentityVerifications,
    Authorization,
    ExpiredFunding,
    ExpiredBuyback,
    NoMoreToPurchase,
    NoOwnedFractions,
    ClaimFundsNotReady,
    Pending
}

export type AssetActionButtonProps = {
    assetSale: Undefinable<AssetSale | AssetSaleDetails>;
    className?: string;
    children?: Delegate<[NoActionAvailableReason], ReactNode>;
};

function useAction(assetSale: Undefinable<AssetSale | AssetSaleDetails>)
{
    const wallet = useWallet();
    const getIdentityStatus = useWalletIdentityStatus();
    const buyer = useBuyerQuery(assetSale?.sale);
    const [ timer, setTimer ] = useState<NodeJS.Timeout>();

    function checkExpiry()
    {
        setTimer(
            (timer) =>
            {
                const endDate = getActivePeriodEndDate(assetSale?.sale);

                if (endDate && moment().isAfter(endDate))
                {
                    buyer.query.refetch();

                    clearInterval(timer);

                    return undefined;
                }

                return timer;
            }
        );
    }

    useEffect(
        () =>
        {
            const endDate = getActivePeriodEndDate(assetSale?.sale);

            if (!endDate)
            {
                return;
            }

            setTimer(() => setInterval(checkExpiry, 1000));

            return () => clearInterval(timer);
        },
        []
    );

    switch (getIdentityStatus(assetSale))
    {
        case WalletIdentityStatus.Anonymous:
            return AvailableAction.NoActionAuthorization;
        case WalletIdentityStatus.NotConnected:
            return AvailableAction.NoActionWalletConnection;
        case WalletIdentityStatus.Buyer:
        {
            switch (assetSale?.sale.saleStatus)
            {
                case SaleStatus.APPROVED_FOR_FUNDING:
                case SaleStatus.FUNDED:
                {
                    const fundingEndTime = moment
                        .unix(assetSale.sale.startingTimestamp.toNumber());

                    if (moment().isAfter(fundingEndTime))
                    {
                        return AvailableAction.NoActionExpiredFunding;
                    }

                    if (
                        assetSale.sale.monetaryInfo.fractionsPurchased
                            .gte(assetSale.sale.monetaryInfo.maxAmountOfFractions)
                    )
                    {
                        return AvailableAction.NoActionNoMoreToPurchase;
                    }

                    return AvailableAction.Invest;
                }
                case SaleStatus.CLOSED_BY_BUYBACK:
                case SaleStatus.CLOSED_BY_SELLOFF:
                {
                    if (!wallet?.account.address || !buyer.query.data)
                    {
                        return AvailableAction.NoActionWalletConnection;
                    }

                    if (buyer.query.data.fractionsBalance.lte(0))
                    {
                        return AvailableAction.NoAtionNoOwnedFractions;
                    }

                    const buybackEndTime = moment.unix(assetSale.sale.endingTimestamp.toNumber());

                    if (moment().isBefore(buybackEndTime))
                    {
                        return AvailableAction.NoActionClaimFundsNotReady;
                    }

                    return AvailableAction.ClaimFunds;
                }
                default:
                    return AvailableAction.NoActionSaleStatus;
            }
        }
        case WalletIdentityStatus.Seller:
        {
            switch (assetSale?.sale.saleStatus)
            {
                case SaleStatus.STARTED:
                case SaleStatus.MARGIN_CALL:
                {
                    const buybackEndTime = moment.unix(assetSale.sale.endingTimestamp.toNumber());

                    if (moment().isAfter(buybackEndTime))
                    {
                        return AvailableAction.NoActionExpiredBuyback;
                    }

                    return AvailableAction.Buyback;
                }
                default:
                    return AvailableAction.NoActionSaleStatus;
            }
        }
    }
}

export function AssetActionButton(props: AssetActionButtonProps)
{
    const navigate = useNavigate();
    const action = useAction(props.assetSale);

    const className = props.className ?? "btn btn-primary btn-pill w-100";
    const identityVerifications = useIdentityVerifications();

    if (!identityVerifications)
    {
        return <>{ props?.children?.(NoActionAvailableReason.Pending) }</>;
    }

    if (!identityVerifications.verificationComplete)
    {
        return <>{ props?.children?.(NoActionAvailableReason.IdentityVerifications) }</>;
    }

    switch (action)
    {
        case undefined:
            return <>{ props?.children?.(NoActionAvailableReason.Pending) }</>;
        case AvailableAction.NoActionClaimFundsNotReady:
            return <>{ props?.children?.(NoActionAvailableReason.ClaimFundsNotReady) }</>;
        case AvailableAction.NoActionExpiredFunding:
            return <>{ props?.children?.(NoActionAvailableReason.ExpiredFunding) }</>;
        case AvailableAction.NoActionExpiredBuyback:
            return <>{ props?.children?.(NoActionAvailableReason.ExpiredBuyback) }</>;
        case AvailableAction.NoActionNoMoreToPurchase:
            return <>{ props?.children?.(NoActionAvailableReason.NoMoreToPurchase) }</>;
        case AvailableAction.NoAtionNoOwnedFractions:
            return <>{ props?.children?.(NoActionAvailableReason.NoOwnedFractions) }</>;
        case AvailableAction.NoActionAuthorization:
            return <>{ props?.children?.(NoActionAvailableReason.Authorization) }</>;
        case AvailableAction.NoActionWalletConnection:
            return <>{ props?.children?.(NoActionAvailableReason.IdentityVerifications) }</>;
        case AvailableAction.Invest:
        {
            return (
                <button
                    onClick={ () =>
                        navigate(
                            generatePath(Invest.route, {
                                assetId: props.assetSale?.asset.assetId ?? null
                            })
                        ) }
                    className={ className }
                >
                    Invest
                </button>
            );
        }
        case AvailableAction.ClaimFunds:
        {
            return (
                <button
                    onClick={ () =>
                        navigate(
                            generatePath(ClaimFunds.route, {
                                assetId: props.assetSale?.asset.assetId ?? null
                            })
                        ) }
                    className={ className }
                >
                    Claim funds
                </button>
            );
        }
        case AvailableAction.Buyback:
        {
            return (
                <button
                    onClick={ () =>
                        navigate(
                            generatePath(BuyBack.route, {
                                assetId: props.assetSale?.asset.assetId ?? null
                            })
                        ) }
                    className={ className }
                >
                    Buy-Back
                </button>
            );
        }
        case AvailableAction.NoActionSaleStatus:
            return <>{ props?.children?.(NoActionAvailableReason.AssetStatus) }</>;
    }
}
