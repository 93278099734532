import { PropertyType } from "@artbanx/nexera/system";
import { RainbowKitConnectButtonRendererProps } from "./RainbowKitConnectButtonRendererProps";

export type ConnectWalletButtonProps = {
    openConnectModal: PropertyType<RainbowKitConnectButtonRendererProps, "openConnectModal">;
};

export function ConnectWalletButton(props: ConnectWalletButtonProps)
{
    return (
        <button
            className="btn btn-pill shadow btn-secondary-outline ps-3"
            onClick={ props.openConnectModal }
            type="button"
        >
            <span className="status-indicator status-red status-indicator-animated me-2">
                <span className="status-indicator-circle"></span>
                <span className="status-indicator-circle"></span>
                <span className="status-indicator-circle"></span>
            </span>
            <span>No Wallet Connected</span>
        </button>
    );
}
