import { formatUnitsScale } from "@artbanx/nexera/helpers";
import { Delegate, Undefinable } from "@artbanx/nexera/system";
import { Dispatch, ForwardedRef, forwardRef, SetStateAction, useEffect, useImperativeHandle, useState } from "react";
import { Modal as BootstrapModal } from "react-bootstrap";
import { classNames } from "../helpers/react";
import { font, FontDefinition } from "../helpers/typography";
import { useOnchain } from "../hooks/onchain";
import { Balance, useBalances } from "../hooks/queries";
import { useWallet } from "../hooks/wallet";
import { IconPosition, InfoText } from "./infoText/InfoText";
import { Loader } from "./loader/Loader";

export interface WalletConnectedModalProps
{
    visible?: boolean;
    userCanHide?: boolean;
    onHide?: Delegate<[], void>;
}

export class WalletConnectedModalController
{
    public constructor(
        private readonly visibility: Dispatch<SetStateAction<Undefinable<boolean>>>,
        private readonly userCanHide: Dispatch<SetStateAction<Undefinable<boolean>>>,
        private readonly onHide: Dispatch<SetStateAction<Undefinable<Delegate<[], void>>>>
    )
    {
    }

    public setOnHide(value: Undefinable<Delegate<[], void>>): this
    {
        this.onHide(value);

        return this;
    }

    public setVisible(value: Undefinable<boolean>): this
    {
        this.visibility(value);

        return this;
    }

    public setUserCanHide(value: Undefinable<boolean>): this
    {
        this.userCanHide(value);

        return this;
    }
}

function WalletConnectedModalComponent(
    props: WalletConnectedModalProps,
    ref: ForwardedRef<WalletConnectedModalController>
)
{
    const [ visible, setVisible ] = useState(props.visible);
    const [ userCanHide, setUserCanHide ] = useState(props.userCanHide);
    const [ onHide, setOnHide ] = useState<Undefinable<Delegate<[], void>>>(props.onHide);
    const balances = useBalances();

    const onchain = useOnchain();
    const wallet = useWallet();

    function onHideHandler()
    {
        if (userCanHide)
        {
            setVisible(false);
            onHide?.();
        }
    }

    useImperativeHandle(
        ref,
        () =>
            new WalletConnectedModalController(
                setVisible,
                setUserCanHide,
                setOnHide
            ),
        []
    );

    function* generateBalances(balances: Balance[])
    {
        for (const balance of balances)
        {
            yield (
                <div className="col-12 d-flex">
                    <span className={ classNames(font(FontDefinition.T5)) }>
                        { formatUnitsScale(balance.value, balance.decimals) }
                    </span>
                    &nbsp;
                    <InfoText iconPosition={ IconPosition.End } className={ classNames(font(FontDefinition.T4)) } title={ balance.symbol }>
                        { balance.name ?? "Native" }
                    </InfoText>
                </div>
            );
        }
    }

    useEffect(() =>
    {
        if (visible)
        {
            balances.query.refetch();
        }
    }, [ visible ]);

    return (
        <BootstrapModal style={ { zIndex: 800001 } } size="xl" centered animation show={ visible } onHide={ onHideHandler }>
            <BootstrapModal.Body>
                <Loader dependencies={ { wallet, onchain, balances: balances.query.data } }>
                    { (dependencies) => (
                        <div className="row">
                            <div className="col-12 mb-6">
                                <div className="row">
                                    <div className="mb-4 d-lg-none d-block col-12 col-lg-6 d-flex justify-content-end align-items-start">
                                        <div className="d-flex align-items-center">
                                            <span className="status-indicator status-green status-indicator-animated me-2">
                                                <span className="status-indicator-circle"></span>
                                                <span className="status-indicator-circle"></span>
                                                <span className="status-indicator-circle"></span>
                                            </span>
                                            <span className={ classNames(font(FontDefinition.T4)) }>Wallet Connected</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <h2 className={ classNames(font(FontDefinition.T1)) }>
                                            Connect Wallet
                                        </h2>
                                        <span className={ classNames(font(FontDefinition.T3)) }>
                                            You are currently connected to your wallet.
                                        </span>
                                    </div>
                                    <div className="d-none d-lg-block col-12 col-lg-6 d-flex justify-content-end align-items-start">
                                        <div className="d-flex align-items-center">
                                            <span className="status-indicator status-green status-indicator-animated me-2">
                                                <span className="status-indicator-circle"></span>
                                                <span className="status-indicator-circle"></span>
                                                <span className="status-indicator-circle"></span>
                                            </span>
                                            <span className={ classNames(font(FontDefinition.T4)) }>Wallet Connected</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 bg-primary-lt text-dark rounded py-5 px-3">
                                <div className="row">
                                    <div className="col-12 col-lg-6 pe-3 mb-4 mb-lg-0">
                                        <div className="row g-4">
                                            <div className="col-12">
                                                <input className="form-control" readOnly value={ dependencies.wallet.account.address } />
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control" readOnly value={ dependencies.wallet.network.chain?.name } />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 pe-3">
                                        <div className="border rounded w-100 h-100 p-2">
                                            <div className="row">
                                                <div className="col-12">
                                                    <span className={ classNames(font(FontDefinition.T4)) }>
                                                        Balance
                                                    </span>
                                                </div>

                                                { [ ...generateBalances(balances.query.data ?? []) ] }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mt-6">
                                <div className="row">
                                    <div className="d-none col-lg-8 d-lg-block">
                                    </div>
                                    <div className="col-6 col-lg-2 d-flex justify-content-end">
                                        <button
                                            className={ classNames("btn", "btn-outline-primary", "btn-pill", "w-100") }
                                            onClick={ () =>
                                            {
                                                setVisible(false);
                                                dependencies.wallet.disconnect.disconnect();
                                            } }
                                        >
                                            Disconnect
                                        </button>
                                    </div>
                                    <div className="col-6 col-lg-2 d-flex justify-content-end">
                                        <button
                                            className={ classNames("btn", "btn-primary", "btn-pill", "w-100") }
                                            onClick={ () => setVisible(false) }
                                        >
                                            Finish
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) }
                </Loader>
            </BootstrapModal.Body>
        </BootstrapModal>
    );
}

export const WalletConnectedModal = forwardRef(WalletConnectedModalComponent);
