import { ReactNode, useRef } from "react";
import { Outlet } from "react-router-dom";
import { ProgressModal, ProgressModalControllerImpl } from "../components/progressModal/ProgressModal";
import { ProgressModalContext } from "../hooks/progressModal";

export type ProgressModalLayoutProps = {
    children?: ReactNode;
};

export function ProgressModalLayout(props: ProgressModalLayoutProps)
{
    const ref = useRef<ProgressModalControllerImpl>(null);

    return (
        <ProgressModalContext.Provider value={ ref }>
            { props.children ?? <Outlet /> }
            <ProgressModal ref={ ref } />
        </ProgressModalContext.Provider>
    );
}
