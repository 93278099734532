import { Onchain, SaleStatus } from "@artbanx/nexera/onchain";
import { Nullable } from "@artbanx/nexera/system";
import { useEffect, useState } from "react";
import { AssetsList } from "../components/assetsList/AssetsList";
import { KycStatus } from "../components/kycProvider/dto/KycUserStatusResultDto";
import { Loader } from "../components/loader/Loader";
import { classNames } from "../helpers/react";
import { font, FontDefinition } from "../helpers/typography";
import { useAssetsTracking } from "../hooks/assetsTracking";
import { useOnchain } from "../hooks/onchain";
import { useWallet } from "../hooks/wallet";
import { AssetFilter } from "../services/assets/assetsService";
import { AssetSale } from "../services/assetsSales/models/assetSale";
import { getKycUserStatus } from "../services/kyc/service";
import { User } from "../services/user/models/user";
import { getOneUserProfile } from "../services/userProfile/userProfileService";

type PortfoiloBodyProps = {
    onchain: Onchain;
    investedAssets: AssetSale[];
    financedAssets: AssetSale[];
};

function PortfolioBody(props: PortfoiloBodyProps)
{
    const [ _user, setUser ] = useState<User>();
    const [ kycStatusResult, setKycStatusResult ] = useState<string>();
    const [ image, setImage ] = useState("");

    useEffect(() =>
    {
        getUserProfile();
        getUserStatus();
    }, []);

    async function getUserProfile()
    {
        let userProfile = await getOneUserProfile();
        if (userProfile)
        {
            setUser(userProfile);
        }

        console.log("USER AVATAR: " + _user?.identity?.avatar);
    }

    async function getUserStatus()
    {
        const kycUserStatus = await getKycUserStatus();

        if (kycUserStatus.status === KycStatus.NO_CHECKS_FOUND || kycUserStatus.status === KycStatus.CHECK_FAILED)
        {
            setImage("https://www.arkefi.com/assets/warning.svg");
            setKycStatusResult("Not Verified");
        }
        else if (kycUserStatus.status === KycStatus.ALL_CHECKS_CLEAR)
        {
            setImage("https://www.arkefi.com/assets/green.svg");
            setKycStatusResult("Verified");
        }
    }

    return (
        <>
            <div className="mb-5" style={ { width: "101%", position: "absolute", left: 0, top: 0 } }>
                <div
                    className="text-white d-flex flex-column align-items-start justify-content-center p-5"
                    style={ { height: "22.5rem", overflow: "hidden", backgroundImage: `url("https://www.arkefi.com/assets/gradient.svg")` } }
                >
                </div>
            </div>

            <div className="col-12" style={ { paddingTop: "13.5rem", paddingBottom: "4rem" } }>
                <div className="card">
                    <div className="card-body py-4" style={ { height: "10.2rem" } }>
                        <div className="d-flex align-items-center justify-content-center h-100" style={ { paddingBottom: "1.75rem" } }>
                            <img
                                src={ _user?.identity?.avatar
                                    ? _user?.identity?.avatar
                                    : "https://www.arkefi.com/assets/subtract.svg" }
                                alt="User Profile"
                                style={ {
                                    width: "150px",
                                    height: "150px",
                                    position: "absolute",
                                    top: "-60%",
                                    left: "50%",
                                    border: "5px solid white",
                                    backgroundColor: "white",
                                    transform: "translateX(-50%)",
                                    boxShadow: "3px 3px 8px rgba(128, 128, 128, 0.5)"
                                } }
                            />
                            <div style={ { paddingTop: "4.4rem" } }>
                                <div style={ { display: "inline-block", marginRight: "20px" } }>
                                    <h1 className="text-center">
                                        { _user?.userInfo.firstName } { _user?.userInfo.lastName }
                                    </h1>
                                </div>
                                <div style={ { display: "inline-block", position: "relative", top: "-4px" } }>
                                    <h5 style={ { marginTop: "-50px" } }>
                                        { image ? <img src={ image } alt="User Status" /> : ("") }
                                        &nbsp;&nbsp; <b>{ kycStatusResult }</b>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row g-4">
                <div className="col-12">
                    <span className={ classNames(font(FontDefinition.T1)) }>My Invested Assets</span>
                </div>

                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row g-4">
                                <div className="col-6 col-xxl-3 d-flex flex-column align-items-left">
                                    { /*TODO: How should we calculate this one? */ }
                                    <span className={ classNames(font(FontDefinition.T7)) }>
                                        Total Return on Investment
                                    </span>
                                    <span className={ classNames(font(FontDefinition.T1)) }>
                                        { "-" }
                                    </span>
                                </div>
                                <div className="col-6 col-xxl-3 d-flex flex-column align-items-left">
                                    <span className={ classNames(font(FontDefinition.T7)) }>
                                        Total Assets
                                    </span>
                                    <span className={ classNames(font(FontDefinition.T1)) }>
                                        { props.investedAssets.length }
                                    </span>
                                </div>
                                <div className="col-6 col-xxl-3 d-flex flex-column align-items-left">
                                    <span className={ classNames(font(FontDefinition.T7)) }>
                                        Buy-Back
                                    </span>
                                    <span className={ classNames(font(FontDefinition.T1)) }>
                                        { props.investedAssets
                                            .filter((assetSale) => assetSale.sale.saleStatus === SaleStatus.STARTED)
                                            .length }
                                    </span>
                                </div>
                                <div className="col-6 col-xxl-3 d-flex flex-column align-items-left">
                                    <span className={ classNames(font(FontDefinition.T7)) }>
                                        No Buy-Back
                                    </span>
                                    <span className={ classNames(font(FontDefinition.T1)) }>
                                        { props.investedAssets
                                            .filter((assetSale) => assetSale.sale.saleStatus !== SaleStatus.STARTED)
                                            .length }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AssetsList assetsSales={ props.investedAssets } />
            </div>

            <div className="row g-4">
                <div className="col-12 mt-7">
                    <span className={ classNames(font(FontDefinition.T1)) }>My Financed Assets</span>
                </div>

                <div className="col-12">
                    <div className="card">
                        <div className="card-body pt-4 pb-5">
                            <div className="row g-4">
                                <div className="col-6 col-xxl-3 d-flex flex-column align-items-left">
                                    <span className={ classNames(font(FontDefinition.T7)) }>
                                        Total Liquidated Assets
                                    </span>
                                    <span className={ classNames(font(FontDefinition.T1)) }>
                                        { "-" }
                                    </span>
                                </div>
                                <div className="col-6 col-xxl-3 d-flex flex-column align-items-left">
                                    <span className={ classNames(font(FontDefinition.T7)) }>
                                        Total Assets
                                    </span>
                                    <span className={ classNames(font(FontDefinition.T1)) }>
                                        { props.financedAssets.length }
                                    </span>
                                </div>
                                <div className="col-6 col-xxl-3 d-flex flex-column align-items-left">
                                    <span className={ classNames(font(FontDefinition.T7)) }>
                                        Buy-Back
                                    </span>
                                    <span className={ classNames(font(FontDefinition.T1)) }>
                                        { props.financedAssets
                                            .filter((assetSale) => assetSale.sale.saleStatus === SaleStatus.STARTED)
                                            .length }
                                    </span>
                                </div>
                                <div className="col-6 col-xxl-3 d-flex flex-column align-items-left">
                                    <span className={ classNames(font(FontDefinition.T7)) }>
                                        No Buy-Back
                                    </span>
                                    <span className={ classNames(font(FontDefinition.T1)) }>
                                        { props.financedAssets
                                            .filter((assetSale) => assetSale.sale.saleStatus !== SaleStatus.STARTED)
                                            .length }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AssetsList assetsSales={ props.financedAssets } />
            </div>
        </>
    );
}

type AssetsFilters = {
    financed: Nullable<AssetFilter>;
    invested: Nullable<AssetFilter>;
};

export function Portfolio()
{
    const onchain = useOnchain();
    const wallet = useWallet();

    const [ filters, setFilters ] = useState<AssetsFilters>({ financed: null, invested: null });

    function fetchFilters()
    {
        if (!onchain)
        {
            return;
        }

        if (!wallet?.account.address)
        {
            setFilters({ financed: null, invested: null });
        }
        else
        {
            setFilters(
                {
                    financed: { sellerId: wallet.account.address },
                    invested: { buyerId: wallet.account.address }
                }
            );
        }
    }

    const investedAssets = useAssetsTracking(filters?.invested);
    const financedAssets = useAssetsTracking(filters?.financed);

    useEffect(fetchFilters, [ !!onchain, wallet?.account.address ]);

    return (
        <>
            <Loader dependencies={ { onchain, investedAssets, financedAssets } }>
                { (dependencies) => <PortfolioBody { ...dependencies } /> }
            </Loader>
        </>
    );
}

export namespace Portfolio
{
    export const route = "/ui/portfolio";
}
