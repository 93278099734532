import { Emptyable, Undefinable } from "@artbanx/nexera/system";
import { useRef, useState } from "react";
import { AssetsList } from "../components/assetsList/AssetsList";
import { CollectionController } from "../components/collection/Collection";
import { Loader } from "../components/loader/Loader";
import { classNames } from "../helpers/react";
import { font, FontDefinition } from "../helpers/typography";
import { useAssetsTracking } from "../hooks/assetsTracking";
import { AssetFilter } from "../services/assets/assetsService";
import { AssetSale } from "../services/assetsSales/models/assetSale";

type ExploreBodyProps = {
    assetsSales: Undefinable<AssetSale[]>;
};

function ExploreBody({ assetsSales }: ExploreBodyProps)
{
    const ref = useRef<CollectionController<AssetSale>>(null);

    return (
        <div className="row">
            <div className="col-12 my-4">
                <div className="row h-100">
                    <div className={ (assetsSales?.length ?? 0) > 0 ? "col-6" : "col-12" }>
                        <span className={ classNames(font(FontDefinition.T1)) }>Latest Offers</span>
                    </div>
                </div>
            </div>

            <AssetsList assetsSales={ assetsSales } ref={ ref } />
        </div>
    );
}

export function Explore()
{
    const [ filter ] = useState<Emptyable<AssetFilter>>(undefined);
    const assetsSales = useAssetsTracking(filter);

    return (
        <>
            <Loader dependencies={ { assetsSales } }>
                { ({ assetsSales }) => <ExploreBody { ...{ assetsSales } } /> }
            </Loader>
        </>
    );
}

export namespace Explore
{
    export const route = "/ui/investments";
}
