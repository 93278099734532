declare global
{
    interface Function
    {
        asVoid<
            TThis extends (this: TThis, ...args: TArguments) => TResult,
            TArguments extends any[],
            TResult
        >(this: TThis): (...args: TArguments) => void;

        asAsyncVoid<
            TThis extends (this: TThis, ...args: TArguments) => TResult,
            TArguments extends any[],
            TResult
        >(this: TThis): (...args: TArguments) => Promise<void>;
    }
}

function asVoid<
    TThis extends (this: TThis, ...args: TArguments) => TResult,
    TArguments extends any[],
    TResult
>(this: TThis): (...args: TArguments) => void
{
    const self = this;

    return (...args: TArguments) =>
    {
        self.call(self, ...args);
    };
}

function asAsyncVoid<
    TThis extends (this: TThis, ...args: TArguments) => TResult,
    TArguments extends any[],
    TResult
>(this: TThis): (...args: TArguments) => Promise<void>
{
    const self = this;

    return async (...args: TArguments) =>
    {
        self.call(self, ...args);
    };
}

Function.prototype.asVoid = asVoid;
Function.prototype.asAsyncVoid = asAsyncVoid;

export {};
